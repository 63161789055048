import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Mask, DotLoading, Toast, PageIndicator, Swiper } from "antd-mobile";
import { useDispatch, useSelector } from "react-redux";
import { COUPON_USER_STATE_REQ_FAIL, COUPON_USER_STATE_REQ_SUCCESS, getUserState, isProofFace, isProofFrequentlyUsedApps, isProofMobile } from "@/store/actions/userState";
import { updateLoanDetailState } from "@/store/actions/loanDetail";
import { PageTag } from "@/utils/pageTag";
import { saveEvent } from "@/store/actions/saveEvent";
import icon_coupon_page_close from "@/assets/icon_coupon_page_close.png"
import icon_coupon_list_item_bg from "@/assets/icon_coupon_list_item_bg.png"
import icon_coupon_list_item_receive from "@/assets/icon_coupon_list_item_receive.png"
import icon_coupon_list_item_coming from '@/assets/icon_coupon_list_item_coming.png'
import icon_coupon_dialog_select from "@/assets/icon_coupon_dialog_select.png"
import icon_coupon_dialog_unselect from "@/assets/icon_coupon_dialog_unselect.png"
import icon_coupon_level_silver_bg from "@/assets/icon_coupon_level_silver_bg.png"
import icon_coupon_level_gold_bg from "@/assets/icon_coupon_level_gold_bg.png"
import icon_coupon_level_platinum_bg from "@/assets/icon_coupon_level_platinum_bg.png"
import icon_coupon_platinum_tips from "@/assets/icon_coupon_platinum_tips.png"
import './coupon.css';
import '../Home/home.css'
import '../../App.css'
import { CouponUseType } from "./CouponUseType";
import { COUPON_DETAIL_REQ_FAIL, COUPON_DETAIL_REQ_SUCCESS, getCouponDetail } from "@/store/actions/couponDetail";
import { CouponLevel } from "./CouponLevel";
import CouponClick from "./CouponClick";
import { isLoanLoaning, isLoanReject, isLoanRepayNow, isLoanReviewing } from "@/utils/loanStatus";

/**
 * 申请页
 */
export default function Coupon() {

    //loading状态
    const [maskVisible, setMaskVisible] = useState(false)

    //loading状态
    const [remindMeWhenHadCoupon, setRemindMeWhenHadCoupon] = useState(false)

    // 模拟数据
    const [couponNowList, setCouponNowList] = useState([])
    const [couponComingList, setCouponComingList] = useState([])

    // 等级状态
    const [couponLevel, setCouponLevel] = useState(CouponLevel.SILVER)

    // 获取路由历史 history 对象
    const history = useHistory()

    //获取 Redux 分发器
    const dispatch = useDispatch()

    //获取Redux Store中的用户状态信息
    const userStateInfo = useSelector(state => state.userState)

    //获取Redux Store中的proof信息
    const loanDetailInfo = useSelector(state => state.loanDetail)

    //获取Redux Store中的coupon信息
    const couponDetailInfo = useSelector(state => state.couponDetail)

    //获取Redux Store中的用户状态信息
    // const userNotExistInfo = useSelector(state => state.userNotExist)

    //查询用户优惠券信息
    const checkUserCouponDetail = async () => {
        console.log("checkUserCouponDetail: ");

        //获取用户状态
        try {
            //显示loading
            setMaskVisible(true)

            //获取Action
            const action = getCouponDetail()

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    // banner
    const banners = [icon_coupon_level_silver_bg, icon_coupon_level_gold_bg, icon_coupon_level_platinum_bg]

    const items = banners.map((banner, index) => (
        <Swiper.Item key={index}>
            <div className="couponSwiperItemContentBg">
                <div className="couponSwiperItemContent">
                    <img className="couponSwiperItemImage" src={banner} alt="" />
                    <div className="couponBannerTipsContent">
                        {index === 0 && <div className="couponBannerTipsTitle couponTextColorSliver">Silver level benefits</div>}
                        {index === 0 && <div className="couponBannerTipsCurLevel">Current level</div>}
                        {index === 1 && <div className="couponBannerTipsTitle couponTextColorGold">Gold level benefits</div>}
                        {index === 2 && <div className="couponBannerTipsTitle couponTextColorPlatinum">Platinum level benefits</div>}
                    </div>
                </div>
            </div>
        </Swiper.Item>
    ))

    //打点接口
    const doHitEvent = async (type, remark = "") => {
        let fromTag = CouponClick.showCouponFromSource || ""
        const typeFormat = fromTag+"_"+type
        console.log("doHitEvent: " + typeFormat + " , " + remark);

        try {
            //获取Action
            const action = saveEvent(typeFormat, remark)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //点击返回
    const onHandleNavGoBack = () => {
        console.log("go back")
        //打点
        doHitEvent("COUPON_CLOSE_CLICK", CouponClick.showCouponFromSource || "")

        history.goBack()
    }

    //查询用户流程状态
    const checkUserStateInfo = async () => {
        console.log("Coupon-checkUserStateInfo: ");

        //获取用户状态
        try {
            //显示loading
            setMaskVisible(true)

            //获取Action
            const action = getUserState(PageTag.COUPON)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    // 点击优惠券
    const onHandleCouponItemClick = (type, index) => {
        //打点
        doHitEvent("COUPON_USE_CLICK", type + "")

        if (index === couponNowList.length - 1) {
            const newStateList = [...couponNowList]
            const item = newStateList[index]
            item.useType = CouponUseType.RECEIVED
            item.canUse = false
            setCouponNowList(newStateList)

            // 更新缓存
            CouponClick.hadClickReceive = true
        }

        // 如果是user now类型
        if (type === CouponUseType.USE_NOW) {
            checkUserStateInfo()
        }
    }

    // 点击优惠券
    const onHandleCouponComingItemClick = (type, index) => {
        //打点
        doHitEvent("COUPON_COMING_CLICK", type + "")
    }

    // 点击有优惠时告诉我
    const onHandleRemindMeWhenAvailble = () => {
        setRemindMeWhenHadCoupon(!remindMeWhenHadCoupon)

        //打点
        doHitEvent("COUPON_REMIND_ME_CLICK", remindMeWhenHadCoupon + "")
    }

    // 切换监听
    const onHandleSwiperIndexChanged = (index) => {
        console.log("切换监听: " + index)

        // 当前coupon list
        const nowList = []
        if (index === CouponLevel.SILVER) {
            couponDetailInfo.list.forEach((value, index) => {
                if (value.type === "FREE") {
                    if (value.validFlag === "YES") {
                        nowList.push({
                            "couponType": 1,
                            "useType": CouponUseType.USE_NOW,
                            "couponAmount": value.amount,
                            "canUse": true
                        })
                    } else {
                        nowList.push({
                            "couponType": 1,
                            "useType": CouponUseType.USED,
                            "couponAmount": value.amount,
                            "canUse": false
                        })
                    }
                } else {
                    if (value.validFlag === "YES") {
                        nowList.push({
                            "couponType": 0,
                            "useType": CouponUseType.USE_NOW,
                            "couponAmount": value.amount,
                            "canUse": true
                        })
                    } else {
                        nowList.push({
                            "couponType": 0,
                            "useType": CouponUseType.USED,
                            "couponAmount": value.amount,
                            "canUse": false
                        })
                    }
                }
            });

            // 更新
            setCouponNowList(nowList)
            setCouponLevel(index)

        } else {
            nowList.push({
                "couponType": 0,
                "useType": CouponUseType.LEVE_NOT_REACHED,
                "couponAmount": "300",
                "canUse": false
            })
            nowList.push({
                "couponType": 0,
                "useType": CouponUseType.LEVE_NOT_REACHED,
                "couponAmount": "300",
                "canUse": false
            })
            nowList.push({
                "couponType": 0,
                "useType": CouponUseType.LEVE_NOT_REACHED,
                "couponAmount": "300",
                "canUse": false
            })
            // 更新
            setCouponNowList(nowList)
            setCouponLevel(index)
        }

        if (index === CouponLevel.SILVER) {
            //打点
            doHitEvent("COUPON_CHECK_SILVER")
        } else if (index === CouponLevel.GOLD) {
            //打点
            doHitEvent("COUPON_CHECK_GOLD")
        } else if (index === CouponLevel.PLATINUM) {
            //打点
            doHitEvent("COUPON_CHECK_PLATINUM")
        }
    }

    //启动时请求首页额度
    useEffect(() => {
        //获取贷款详情
        try {
            //添加 fb 像素
            // eslint-disable-next-line no-undef
            fbq('track', 'Page_Apply');
            // eslint-disable-next-line no-undef
            gtag('track', 'Page_Apply');

            // 即将取得coupon list
            const comingList = []
            comingList.push({
                "couponType": 0,
                "useType": CouponUseType.COMING_SOON,
                "couponAmount": "3000",
                "canUse": false
            })
            comingList.push({
                "couponType": 0,
                "useType": CouponUseType.COMING_SOON,
                "couponAmount": "3000",
                "canUse": false
            })
            setCouponComingList(comingList)

            // 获取优惠券详细
            checkUserCouponDetail()

            //打点
            doHitEvent("COUPON_START", CouponClick.showCouponFromSource || "")

            return () => {
                //打点
                doHitEvent("COUPON_END", CouponClick.showCouponFromSource || "")
            }

        } catch (e) {
            console.log(e)
        }
    }, [])

    //监听优惠券详情返回
    useEffect(() => {
        //监听优惠券详情返回
        if (couponDetailInfo.status === COUPON_DETAIL_REQ_SUCCESS) {
            //隐藏loading
            setMaskVisible(false)

            console.log("COUPON-优惠券详情返回")
            console.log(couponDetailInfo)

            // 当前coupon list
            const nowList = []
            couponDetailInfo.list.forEach((value, index) => {
                if (value.type === "FREE") {
                    if (value.validFlag === "YES") {
                        nowList.push({
                            "couponType": 1,
                            "useType": CouponUseType.USE_NOW,
                            "couponAmount": value.amount,
                            "canUse": true
                        })
                    } else {
                        nowList.push({
                            "couponType": 1,
                            "useType": CouponUseType.USED,
                            "couponAmount": value.amount,
                            "canUse": false
                        })
                    }
                } else {
                    if (value.validFlag === "YES") {
                        nowList.push({
                            "couponType": 0,
                            "useType": CouponUseType.USE_NOW,
                            "couponAmount": value.amount,
                            "canUse": true
                        })
                    } else {
                        nowList.push({
                            "couponType": 0,
                            "useType": CouponUseType.USED,
                            "couponAmount": value.amount,
                            "canUse": false
                        })
                    }
                }

                // 如果是最后一个，设置为receive
                if (index === couponDetailInfo.list.length - 1) {
                    const item = nowList[nowList.length - 1]
                    if (!CouponClick.hadClickReceive) {
                        item.useType = CouponUseType.RECEIVE
                    } else {
                        item.useType = CouponUseType.RECEIVED
                        item.canUse = false
                    }
                }

            });
            setCouponNowList(nowList)

        } else if (couponDetailInfo.status === COUPON_DETAIL_REQ_FAIL) {
            //隐藏loading
            setMaskVisible(false)
        }
    }, [couponDetailInfo])


    // 查询用户流程状态返回监听
    useEffect(() => {
        try {

            console.log("Home-查询用户流程状态返回监听")
            console.log(userStateInfo)

            if (userStateInfo.status === COUPON_USER_STATE_REQ_SUCCESS) {
                //隐藏loading
                setMaskVisible(false)

                console.log("Coupon-再次查询user state:")
                console.log("Coupon-applyStatus: " + userStateInfo.applyStatus)
                console.log("Coupon-orderStatus: " + userStateInfo.orderStatus)
    
                //是否为审核中
                const isHomeReviewing = isLoanReviewing(userStateInfo.applyStatus)
                //是否为拒绝
                const isHomeReject = isLoanReject(userStateInfo.applyStatus, userStateInfo.orderStatus)
                //是否为放款中
                const isHomeLoaning = isLoanLoaning(userStateInfo.orderStatus)
                //是否为待还款
                const isHomeRepay = isLoanRepayNow(userStateInfo.orderStatus)

                if(isHomeReviewing || isHomeReject || isHomeLoaning ||isHomeRepay){
                    history.goBack();
                    return;
                }

                if (userStateInfo.isProofFinish === true) {
                    //跳转申请页
                    history.push("/apply")

                } else if (userStateInfo.isProofFinish === false) {
                    //是否为号码认证
                    if (isProofMobile(userStateInfo.stepTitle)) {
                        //跳转申请页
                        history.push('/proofmobile')

                    } else if (isProofFrequentlyUsedApps(userStateInfo.stepTitle)) {
                        //跳转申请页
                        history.push('/prooffrequentlyapps')

                    } else if (isProofFace(userStateInfo.stepTitle)) {
                        //跳转申请页
                        history.push('/proofface')

                    } else {
                        //跳转认证页
                        history.push("/proof")
                    }
                }

            } else if (userStateInfo.status === COUPON_USER_STATE_REQ_FAIL) {
                //隐藏loading
                setMaskVisible(false)
            }
        } catch (e) {
            console.log(e)
        }
    }, [userStateInfo])

    return (
        <div className="couponRoot">

            <div className="couponContent">

                <div className="couponCloseImageContent">
                    <img className="couponCloseImage" src={icon_coupon_page_close} onClick={onHandleNavGoBack} alt="" />
                </div>

                {/* 顶部banner */}
                <div className="couponSwiperContent">
                    <Swiper
                        style={{
                            '--border-radius': '8px',
                            '--track-padding': ' 0px 0px 32px 0px'
                        }}
                        stuckAtBoundary={false}
                        slideSize={80}
                        trackOffset={10}
                        onIndexChange={onHandleSwiperIndexChanged}
                        indicator={(total, current) => (
                            <div className="couponSwiperIndicator">
                                <div className="couponSwiperIndicatorContent">
                                    <PageIndicator
                                        total={total}
                                        current={current}
                                        style={{
                                            '--dot-color': '#E0E0E0',
                                            '--active-dot-color': '#DCFF79',
                                            '--dot-size': '8px',
                                            '--active-dot-size': '8px',
                                            '--dot-border-radius': '50%',
                                            '--active-dot-border-radius': '4px',
                                            '--dot-spacing': '10px',
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        defaultIndex={0}>
                        {items}
                    </Swiper>
                </div>

                {couponLevel !== CouponLevel.SILVER && <div className="couponGoodRecordContent">Maintain a good credit record and upgrade to a high-level user</div>}

                {couponLevel === CouponLevel.PLATINUM && <div className="couponPlatinumTipsContentBg">
                    <div className="couponPlatinumTipsContent">
                        <div className="couponPlatinumTipsTextContent">
                            <div className="couponPlatinumTipsTitle">VIP exclusive customer service</div>
                            <div className="couponPlatinumTipsDes">{"Professional account manager, one\n-to-one service"}</div>
                        </div>
                        <img className="couponPlatinumTipsImage" src={icon_coupon_platinum_tips} alt="" />
                    </div>
                </div>}


                <div className="couponNowListContent">
                    {/* 当前列表 */}
                    <div className="couponItemList ">
                        {couponNowList.map((value, index) => {
                            if (value.couponType === 0) {
                                return (
                                    <div className="couponItemContent" key={index}>
                                        <div className="couponImageTypeContent">
                                            <div className="couponImageBg">
                                                <div className="couponImageContent">
                                                    <img className="couponImageImg" src={icon_coupon_list_item_bg} alt="" />
                                                    <div className="couponImageAmountContent">
                                                        <div className="couponImageTextContent">
                                                            <div className="couponImageTitle">Repayment Coupon</div>
                                                            <div className="couponImageAmount">₦{value.couponAmount}</div>
                                                            <div className="couponImageTitle">Discount on repayment</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={value.canUse ? "couponStateContent" : "couponStateContentUnable"} onClick={() => { onHandleCouponItemClick(value.useType, index) }}>{value.useType}</div>
                                        </div>
                                        {(value.useType === CouponUseType.RECEIVE) && <div className="couponImgeNextToUseContent">
                                            <img className="couponImageReceiveImg" src={icon_coupon_list_item_receive} alt="" />
                                            <div>Next Loan Available</div>
                                        </div>}
                                    </div>
                                )
                            } else if (value.couponType === 1) {
                                return (
                                    <div className="couponItemContent" key={index}>
                                        <div className="couponImageTypeContent">
                                            <div className="couponImageBg">
                                                <div className="couponImageContent">
                                                    <img className="couponImageImg" src={icon_coupon_list_item_bg} alt="" />
                                                    <div className="couponImageAmountContent">
                                                        <div className="couponImageTextContent">
                                                            <div className='couponImageFreeContent'>
                                                                <div className="couponImageFreeAmount">0</div>
                                                                <div className="couponImageFreeAmountDes">free for</div>
                                                            </div>
                                                            <div className="couponImageTitle">your first loan</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={value.canUse ? "couponStateContent" : "couponStateContentUnable"} onClick={() => { onHandleCouponItemClick(value.useType, index) }}>{value.useType}</div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className="couponItemContent" key={index} onClick={() => { }}></div>
                                )
                            }
                        })}
                    </div>

                    {/* 即将到来列表 */}
                    {couponLevel === CouponLevel.SILVER && <div className="couponItemComingSoonContent">
                        <div className="couponItemComingSoonHeader">Coming soon</div>
                        <div className="couponItemComingSoonRow">
                            <img className="couponImageComingSoonImg" src={icon_coupon_list_item_coming} alt="" />
                            <div className="couponItemComingSoonTitle">Remind me when availble</div>
                            <img className="couponImageCheckboxImg" src={remindMeWhenHadCoupon ? icon_coupon_dialog_select : icon_coupon_dialog_unselect} alt="" onClick={onHandleRemindMeWhenAvailble} />
                        </div>
                    </div>}

                    {couponLevel !== CouponLevel.SILVER && <div className="couponRowLineContent">
                        <div className="couponRowLine"></div>
                    </div>}

                    <div className="couponItemList contentVerticalPadding12Px">
                        {couponComingList.map((value, index) => {
                            return (
                                <div className="couponItemContent" key={index}>
                                    <div className="couponImageTypeContent">
                                        <div className="couponImageBg">
                                            <div className="couponImageContent">
                                                <img className="couponImageImg" src={icon_coupon_list_item_bg} alt="" />
                                                <div className="couponImageAmountContent">
                                                    <div className="couponImageTextContent">
                                                        <div className="couponImageTitle">Amount increase coupon</div>
                                                        <div className="couponImageAmount">{value.couponAmount}</div>
                                                        <div className="couponImageTitle">increase loan amount</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="couponStateContentComingSoon" onClick={() => { onHandleCouponComingItemClick(value.useType, index) }}>{value.useType}</div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            {/* load mask */}
            <Mask visible={maskVisible} opacity={0.1}
                onMaskClick={() => { }}>
                <div className="overlayContent">
                    <span style={{ fontSize: 24, color: '#ff3141' }} >
                        <DotLoading color="currentColor" />
                    </span>
                </div>
            </Mask>
        </div >
    )
}