import { cleanLoginState } from "./login"
import { cleanOtpState } from "./sendOtp"
import { cleanUserState } from "./userState"

//类型：监听用户是否存在
export const USER_NOT_EXIST_CHECK_REQ = "USER_NOT_EXIST_CHECK_REQ"


/**
 * 设置用户不存在
 * @returns thunk
 */
export const setUserNotExist = () => {
    return async (dispatch) => {

        //清除验证码信息
        dispatch(cleanOtpState())

        //清除登录信息
        dispatch(cleanLoginState())

        //清除流程信息
        dispatch(cleanUserState())

        const data = {
            type: USER_NOT_EXIST_CHECK_REQ,
            payload: {}
        }
        dispatch(data)
    }
}

