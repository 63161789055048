import React, { useEffect, useState } from 'react';
import './starreviewdialog.css'
import icon_star_dialog from '@/assets/icon_star_dialog.png'
import icon_star_close from '@/assets/icon_star_close.png'
import icon_star_no from '@/assets/icon_star_no.png'
import icon_star_yes from '@/assets/icon_star_yes.png'
import { Mask, Toast } from 'antd-mobile';
import { saveEvent } from '@/store/actions/saveEvent';
import { useDispatch } from 'react-redux';
import { setAppReviewFlag } from '@/utils/storage';


export default function StarReviewDialog({ visible, onClose }) {

    // 获取 Redux 分发器
    const dispatch = useDispatch()

    const [needDisplay, setNeedDisplay] = useState(false);

    const mStarList = [
        {
            icon: icon_star_no,
            star: 1,
            checked: false
        },
        {
            icon: icon_star_no,
            star: 2,
            checked: false
        },
        {
            icon: icon_star_no,
            star: 3,
            checked: false
        },
        {
            icon: icon_star_no,
            star: 4,
            checked: false
        },
        {
            icon: icon_star_no,
            star: 5,
            checked: false
        }
    ]

    //星星列表
    const [stars, setStars] = useState(mStarList)

    //点击星星回调
    const onHandleStarClick = (starIndex) => {
        const newList = [...stars]
        newList.forEach((value, index) => {
            if (index <= starIndex) {
                value.checked = true
                value.icon = icon_star_yes
            } else {
                value.checked = false
                value.icon = icon_star_no
            }
        })
        //更新状态
        setStars(newList)

        //打点
        const star = starIndex + 1
        doHitEvent("APP_REVIEW_STAR_CLICK", star + "")
    }

    //点击 not now 回调
    const onHandleNotNowClick = () => {
        //计算星级
        let maxStarIndex = -1
        stars.forEach((value, index) => {
            if (value.checked) {
                maxStarIndex = index
            }
        });
        if (maxStarIndex >= 0) {
            const starItem = stars[maxStarIndex]
            doHitEvent("APP_REVIEW_NOT_NOW_CLICK", starItem.star + "")
        } else {
            doHitEvent("APP_REVIEW_NOT_NOW_CLICK")
        }

        //关闭
        onHandleCloseClick()
    }

    //点击 rate 回调
    const onHandleRateClick = () => {
        //计算星级
        let maxStarIndex = -1
        stars.forEach((value, index) => {
            if (value.checked) {
                maxStarIndex = index
            }
        });
        if (maxStarIndex < 0) {
            Toast.show("Please select star first");
            return;
        }
        const starItem = stars[maxStarIndex]
        doHitEvent("APP_REVIEW_RATE_CLICK", starItem.star + "")

        //保存本地状态
        setAppReviewFlag()

        //关闭
        onHandleCloseClick()
    }

    //点击 close 回调
    const onHandleCloseClick = () => {
        onClose && onClose()
    }

    //打点接口
    const doHitEvent = async (type, remark = "") => {
        console.log("doHitEvent: " + type + " , " + remark);

        try {
            //获取Action
            const action = saveEvent(type, remark)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //监听
    useEffect(() => {
        setNeedDisplay(visible)
    }, [visible])


    return (
        <Mask visible={needDisplay} opacity={0.6}
            onMaskClick={() => { }}>
            <div className="starreviewDialogOverlayBg">
                <div className="starreviewDialogOverlayContent">
                    <img className="starreviewDialogOverlayIcon" src={icon_star_dialog} alt="" />
                    <img className="starreviewDialogOverlayIconClose" src={icon_star_close} alt="" onClick={onHandleCloseClick} />
                    <div className='starreviewDialogOverlayContentTitle'>How is your experience?</div>
                    <div className='starreviewDialogOverlayContentTitle'>Please rate us to make us better.</div>

                    {/* 星级 */}
                    <div className='starreviewDialogOverlayContentStars'>
                        {stars.map((value, index) => {
                            return (
                                <div className="starreviewDialogOverlayContentStar" key={index} onClick={() => onHandleStarClick(index)}>
                                    <img className='starreviewDialogOverlayStar' src={value.icon} alt='' />
                                </div>
                            )
                        })}
                    </div>

                    {/* 拒绝与评价 */}
                    <div className='starreviewDialogOverlayContentActions'>
                        <div className='starreviewDialogOverlayActionNotNow' onClick={onHandleNotNowClick}>Not Now</div>
                        <div className='starreviewDialogOverlayActionSpace'></div>
                        <div className='starreviewDialogOverlayActionRate' onClick={onHandleRateClick}>Rate</div>
                    </div>
                </div>
            </div>
        </Mask >
    );
};
