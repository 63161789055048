import React from "react";
import '../FormSelect/form.css'
import { Input, Picker } from "antd-mobile";
import Icon from "../Icon";
import icon_down_black from "@/assets/icon_down_black.png"

export default function BankFormSelect({ title, showPicker, list, onCheckDataClick, onSelectedDataClick, onCloseClick, ...rest }) {

    //设置选择回调
    const onHandleSelectedPick = (data, extend) => {
        try {
            let item = extend.items[0]
            console.log(item)
            onSelectedDataClick(item)

        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="formContent">
            <span className="formTitle">{title}</span>
            <div className="formValueContent" onClick={onCheckDataClick}>
                <Input className="formValue"  {...rest} readOnly={true} />
                <Icon picUrl={icon_down_black} onItemClick={() => { }}></Icon>
            </div>
            <div className="formSplitLine" hidden></div>
            <Picker
                className="formPicker"
                title={title}
                columns={list}
                cancelText="Cancel"
                confirmText="Confirm"
                visible={showPicker}
                onClose={onCloseClick}
                onConfirm={onHandleSelectedPick}
            />
        </div >
    )
}

