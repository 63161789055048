import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Mask, DotLoading, Toast } from "antd-mobile";
import { useDispatch, useSelector } from "react-redux";
import { APPLY_USER_STATE_REQ_SUCCESS, LAUNCH_USER_STATE_REQ_SUCCESS, getUserState } from "@/store/actions/userState";
import { LOAN_DETAIL_BY_AMOUNT_REQ_SUCCESS, LOAN_DETAIL_REQ_FAIL, LOAN_DETAIL_REQ_SUCCESS, cleanLoanDetailState, getLoanDetailByAmount, updateLoanDetailState } from "@/store/actions/loanDetail";
import { checkOSType, toPercentageString } from "@/utils/utils";
import { LOAN_APPLY_REQ_FAIL, LOAN_APPLY_REQ_SUCCESS, confirmLoanApply, updateConfirmApplyState } from "@/store/actions/loanApply";
import { PageTag } from "@/utils/pageTag";
import ProofNavigateBar from "@/components/ProofNavigateBar";
import { saveEvent } from "@/store/actions/saveEvent";
import icon_close from "@/assets/icon_close.png"
import icon_apply_increase1 from "@/assets/icon_apply_increase1.png"
import icon_apply_increase2 from "@/assets/icon_apply_increase2.png"
import icon_apply_increase3 from "@/assets/icon_apply_increase3.png"
import icon_coupon_apply_item_bg from "@/assets/icon_coupon_apply_item_bg.png"
import icon_coupon_apply_checkbox_yes from "@/assets/icon_coupon_apply_checkbox_yes.png"
import icon_apply_disburse from "@/assets/icon_apply_disburse.png"
import icon_apply_repayment from "@/assets/icon_apply_repayment.png"
import icon_apply_coupon_reduce from "@/assets/icon_apply_coupon_reduce.png"
import './apply.css';
import '../../Home/home.css'
import '../../../App.css'
import AddFavorite from "@/components/AddFavorite";
import AddToHomeGifDialog from "@/components/AddToHomeGifDialog/AddToHomeGifDialog";
import AddHomeEvent from "@/utils/addHomeEvent";
import { AmountRange } from "@/utils/amountRange";
import CouponClick from "@/pages/Coupon/CouponClick";
import { setOrderInfo } from "@/utils/storage";

/**
 * 申请页
 */
export default function ApplyCoupon() {

    //loading状态
    const [maskVisible, setMaskVisible] = useState(false)

    //显示add home弹框
    const [addHomeMaskVisible, setAddHomeMaskVisible] = useState(false);
    const [addHomeMaskIsHomeMode, setAddHomeMaskIsHomeMode] = useState(true);

    //loading状态
    const [loanStategyVisible, setLoanStategyVisible] = useState(false)

    // 获取路由历史 history 对象
    const history = useHistory()

    //获取 Redux 分发器
    const dispatch = useDispatch()

    //获取Redux Store中的用户状态信息
    const userStateInfo = useSelector(state => state.userState)

    //获取Redux Store中的proof信息
    const loanDetailInfo = useSelector(state => state.loanDetail)

    //获取Redux Store中的confirm apply信息
    const loanApplyInfo = useSelector(state => state.loanApply)

    //获取Redux Store中的用户状态信息
    // const userNotExistInfo = useSelector(state => state.userNotExist)

    //获取金额选项状态值
    const [amountValue] = useState("1")

    //获取日期选项状态值
    const [dayValue] = useState("1")

    //确认申请
    const doLoanApply = async () => {
        console.log("APPLY-confirmLoanApply: ");

        //确认申请
        try {
            //过滤days
            let days = loanDetailInfo.days
            const daysList = loanDetailInfo.dayOptions.filter(item => {
                return item.selected
            })
            if (daysList && daysList.length > 0) {
                days = daysList[0].label
            }

            //过滤金额
            let amount = AmountRange.rank3
            const amountList = loanDetailInfo.amountOptions.filter(item => {
                return item.selected
            })
            if (amountList && amountList.length > 0) {
                amount = amountList[0].label
            }

            //获取Action
            const action = confirmLoanApply(amount, days)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //开始金额试算
    const checkLoanDetailInfoByAmount = async (amount) => {
        console.log("checkLoanDetailInfoByAmount: ");

        //获取用户状态
        try {
            //显示loading
            setMaskVisible(true)

            console.log(loanDetailInfo)

            //获取Action
            const action = getLoanDetailByAmount(amount, loanDetailInfo)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //查询用户流程状态
    const checkUserStateInfo = async () => {
        console.log("checkUserStateInfo: ");

        //获取用户状态
        try {
            //显示loading
            setMaskVisible(true)

            //获取Action
            const action = getUserState(PageTag.APPLY)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //查询用户流程状态
    const checkUserStateInfoForOldUser = async () => {
        console.log("checkUserStateInfoForOldUser: ");

        //获取用户状态
        try {
            //显示loading
            setMaskVisible(true)

            //获取Action
            const action = getUserState()

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //打点接口
    const doHitEvent = async (type, remark = "") => {
        console.log("doHitEvent: " + type + " , " + remark);

        try {
            //获取Action
            const action = saveEvent(type, remark)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //点击申请
    const onHandleApplyClick = () => {
        //打点
        doHitEvent("APPLY_COMMIT_CLICK")

        //查询是否已选额度
        let hadAmountSelected = false
        for (let i = 0; i < loanDetailInfo.amountOptions.length; i++) {
            if (loanDetailInfo.amountOptions[i].selected === true) {
                hadAmountSelected = true
            }
        }
        if (!hadAmountSelected) {
            Toast.show("Please select amount first!")
            return
        }

        //查询用户状态
        checkUserStateInfo()
    }

    //点击返回
    const onHandleNavGoBack = () => {
        console.log("go back")
        history.replace("/home")
    }

    //点击金额返回
    const onHandleAmountClick = (amount, selected, index) => {
        if (!selected) {
            //更新状态
            const newState = { ...loanDetailInfo }
            for (let i = 0; i < newState.amountOptions.length; i++) {
                if (i === index) {
                    newState.amountOptions[i].selected = true
                } else {
                    newState.amountOptions[i].selected = false
                }
            }

            //更新状态
            dispatch(updateLoanDetailState(newState, true))

            //打点
            doHitEvent("APPLY_AMOUNT_CLICK", amount + "")

            if (loanDetailInfo.canSelectAmount === true) {
                //重新计算详情
                checkLoanDetailInfoByAmount(amount)
            } else {
                //显示弹框
                setLoanStategyVisible(true)
            }
        }
    }

    //点击日期返回
    const onHandleDayClick = (day, selected, index) => {
        if (!selected) {
            //显示弹框
            setLoanStategyVisible(true)

            //打点
            doHitEvent("APPLY_DAY_CLICK", day + "")
        }
    }

    // 刷新操作
    const onHandleRefresh = () => {
        // 查询用户流程状态
        checkUserStateInfoForOldUser()
    }

    // 点击add home监听
    const onHandleAddToHome = (type) => {
        console.log(`点击add home监听: ${type}`)
        if (type === 'home') {
            AddHomeEvent.onHandleAddHomeEventClick()
        } else {
            AddHomeEvent.onHandleAddFavoriteEventClick()
        }
    }

    // 关闭add home
    const onHandleAddToHomeClose = () => {
        console.log(`点击close add home监听`)
        setAddHomeMaskVisible(false)
    }

    //启动时请求首页额度
    useEffect(() => {
        //获取贷款详情
        try {
            //添加 fb 像素
            // eslint-disable-next-line no-undef
            fbq('track', 'Page_Apply');
            // eslint-disable-next-line no-undef
            gtag('track', 'Page_Apply');

            // 优惠券入口
            CouponClick.fromParentPage = PageTag.APPLY

            //显示loading
            setMaskVisible(true)

            //获取Action
            console.log("--->user state:")
            console.log(userStateInfo)
            checkUserStateInfoForOldUser()
            //const action = getLoanDetailByAmount(AmountRange.rank1)

            //触发接口
            //dispatch(action)

            //打点
            doHitEvent("APPLY_START")

            return () => {
                //打点
                doHitEvent("APPLY_END")

                //清空状态
                dispatch(cleanLoanDetailState())
            }

        } catch (e) {
            console.log(e)
        }
    }, [])

    //监听还款详细返回
    useEffect(() => {
        //获取短信发送成功返回
        if (loanDetailInfo.status === LOAN_DETAIL_REQ_SUCCESS) {
            //隐藏loading
            setMaskVisible(false)

            console.log("APPLY-贷款详细返回成功")
            console.log(loanDetailInfo)

        } else if (loanDetailInfo.status === LOAN_DETAIL_BY_AMOUNT_REQ_SUCCESS) {
            //隐藏loading
            setMaskVisible(false)

            console.log("APPLY-贷款试算详细返回成功")
            console.log(loanDetailInfo)

        } else if (loanDetailInfo.status === LOAN_DETAIL_REQ_FAIL) {
            //隐藏loading
            setMaskVisible(false)
        }
    }, [loanDetailInfo])

    // 查询用户流程状态返回监听
    useEffect(() => {
        try {

            console.log("APPLY-查询用户流程状态返回监听")
            console.log(userStateInfo)

            if (userStateInfo.status === APPLY_USER_STATE_REQ_SUCCESS) {
                //隐藏loading
                setMaskVisible(false)

                if (userStateInfo.isProofFinish === true) {
                    //直接申请
                    doLoanApply()

                } else if (userStateInfo.isProofFinish === false) {
                    //跳转认证页
                    history.replace("/proof")
                }
            } else if (userStateInfo.status === LAUNCH_USER_STATE_REQ_SUCCESS) {
                if (userStateInfo && (userStateInfo.userType === 'OLD')) {
                    // 老客不用取默认值
                    const action = getLoanDetailByAmount("")

                    //触发接口
                    dispatch(action)
                } else {
                    // 新客取默认值
                    const action = getLoanDetailByAmount(AmountRange.rank1)

                    //触发接口
                    dispatch(action)
                }
            }
        } catch (e) {
            console.log(e)
        }
    }, [userStateInfo])

    // 提交申请返回监听
    useEffect(() => {
        try {
            console.log("APPLY-提交申请返回监听")
            console.log(loanApplyInfo)

            if (loanApplyInfo.status === LOAN_APPLY_REQ_SUCCESS) {

                //隐藏loading
                //setMaskVisible(false)

                //跳转审核中页
                // history.replace("/review")
                // 保存订单号
                const orderId = loanApplyInfo.orderId || ""
                setOrderInfo(orderId)

                if (checkOSType() === "IOS") {
                    //隐藏loading
                    setMaskVisible(false)

                    //跳转审核中页
                    history.replace("/review")

                } else {
                    // 只针对测试账号
                    setTimeout(() => {
                        //隐藏loading
                        setMaskVisible(false)

                        //跳转引导页
                        history.replace("/guide")
                    }, 1000)

                    //缓存用户信息
                    // const userInfo = getUserInfo()
                    // const mobile = userInfo.mobile || ""
                    // if (mobile === "9162222222") {
                    //     // 只针对测试账号
                    //     setTimeout(() => {
                    //         //隐藏loading
                    //         setMaskVisible(false)

                    //         //跳转引导页
                    //         history.replace("/guide")
                    //     }, 1000)
                    // } else {
                    //     //隐藏loading
                    //     setMaskVisible(false)

                    //     //跳转审核中页
                    //     history.replace("/review")
                    // }
                }


                //更新状态
                //dispatch(updateConfirmApplyState(loanApplyInfo, true))


            } else if (loanApplyInfo.status === LOAN_APPLY_REQ_FAIL) {
                //隐藏loading
                setMaskVisible(false)
            }
        } catch (e) {
            console.log(e)
        }
    }, [loanApplyInfo])

    //监听用户是否存在
    // useEffect(() => {
    //     if (userNotExistInfo.notExist === true) {
    //         //history.replace('/login')
    //          history.replace('/loginfb')
    //     }
    // }, [userNotExistInfo])

    return (
        <div className="applyRoot">
            {/* 顶部背景 */}
            {/* <div className="applyTopBackground"></div> */}
            <ProofNavigateBar theme="white" onBackClick={onHandleNavGoBack} onAddFavorite={() => { setAddHomeMaskIsHomeMode(false); setAddHomeMaskVisible(true) }} onAddHome={() => { setAddHomeMaskIsHomeMode(true); setAddHomeMaskVisible(true) }} onRefresh={onHandleRefresh}>Loan Salad</ProofNavigateBar>

            {/* 使用添加 home screen bar */}
            {/* <AddFavorite onAddHome={() => { setAddHomeMaskIsHomeMode(true); setAddHomeMaskVisible(true) }} /> */}

            {false && <div className="applyAmountContent">
                <div className="homeAmountTitle">Loan Amount</div>

                {/* 金额 */}
                <div className="contentRowCenter homeAmountAmountBottomAlign">
                    <div className="homeAmountCurrency">{loanDetailInfo.currency}</div>
                    <div className="homeAmountValue">{loanDetailInfo.amount}</div>
                </div>
            </div>}

            {/* 金额模块 */}
            <div className="applyContent">
                {/* 选择金额 */}
                <div className="applyItemList contentVerticalPadding16Px">
                    <span className="applyItemBillHeaderTitle">Choose your loan amount</span>
                    <div className="applyItemSelectorContent">
                        {loanDetailInfo.isOldUser && loanDetailInfo.amountOptions && loanDetailInfo.amountOptions.map((value, index) => {
                            if (value.selected) {
                                return (
                                    <div className="applyItemSelectorSelected" key={index} onClick={() => { onHandleAmountClick(value.label, true, index) }}>{value.label}</div>
                                )
                            } else {
                                return (
                                    <div className="applyItemSelectorUnSelectBlank" key={index} onClick={() => { }}></div>
                                )
                            }
                        })}

                        {(!loanDetailInfo.isOldUser) && loanDetailInfo.amountOptions && loanDetailInfo.amountOptions.map((value, index) => {
                            if (value.selected) {
                                return (
                                    <div className="applyItemSelectorSelected" key={index} onClick={() => { onHandleAmountClick(value.label, true, index) }}>{value.label}</div>
                                )
                            } else {
                                return (
                                    <div className="applyItemSelectorUnSelect" key={index} onClick={() => { onHandleAmountClick(value.label, false, index) }}>{value.label}</div>
                                )
                            }
                        })}
                    </div>
                </div>

                {/* 选择日期 */}
                <div className="applyItemList contentVerticalPadding16Px">
                    <span className="applyItemBillHeaderTitle">Choose your loan term</span>
                    <div className="applyItemSelectorContent">
                        {loanDetailInfo.dayOptions && loanDetailInfo.dayOptions.map((value, index) => {
                            if (value.selected) {
                                return (
                                    <div className="applyItemSelectorSelected" key={index} onClick={() => { onHandleDayClick(value.label, true) }}>{value.label}</div>
                                )
                            } else {
                                return (
                                    <div className="applyItemSelectorUnSelect" key={index} onClick={() => { onHandleDayClick(value.label, false) }}>{value.label}</div>
                                )
                            }
                        })}
                    </div>
                </div>

                {/* 放款信息 */}
                <div className="applyItemList contentVerticalPadding">
                    <div className="applyItemBillContent">
                        <img className="applyItemBillHeaderImg" src={icon_apply_disburse} alt="" />
                        <span className="applyItemBillHeaderTitle">Disburse amount</span>
                        <span className="applyItemBillHeaderAmount">{loanDetailInfo.realAmount}</span>
                        <span className="applyItemBillCurrency applyMainTextColor">{loanDetailInfo.currency}</span>
                    </div>
                    <div className="applyItemBillBg">
                        <div className="applyItemBillContent applyItemBillBottomLine">
                            <span className="applyItemBillItemTitle">Loan amount</span>
                            <span className="applyItemBillItemAmount">{loanDetailInfo.amount}</span>
                            <span className="applyItemBillCurrency">{loanDetailInfo.currency}</span>
                        </div>
                        <div className="applyItemBillContent">
                            <span className="applyItemBillItemTitle">Service fee</span>
                            <span className="applyItemBillItemAmount">{loanDetailInfo.processingFee}</span>
                            <span className="applyItemBillCurrency">{loanDetailInfo.currency}</span>
                        </div>
                    </div>
                </div>

                {/* 还款信息 */}
                <div className="applyItemList applyAmountMarginTop6Px">
                    <div className="applyItemBillContent">
                        <img className="applyItemBillHeaderImg" src={icon_apply_repayment} alt="" />
                        <span className="applyItemBillHeaderTitle">Repayment amount</span>
                        {loanDetailInfo.couponFlag === "YES" && <span className="applyItemBillHeaderAmount applyCouponAmount">{loanDetailInfo.repayAmount}</span>}
                        <span className="applyItemBillHeaderAmount">{loanDetailInfo.couponFlag === "YES" ? loanDetailInfo.couponRepayAmount : loanDetailInfo.repayAmount}</span>
                        <span className="applyItemBillCurrency applyMainTextColor">{loanDetailInfo.currency}</span>
                    </div>
                    <div className="applyItemBillBg">
                        <div className="applyItemBillContent applyItemBillBottomLine">
                            <span className="applyItemBillItemTitle">Loan amount</span>
                            <span className="applyItemBillItemAmount">{loanDetailInfo.amount}</span>
                            <span className="applyItemBillCurrency">{loanDetailInfo.currency}</span>
                        </div>
                        <div className="applyItemBillContent applyItemBillBottomLine">
                            <span className="applyItemBillItemTitle">Interest</span>
                            <span className="applyItemBillItemAmount">{loanDetailInfo.interest}</span>
                            <span className="applyItemBillCurrency">{loanDetailInfo.currency}</span>
                        </div>
                        <div className="applyItemBillContent applyItemBillBottomLine">
                            <span className="applyItemBillItemTitle">Service fee</span>
                            <span className="applyItemBillItemAmount">{loanDetailInfo.repayProFee}</span>
                            <span className="applyItemBillCurrency">{loanDetailInfo.currency}</span>
                        </div>
                        <div className="applyItemBillContent">
                            <span className="applyItemBillItemTitle">Overdue penalty interest</span>
                            <span className="applyItemBillItemAmount">{toPercentageString(loanDetailInfo.overdueRate)} per day</span>
                            <span className="applyItemBillCurrency"></span>
                        </div>
                        {loanDetailInfo.couponFlag === "YES" && <div className="applyItemBillContent">
                            <img className="applyItemBillItemImg" src={icon_apply_coupon_reduce} alt="" />
                            <span className="applyItemBillItemTitle">Coupon reduce</span>
                            <span className="applyItemBillItemAmount applyCouponReduceAmount">-{loanDetailInfo.couponOrderAmount}</span>
                            <span className="applyItemBillCurrency">{loanDetailInfo.currency}</span>
                        </div>}
                    </div>
                </div>

                {/* 优惠券模块 */}
                {loanDetailInfo.couponFlag === "YES" && <div className="applyAmountCouponBg applyAmountMarginTop16Px">
                    <div className="applyAmountCouponHeader">Coupon</div>
                    <div className="applyAmountCouponContent applyAmountMarginTop12Px">
                        <img className="applyAmountCouponImg" src={icon_coupon_apply_item_bg} alt="" />
                        <div className="applyAmountCouponAmountContent">
                            <img className="applyAmountCouponCheckboxImg" src={icon_coupon_apply_checkbox_yes} alt="" />
                            <div className="applyAmountCouponTextContent">
                                <div className="applyAmountCouponTitle">Repayment Coupon</div>
                                <div className="applyAmountCouponAmount">₦{loanDetailInfo.couponAmount}</div>
                                <div className="applyAmountCouponTitle">Discount on repayment</div>
                            </div>
                        </div>
                    </div>
                </div>}

                {/* 首页点击申请 */}
                <div className="applyApplyBtn" onClick={onHandleApplyClick}>
                    <div className="mainButtonGradient">Apply Now</div>
                </div>
            </div>

            {/* load mask https://mobile.ant.design/zh/components/mask/ */}
            <Mask visible={loanStategyVisible} opacity={0.1}
                onMaskClick={() => { }}>
                <div className="applyOverlayBg">
                    <div className="applyOverlayContent">
                        <div className="applyOverlayContentIconClose" onClick={() => { setLoanStategyVisible(false) }}>
                            <img className="applyOverlayIconClose" src={icon_close} alt="" />
                        </div>
                        <div className="applyOverlayContentHeaderTitle">Loan strategy</div>
                        <div className="applyOverlayContentRowTitle">Quickly increase the personal loan amount and term all you need to do</div>
                        <div className="applyOverlayContentRowTitle">Strategy 1: Pay off each loan in full and on time.</div>
                        <div className="applyOverlayContentRowTitle">Strategy 2: Continuous use, the more borrowing times, the higher the amount, and the lower the fee.</div>
                        <div className="applyOverlayContentIconIncrease">
                            <img className="applyOverlayIconIncrease" src={icon_apply_increase1} alt="" />
                            <div className="applyOverlayIconIncreaseSpace"></div>
                            <img className="applyOverlayIconIncrease" src={icon_apply_increase2} alt="" />
                        </div>
                        <div className="applyOverlayContentIconIncrease">
                            <img className="applyOverlayIconIncreaseLarge" src={icon_apply_increase3} alt="" />
                        </div>
                    </div>
                </div>
            </Mask>

            {/* add home gif dialog */}
            <AddToHomeGifDialog visible={addHomeMaskVisible} onConfirm={onHandleAddToHome} onClose={onHandleAddToHomeClose} isHomeMode={addHomeMaskIsHomeMode} />

            {/* load mask */}
            <Mask visible={maskVisible} opacity={0.1}
                onMaskClick={() => { }}>
                <div className="overlayContent">
                    <span style={{ fontSize: 24, color: '#ff3141' }} >
                        <DotLoading color="currentColor" />
                    </span>
                </div>
            </Mask>
        </div >
    )
}