import React, { useState } from "react";
import './form.css'
import { Input, Picker } from "antd-mobile";
import Icon from "../Icon";
import icon_down_black from "@/assets/icon_down_black.png"

export default function FormSelect({ title, list, onSelectedDataClick, ...rest }) {

    //设置日期显示状态
    const [showPicker, setShowPicker] = useState(false)

    //设置选择回调
    const onHandleSelectedPick = (data, extend) => {
        // console.log(data)
        // onSelectedDataClick(data+"")
        try {
            let item = extend.items[0]
            console.log(item)
            onSelectedDataClick(item)

        } catch (e) {
            console.log(e)
        }
    }

    //关闭日期显示
    const onHandleCloseDate = () => {
        setShowPicker(false)
    }


    return (
        <div className="formContent">
            <span className="formTitle">{title}</span>
            <div className="formValueContent" onClick={() => { setShowPicker(true) }}>
                <Input className="formValue"  {...rest} readOnly={true} />
                <Icon picUrl={icon_down_black} onItemClick={() => { setShowPicker(true) }}></Icon>
            </div>
            <div className="formSplitLine" hidden></div>
            {/* <div className="formPicker"> */}
            <Picker
                className="formPicker"
                title={title}
                columns={list}
                cancelText="Cancel"
                confirmText="Confirm"
                visible={showPicker}
                onClose={onHandleCloseDate}
                onConfirm={onHandleSelectedPick}
            />
            {/* </div> */}
        </div>
    )
}

