
import http, { baseParams } from "@/utils/request";
import { UrlGetUseState } from "@/utils/apiUrl";
import { isEmptyString, isNullObject } from "@/utils/utils";
import { PageTag } from "@/utils/pageTag";
import { Toast } from "antd-mobile";
import { saveEvent } from "./saveEvent";
import { setUserNotExist } from "./userNotExist";
import AppParams from "@/utils/appParams";
import { setChatMobile, setUserInfo } from "@/utils/storage";

//类型：申请成功
export const USER_STATE_REQ_SUCCESS = "USER_STATE_REQ_SUCCESS"

//类型：申请失败
export const USER_STATE_REQ_FAIL = "USER_STATE_REQ_FAIL"

//类型：清空请求
export const USER_STATE_REQ_CLEAR = "USER_STATE_REQ_CLEAR"

//类型：启动页-申请成功
export const LAUNCH_REFERRER_USER_STATE_REQ_SUCCESS = "LAUNCH_REFERRER_USER_STATE_REQ_SUCCESS"

//类型：启动页-申请失败
export const LAUNCH_REFERRER_USER_STATE_REQ_FAIL = "LAUNCH_REFERRER_USER_STATE_REQ_FAIL"

//类型：启动页-申请成功
export const LAUNCH_USER_STATE_REQ_SUCCESS = "LAUNCH_USER_STATE_REQ_SUCCESS"

//类型：启动页-申请失败
export const LAUNCH_USER_STATE_REQ_FAIL = "LAUNCH_USER_STATE_REQ_FAIL"

//类型：登录页-申请成功
export const LOGIN_USER_STATE_REQ_SUCCESS = "LOGIN_USER_STATE_REQ_SUCCESS"

//类型：登录页-申请失败
export const LOGIN_USER_STATE_REQ_FAIL = "LOGIN_USER_STATE_REQ_FAIL"

//类型：首页-申请成功
export const HOME_USER_STATE_REQ_SUCCESS = "HOME_USER_STATE_REQ_SUCCESS"

//类型：首页-申请失败
export const HOME_USER_STATE_REQ_FAIL = "HOME_USER_STATE_REQ_FAIL"

//类型：认证页-申请成功
export const PROOF_USER_STATE_REQ_SUCCESS = "PROOF_USER_STATE_REQ_SUCCESS"

//类型：认证页-申请失败
export const PROOF_USER_STATE_REQ_FAIL = "PROOF_USER_STATE_REQ_FAIL"

//类型：认证页-号码-申请成功
export const PROOF_MOBILE_USER_STATE_REQ_SUCCESS = "PROOF_MOBILE_USER_STATE_REQ_SUCCESS"

//类型：认证页-号码-申请失败
export const PROOF_MOBILE_USER_STATE_REQ_FAIL = "PROOF_MOBILE_USER_STATE_REQ_FAIL"

//类型：认证页-常用app-申请成功
export const PROOF_FREQUENTLY_APPS_USER_STATE_REQ_SUCCESS = "PROOF_FREQUENTLY_APPS_USER_STATE_REQ_SUCCESS"

//类型：认证页-常用app-申请失败
export const PROOF_FREQUENTLY_APPS_USER_STATE_REQ_FAIL = "PROOF_FREQUENTLY_APPS_USER_STATE_REQ_FAIL"

//类型：认证页-人脸-申请成功
export const PROOF_FACE_USER_STATE_REQ_SUCCESS = "PROOF_FACE_USER_STATE_REQ_SUCCESS"

//类型：认证页-人脸-申请失败
export const PROOF_FACE_USER_STATE_REQ_FAIL = "PROOF_FACE_USER_STATE_REQ_FAIL"

//类型：申请页-申请成功
export const APPLY_USER_STATE_REQ_SUCCESS = "APPLY_USER_STATE_REQ_SUCCESS"

//类型：申请页-申请失败
export const APPLY_USER_STATE_REQ_FAIL = "APPLY_USER_STATE_REQ_FAIL"

//类型：审核页-申请成功
export const REVIEW_USER_STATE_REQ_SUCCESS = "REVIEW_USER_STATE_REQ_SUCCESS"

//类型：审核页-申请失败
export const REVIEW_USER_STATE_REQ_FAIL = "REVIEW_USER_STATE_REQ_FAIL"

//类型：拒绝页-申请成功
export const REJECT_USER_STATE_REQ_SUCCESS = "REJECT_USER_STATE_REQ_SUCCESS"

//类型：拒绝页-申请失败
export const REJECT_USER_STATE_REQ_FAIL = "REJECT_USER_STATE_REQ_FAIL"

//类型：放款中页-申请成功
export const LOANING_USER_STATE_REQ_SUCCESS = "LOANING_USER_STATE_REQ_SUCCESS"

//类型：放款中页-申请失败
export const LOANING_USER_STATE_REQ_FAIL = "LOANING_USER_STATE_REQ_FAIL"

//类型：还款页-申请成功
export const REPAY_USER_STATE_REQ_SUCCESS = "REPAY_USER_STATE_REQ_SUCCESS"

//类型：还款页-申请失败
export const REPAY_USER_STATE_REQ_FAIL = "REPAY_USER_STATE_REQ_FAIL"

//类型：优惠券页-申请成功
export const COUPON_USER_STATE_REQ_SUCCESS = "COUPON_USER_STATE_REQ_SUCCESS"

//类型：优惠券页-申请失败
export const COUPON_USER_STATE_REQ_FAIL = "COUPON_USER_STATE_REQ_FAIL"

//类型：引导页-申请成功
export const GUIDE_USER_STATE_REQ_SUCCESS = "GUIDE_USER_STATE_REQ_SUCCESS"

//类型：引导页-申请失败
export const GUIDE_USER_STATE_REQ_FAIL = "GUIDE_USER_STATE_REQ_FAIL"




/** 认证步骤：基本信息 */
export const ProofStepBasic = "personal_info"

/** 认证步骤：工作信息 */
export const ProofStepWork = "work_info"

/** 认证步骤：联系人信息 */
export const ProofStepContact = "contact_info"

/** 认证步骤：号码验证信息 */
export const ProofStepMobile = "mobile_verify"

/** 认证步骤：号码验证信息 */
export const ProofStepFrequentlyUsedApps = "frequently_used_apps"

/** 认证步骤：人脸验证信息 */
export const ProofStepFace = "selfie_invo"

/** 认证步骤：bvn信息 */
export const ProofStepBvn = "bvn_info"

/** 认证步骤：银行卡信息 */
export const ProofStepAccount = "account_info"

/**
 * 获取用户状态
 * @returns thunk
 */
export const getUserState = (pageTag = PageTag.LAUNCH) => {
    return async (dispatch) => {
        //组装参数
        const params = baseParams()

        if (pageTag === PageTag.LAUNCH_REFERRER) {
            params.token = AppParams.tid || ""
        }

        //发起请求
        const res = await http.post(UrlGetUseState, params)
        const resCode = parseInt(res.code)
        const resObj = res.obj
        console.log("获取用户状态回调-结束：=======" + pageTag)

        //保存
        const success = (resCode === 0)
        if (pageTag === PageTag.LAUNCH_REFERRER) {
            const userInfoResult = {}
            userInfoResult.token = params.token
            userInfoResult.mobile = resObj.mobile || ""
            userInfoResult.userId = resObj.userId || ""

            // 保存用户信息
            setUserInfo(userInfoResult)
            setChatMobile(resObj.mobile + "")
        }
        // 保存状态
        dispatch(saveUserState(resObj, success, pageTag, dispatch))

        //保存打点,只针对认证页
        if (success) {
            if (pageTag === PageTag.PROOF) {
                const steps = resObj.steps
                if (isAllStepsFinish(steps)) {
                    //所有步骤完成
                    dispatch(saveEvent("PROOF_ALL_END"))
                } else {
                    const nextStep = steps[0]
                    dispatch(saveEvent("PROOF_" + nextStep + "_START"))
                }
            }
        }

        //如果有错误，提示
        if (!success) {
            if (!isEmptyString(res.msg)) {
                Toast.show(res.msg, 1)
            }
            if (res.msg === "User does not exist") {
                dispatch(setUserNotExist())
            }
        }
    }
}

/**
 * 更新用户认证信息
 * @param {*} data 
 */
export const updateUserState = (data, pageTag) => {
    console.log("更新user state: true , pageTag: " + pageTag)
    const resutType = pageTagCovertToReduxType(true, pageTag)
    return async (dispatch) => {
        const updateData = {
            type: resutType,
            payload: data
        }

        dispatch(updateData)
    }
}

/**
 * 将 返回状态 信息保存到 Redux 中
 * @param {*} data 
 * @returns 
 */
export const cleanUserState = () => {
    console.log("清空user state: ")
    return {
        type: USER_STATE_REQ_CLEAR,
        payload: {}
    }
}

/**
 * 将 返回状态 信息保存到 Redux 中
 * @param {*} data 
 * @returns 
 */
export const saveUserState = (data, success, pageTag) => {
    console.log("保存user state: " + success + ", pageTag: " + pageTag)
    const resutType = pageTagCovertToReduxType(success, pageTag)

    //如果失败，直接返回
    if (!success) {
        return {
            type: resutType,
            payload: {}
        }
    }

    //如果成功，格式化数据
    //当前手机号
    const readonlyMobile = data.readonlyMobile || null
    console.log("当前手机号: " + readonlyMobile)

    //优惠券
    const couponAmount = data.couponAmount || ""
    const couponFlag = data.couponFlag || ""
    const couponType = data.couponType || ""


    //待做步骤
    const steps = data.steps
    console.log("待做步骤组：" + steps)

    //如果所有步骤已完成, 直接返回
    if (isAllStepsFinish(steps)) {
        console.log("all finish")
        data.isProofFinish = true
        data.pageTitle = "Info"
        return {
            type: resutType,
            payload: data
        }
    }

    //取出第1个步骤
    const nextStep = steps[0]
    const allSteps = data.allSteps
    const stepTitles = data.stepTitles
    const indexOfNextStep = allSteps.indexOf(nextStep)
    const nextStepTitle = stepTitles[indexOfNextStep]
    console.log("下一个认证步骤：" + nextStep + ", inex: " + indexOfNextStep + ", title: " + nextStepTitle)

    //如果是 mobile_verify，直接返回
    if (isProofMobile(nextStep)) {
        const mobileFormItem = {}
        mobileFormItem.pageTitle = nextStepTitle
        mobileFormItem.stepTitle = nextStep
        mobileFormItem.isProofFinish = false
        mobileFormItem.readonlyMobile = readonlyMobile
        mobileFormItem.couponAmount = couponAmount
        mobileFormItem.couponFlag = couponFlag
        mobileFormItem.couponType = couponType
        mobileFormItem.userType = data.userType

        return {
            type: resutType,
            payload: mobileFormItem
        }
    }

    //如果是 frequently_apps，直接返回
    if (isProofFrequentlyUsedApps(nextStep)) {
        const mobileFormItem = {}
        mobileFormItem.pageTitle = nextStepTitle
        mobileFormItem.stepTitle = nextStep
        mobileFormItem.isProofFinish = false
        mobileFormItem.readonlyMobile = readonlyMobile
        mobileFormItem.couponAmount = couponAmount
        mobileFormItem.couponFlag = couponFlag
        mobileFormItem.couponType = couponType
        mobileFormItem.userType = data.userType

        return {
            type: resutType,
            payload: mobileFormItem
        }
    }

    //如果是 selfie_invo
    if (isProofFace(nextStep)) {
        const mobileFormItem = {}
        mobileFormItem.pageTitle = nextStepTitle
        mobileFormItem.stepTitle = nextStep
        mobileFormItem.isProofFinish = false
        mobileFormItem.readonlyMobile = readonlyMobile
        mobileFormItem.couponAmount = couponAmount
        mobileFormItem.couponFlag = couponFlag
        mobileFormItem.couponType = couponType
        mobileFormItem.userType = data.userType

        return {
            type: resutType,
            payload: mobileFormItem
        }
    }

    //取出对应的字段信息
    const nextFormItem = data[nextStep]
    nextFormItem.pageTitle = nextStepTitle
    nextFormItem.stepTitle = nextStep
    nextFormItem.isProofFinish = false
    nextFormItem.readonlyMobile = readonlyMobile
    nextFormItem.couponAmount = couponAmount
    nextFormItem.couponFlag = couponFlag
    nextFormItem.couponType = couponType
    nextFormItem.userType = data.userType


    //更新dataList中的content值，让它转变为受控状态
    nextFormItem.extDataList && nextFormItem.extDataList.forEach(item => {
        item.content = ""
        item.show = false
    })

    //更新dataList中的content值，让它转变为受控状态
    nextFormItem.dataList && nextFormItem.dataList.forEach(item => {
        item.content = ""
        item.realContent = ""
        item.show = false

        //工作、基本信息需要这些字段
        item.stateContent = ""
        item.cityContent = ""
        item.areaContent = ""

        //银行卡页需要这个字段
        item.bankCodeContent = ""

        //组装picker 列表数据 [[]]
        if (item.values && isActionSelect(item.action)) {
            item.values.forEach(value => {
                value.label = value.title
            })
            item.pickValues = []
            item.pickValues.push(item.values)
        }
    });

    //判断是否为联系人步骤
    if (isProofContact(nextStep)) {
        const itemCount = nextFormItem.itemCount
        const sectionList = []
        //nextFormItem.dataList=[{},{},{}]
        for (let i = 0; i < itemCount; i++) {
            let itemList = []
            let relationships = [...nextFormItem.relationShips[i]]
            console.log(relationships)

            for (let itemIndex = 0; itemIndex < nextFormItem.dataList.length; itemIndex++) {
                let oldItem = nextFormItem.dataList[itemIndex]
                let newItem = { ...oldItem }

                //如果action是 CONTACT_GROUP_RELATION, 组装values值
                if (isActionSelect(oldItem.action)) {
                    //清空
                    newItem.values = []
                    relationships.forEach(ship => {
                        let relationship = {
                            label: ship,
                            title: ship,
                            value: ship
                        }
                        newItem.values.push(relationship)
                    })

                    //组装picker 列表数据 [[]]
                    newItem.pickValues = []
                    newItem.pickValues.push(newItem.values)
                }

                itemList[itemIndex] = newItem
            }
            sectionList[i] = itemList
        }
        nextFormItem.sectionList = sectionList
    }
    console.log(nextFormItem)

    return {
        type: resutType,
        payload: nextFormItem
    }
}

/**
 * 判断是否完成所有认证步骤
 * @param {*} list 步骤列表
 * @returns 
 */
const isAllStepsFinish = list => {
    if (!isNullObject(list)) {
        return !(list.length > 0)
    }

    return true
}

/**
 * 判斷Action是否為选择地址
 * @param {*} action 
 * @returns 
 */
export const isActionAddressPick = action => {
    switch (action) {
        case "ADDRESS_PICK": return true
        default: return false
    }
}

/**
 * 判斷Action是否為选择日期
 * @param {*} action 
 * @returns 
 */
export const isActionDatePick = action => {
    switch (action) {
        case "DATE_PICK": return true
        default: return false
    }
}

/**
 * 判斷Action是否為选择银行
 * @param {*} action 
 * @returns 
 */
export const isActionBankPick = action => {
    switch (action) {
        case "BANK_PICK": return true
        default: return false
    }
}

/**
 * 判斷Action是否為列表选择
 * @param {*} action 
 * @returns 
 */
export const isActionSelect = action => {
    switch (action) {
        case "CONTACT_GROUP_RELATION": return true
        case "CREDIT_REPORT": return true
        case "GENDER_PICK": return true
        case "SELECT": return true
        default: return false
    }
}

/**
 * 判斷Action是否為输入
 * @param {*} action 
 * @returns 
 */
export const isActionInput = action => {
    switch (action) {
        case "INPUT": return true
        case "CONTACT_GROUP_NAME": return true
        case "CONTACT_GROUP_PHONE": return true
        default: return false
    }
}

/**
 * 判斷是否为联系人认证页
 * @param {*} step 
 * @returns 
 */
export const isProofContact = step => {
    switch (step) {
        case ProofStepContact: return true
        default: return false
    }
}

/**
 * 判斷是否为号码认证页
 * @param {*} step 
 * @returns 
 */
export const isProofMobile = step => {
    switch (step) {
        case ProofStepMobile: return true
        default: return false
    }
}

/**
 * 判斷是否为常用app认证页
 * @param {*} step 
 * @returns 
 */
export const isProofFrequentlyUsedApps = step => {
    switch (step) {
        case ProofStepFrequentlyUsedApps: return true
        default: return false
    }
}

/**
 * 判斷是否为人脸认证页
 * @param {*} step 
 * @returns 
 */
export const isProofFace = step => {
    switch (step) {
        case ProofStepFace: return true
        default: return false
    }
}

/**
 * 判斷是否为绑卡认证页
 * @param {*} step 
 * @returns 
 */
export const isProofBankAccount = step => {
    switch (step) {
        case ProofStepAccount: return true
        default: return false
    }
}

/**
 * 获取状态类型
 */
const pageTagCovertToReduxType = (success, pageTag) => {
    if (success) {
        switch (pageTag) {
            case PageTag.LAUNCH: return LAUNCH_USER_STATE_REQ_SUCCESS
            case PageTag.LAUNCH_REFERRER: return LAUNCH_REFERRER_USER_STATE_REQ_SUCCESS
            case PageTag.LOGIN: return LOGIN_USER_STATE_REQ_SUCCESS
            case PageTag.HOME: return HOME_USER_STATE_REQ_SUCCESS
            case PageTag.PROOF: return PROOF_USER_STATE_REQ_SUCCESS
            case PageTag.PROOF_MOBILE: return PROOF_MOBILE_USER_STATE_REQ_SUCCESS
            case PageTag.PROOF_FREQUENTLY_APPS: return PROOF_FREQUENTLY_APPS_USER_STATE_REQ_SUCCESS
            case PageTag.PROOF_FACE: return PROOF_FACE_USER_STATE_REQ_SUCCESS
            case PageTag.APPLY: return APPLY_USER_STATE_REQ_SUCCESS
            case PageTag.REVIEW: return REVIEW_USER_STATE_REQ_SUCCESS
            case PageTag.REJECT: return REJECT_USER_STATE_REQ_SUCCESS
            case PageTag.LOANING: return LOANING_USER_STATE_REQ_SUCCESS
            case PageTag.REPAY: return REPAY_USER_STATE_REQ_SUCCESS
            case PageTag.COUPON: return COUPON_USER_STATE_REQ_SUCCESS
            case PageTag.GUIDE: return GUIDE_USER_STATE_REQ_SUCCESS
            default: return USER_STATE_REQ_SUCCESS
        }
    } else {
        switch (pageTag) {
            case PageTag.LAUNCH: return LAUNCH_USER_STATE_REQ_FAIL
            case PageTag.LAUNCH_REFERRER: return LAUNCH_REFERRER_USER_STATE_REQ_FAIL
            case PageTag.LOGIN: return LOGIN_USER_STATE_REQ_FAIL
            case PageTag.HOME: return HOME_USER_STATE_REQ_FAIL
            case PageTag.PROOF: return PROOF_USER_STATE_REQ_FAIL
            case PageTag.PROOF_MOBILE: return PROOF_MOBILE_USER_STATE_REQ_FAIL
            case PageTag.PROOF_FREQUENTLY_APPS: return PROOF_FREQUENTLY_APPS_USER_STATE_REQ_FAIL
            case PageTag.PROOF_FACE: return PROOF_FACE_USER_STATE_REQ_FAIL
            case PageTag.APPLY: return APPLY_USER_STATE_REQ_FAIL
            case PageTag.REVIEW: return REVIEW_USER_STATE_REQ_FAIL
            case PageTag.REJECT: return REJECT_USER_STATE_REQ_FAIL
            case PageTag.LOANING: return LOANING_USER_STATE_REQ_FAIL
            case PageTag.REPAY: return REPAY_USER_STATE_REQ_FAIL
            case PageTag.COUPON: return COUPON_USER_STATE_REQ_FAIL
            case PageTag.GUIDE: return GUIDE_USER_STATE_REQ_FAIL
            default: return USER_STATE_REQ_FAIL
        }
    }
}