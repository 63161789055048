
import http, { baseParams } from "@/utils/request";
import { getUserState } from "./userState";
import { PageTag } from "@/utils/pageTag";
import { isEmptyString } from "@/utils/utils";
import { Toast } from "antd-mobile";
import { saveEvent } from "./saveEvent";
import { UrlDataSmsCodeLogin } from "@/utils/apiUrl";

//类型：请求成功
export const DATA_SMS_CODE_LOIN_REQ_SUCCESS = "DATA_SMS_CODE_LOIN_REQ_SUCCESS"

//类型：申请失败
export const DATA_SMS_CODE_LOIN_REQ_FAIL = "DATA_SMS_CODE_LOIN_REQ_FAIL"

//类型：重置
export const DATA_SMS_CODE_LOIN_REQ_RESET = "DATA_SMS_CODE_LOIN_REQ_RESET"

/**
 * 运营商验证码登录
 * @param {String} mobile
 * @param {String} code
 * @param {String} fetchOrderId
 * @returns thunk
 */
export const dataSmsCodeLogin = (mobile, code, fetchOrderId) => {
    return async (dispatch) => {
        //组装参数
        const baseParam = baseParams()
        baseParam.mobile = mobile + ""
        baseParam.code = code + ""
        baseParam.fetchOrderId = fetchOrderId+""

        //发起请求
        const res = await http.post(UrlDataSmsCodeLogin, baseParam)
        const resCode = parseInt(res.code)
        console.log("运营商验证码登录回调-结束：=======")

        //保存
        const success = (resCode === 0)
        if (success) {
            //打点
            dispatch(saveEvent(PageTag.PROOF_MOBILE + "_END"))

            //获取新的用户状态
            dispatch(getUserState(PageTag.PROOF_MOBILE))
        }

        //如果有错误，提示
        if (!success) {
            if (!isEmptyString(res.msg)) {
                Toast.show(res.msg, 1)
            }
            dispatch(saveProofMobileDataFailState())
        }
    }
}

/**
 * 将 返回状态 信息保存到 Redux 中
 * @returns 
 */
export const saveProofMobileDataFailState = () => {
    return {
        type: DATA_SMS_CODE_LOIN_REQ_FAIL,
        payload: {}
    }
}

/**
 * 将 返回状态 信息保存到 Redux 中
 * @returns 
 */
export const resetProofMobileDataFailState = () => {
    return {
        type: DATA_SMS_CODE_LOIN_REQ_RESET,
        payload: {}
    }
}

