

/**
 * App 传过来的接口入参
 */
export default class AppParams {
    /** 广告id */
    static urlParams = ""

    /** referrer */
    static referrer = ""

    /** t */
    static tid = ""

    /** 打印输出 */
    static printlog() {
        console.log("=======接收链接传过来的参数：")
        console.log("=======urlParams: " + AppParams.urlParams)
        console.log("=======tid: " + AppParams.tid)
    }
}