
import http, { baseParams } from "@/utils/request";
import { getUserState } from "./userState";
import { PageTag } from "@/utils/pageTag";
import { isEmptyString } from "@/utils/utils";
import { Toast } from "antd-mobile";
import { saveEvent } from "./saveEvent";

//类型：申请失败
export const SAVE_PROOF_REQ_FAIL = "SAVE_PROOF_REQ_FAIL"

//类型：重置
export const SAVE_PROOF_REQ_RESET = "SAVE_PROOF_REQ_RESET"

/**
 * 保存认证步骤信息
 * @param {String} apiUrl
 * @param {*} proofData
 * @returns thunk
 */
export const saveProofData = (apiUrl, proofData, curStep) => {
    return async (dispatch) => {
        //组装参数
        const baseParam = baseParams()
        const params = { ...baseParam, ...proofData }

        //发起请求
        const res = await http.post(apiUrl, params)
        const resCode = parseInt(res.code)
        console.log("保存认证步骤信息回调-结束：=======")

        //保存
        const success = (resCode === 0)
        if (success) {
            //打点
            dispatch(saveEvent("PROOF_" + curStep + "_END"))

            //获取新的用户状态
            dispatch(getUserState(PageTag.PROOF))
        }

        //如果有错误，提示
        if (!success) {
            if (!isEmptyString(res.msg)) {
                Toast.show(res.msg, 1)
            }
            dispatch(saveProofDataFailState())
        }
    }
}

/**
 * 将 返回状态 信息保存到 Redux 中
 * @param {*} apiUrl 
 * @param {*} data 
 * @returns 
 */
export const saveProofDataFailState = () => {
    return {
        type: SAVE_PROOF_REQ_FAIL,
        payload: {}
    }
}

/**
 * 将 返回状态 信息保存到 Redux 中
 * @param {*} apiUrl 
 * @param {*} data 
 * @returns 
 */
export const resetProofDataFailState = () => {
    return {
        type: SAVE_PROOF_REQ_RESET,
        payload: {}
    }
}

