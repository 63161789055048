import React, { useState, useEffect } from "react";
import '../FormSelect/form.css'

let timeId

export default function FormOtpInput({ title, canStart = false, onClickCallback, onStartCallback, ...rest }) {

    const maxCount = 60

    const defaultPlaceholder = "OTP"

    //初始化定时器id
    // eslint-disable-next-line no-undef

    //初始化倒计时文案
    const [codeMessage, setCodeMessage] = useState(defaultPlaceholder);

    //初始化倒计时长
    const [time, setTime] = useState(maxCount);

    //初始化是否禁用
    const [isDisabled, setIsDisabled] = useState(false)

    //初始化是否能开始
    const [isCutDownNow, setIsCutDownNow] = useState(false)


    useEffect(() => clearInterval(timeId), [])

    useEffect(() => {
        if (time > 0 && time < maxCount) {
            //开始
            let curCount = time + 's'
            console.log("curCount:" + curCount)
            setIsDisabled(true);
            setIsCutDownNow(true)
            setCodeMessage(curCount)
        } else {
            clearInterval(timeId);
            setIsDisabled(false);
            setTime(maxCount)
            setCodeMessage(defaultPlaceholder);
        }
    }, [time])


    function startCountDown() {
        if (!isDisabled) {
            timeId = setInterval(() => setTime(t => (t - 1)), 1000);
            setIsDisabled(true)
            setIsCutDownNow(false)
        }
    }

    function onStartClick() {
        onClickCallback()
    }

    if (canStart) {
        if (!isCutDownNow) {
            console.log("is cut down now= ")
            setIsCutDownNow(true)
            startCountDown()
        }
    }

    return (
        <div className="formContent">
            {title && <span className="formTitle">{title}</span>}
            <div className="formValueContentOtp">
                <input className="formValueOtp" maxLength={6} {...rest} />
                <div className="formExtra" disabled={isDisabled} onClick={onStartClick}>{codeMessage}</div>
            </div>
            <div className="formSplitLine" hidden></div>
        </div>
    )
}

// 别忘了 forwardRef 
// export default forwardRef(OtpInput);  
