import React from "react";
import '../FormSelect/form.css'
import { Input } from "antd-mobile";

export default function FormInput({ title, ...rest }) {
    return (
        <div className="formContent">
            <span className="formTitle">{title}</span>
            <div className="formValueContent">
                <Input className="formValue"  {...rest} clearable="true" />
            </div>
            <div className="formSplitLine" hidden></div>
        </div>
    )
}