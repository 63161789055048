// import { generateAudid } from "./aduid"
import { getAduidInfo, getDeviceIDInfo, setAduidInfo, setDeviceIDInfo } from "./storage"
import { isEmptyString } from "./utils"
import { MD5 } from 'crypto-js';

/** 无痕模式下的 aduid 值 */
export const ADUID_NO_TRACE = "wuheng"
/** 无痕模式下的 aduid 值 */
export const ADUID_NO_TRACE_SAVE_ERR = "wuheng_save_err"
/** 无痕模式下的 aduid 值 */
export const ADUID_NO_TRACE_READ_EMPTY = "wuheng_read_empty"


/**
 * 生成新的 aduid值
 */
export const generateDeviceIdV2 = (deviceId) => {
    const uagent = navigator.userAgent
    console.log("generate uagent: " + uagent)

    const waitString = deviceId + "_" + uagent
    console.log("generate waitString: " + waitString)

    const hashString = MD5(waitString).toString()
    console.log("generate hashString: " + hashString)

    return hashString
}

/**
 * 设备类
 */
export default class DeviceID {

    /////////////////////////// VISITORID ////////////////////////////////
    // visitorId
    static visitorId = ""

    /**
     * 保存 visitorId
     * @param {*} vid 
     */
    static saveVisitorId(vid) {
        this.visitorId = vid
        console.log("saveVisitorId: " + vid)
        setDeviceIDInfo(this.visitorId)
    }

    /**
     * 获取 visitorId
     * @returns {String} visitorId
     */
    static getVisitorId() {
        if (isEmptyString(this.visitorId)) {
            this.visitorId = getDeviceIDInfo()
        }
        console.log("getVisitorId: " + this.visitorId)
        return this.visitorId
    }

    /**
     * 是否存在有效的visitor id
     * @returns 
     */
    static isValidVistorId() {
        if (isEmptyString(this.getVisitorId)) {
            console.log("device id is null, 需要重新获取")
            return false
        }
        return true
    }

    /////////////////////////// ADUID ////////////////////////////////
    // aduid
    static aduid = ""

    /**
     * 获取 aduid
     * @returns {String} aduid
     */
    static getAduid() {
        if (isEmptyString(this.aduid)) {
            const localAduid = getAduidInfo()
            if (isEmptyString(localAduid)) {
                //1 如果保存不了，默认是无痕模式
                try {
                    setAduidInfo(this.aduid)
                } catch (e) {
                    this.aduid = ADUID_NO_TRACE_SAVE_ERR
                }

                //2 保存后，如果读出来为空，，默认是无痕模式
                const localAduidNext = getAduidInfo()
                if (isEmptyString(localAduidNext)) {
                    this.aduid = ADUID_NO_TRACE_READ_EMPTY
                } else {
                    this.aduid = localAduidNext
                    if (this.aduid.indexOf("_" + ADUID_NO_TRACE) !== -1) {
                        this.aduid = ADUID_NO_TRACE
                    }
                }
            } else {
                this.aduid = localAduid
            }
        } else {
            if (this.aduid.indexOf("_" + ADUID_NO_TRACE) !== -1) {
                this.aduid = ADUID_NO_TRACE
            }
        }
        console.log("getAduid: " + this.aduid)
        return this.aduid
    }

    /**
    * 是否存在有效的aduid
    * @returns 
    */
    static isValidAduid() {
        if (isEmptyString(this.aduid)) {
            console.log("aduid id is null, 需要重新获取")
            return false
        }
        return true
    }
}