import React, { useEffect, useState } from "react";
import './hotlineDrawer.css'
import icon_drawer_close from "@/assets/icon_drawer_close.png"

export default function HotlineDrawer({ isOpen, onClose, children }) {

    //设置显示状态
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    //监听状态
    useEffect(() => {
        setIsDrawerOpen(isOpen)
    }, [isOpen])

    //关闭显示监听
    const onHandleCloseDrawer = () => {
        setIsDrawerOpen(false);
        onClose();
    }

    return (
        <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
            <div className="drawer-content">
                {/* 关闭按钮 */}
                <div className="close-button">
                    <img className="close-button-img" src={icon_drawer_close} onClick={() => { onHandleCloseDrawer() }} alt='' />
                </div>
                <div className="drawer-bounds">
                    {children}
                </div>
            </div>
        </div>
    )
}

