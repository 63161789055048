
import http, { baseParams } from "@/utils/request";
import { UrlConfirmLoanApply } from "@/utils/apiUrl";
import { isEmptyString } from "@/utils/utils";
import { Toast } from "antd-mobile";

//类型：申请成功
export const LOAN_APPLY_REQ_SUCCESS = "LOAN_APPLY_REQ_SUCCESS"

//类型：申请清空
export const LOAN_APPLY_REQ_CLEAR = "LOAN_APPLY_REQ_CLEAR"

//类型：申请失败
export const LOAN_APPLY_REQ_FAIL = "LOAN_APPLY_REQ_FAIL"

/**
 * 确认贷款申请
 * @returns thunk
 */
export const confirmLoanApply = (amount = "", days = "") => {
    return async (dispatch) => {
        //组装参数
        const params = baseParams()
        params.amount = amount
        params.days = days

        //发起请求
        const res = await http.post(UrlConfirmLoanApply, params)
        const resCode = parseInt(res.code)
        const resObj = res.obj
        console.log("确认贷款申请回调-结束：=======")

        //保存
        const success = (resCode === 0)
        dispatch(saveConfirmApplyState(resObj, success))

        //如果有错误，提示
        if (!success) {
            if (!isEmptyString(res.msg)) {
                Toast.show(res.msg, 1)
            }
        }
    }
}

/**
 * 将 返回状态 信息保存到 Redux 中
 * @param {*} data 
 * @returns 
 */
export const saveConfirmApplyState = (data, success) => {
    return {
        type: success ? LOAN_APPLY_REQ_SUCCESS : LOAN_APPLY_REQ_FAIL,
        payload: data
    }
}

/**
 * 将 返回状态 信息更新到 Redux 中
 * @param {*} data 
 * @returns 
 */
export const updateConfirmApplyState = (data, success = true) => {
    return {
        type: success ? LOAN_APPLY_REQ_CLEAR : LOAN_APPLY_REQ_FAIL,
        payload: data
    }
}