import React, { useEffect, useState } from 'react';
import './paidaskdialog.css'
import { saveEvent } from '@/store/actions/saveEvent';
import { useDispatch } from 'react-redux';
import { Mask } from 'antd-mobile';


export default function PaidAskDialog({ visible, onDidPaid, onNotPaid, onClose }) {

    //获取 Redux 分发器
    const dispatch = useDispatch()

    const [needDisplay, setNeedDisplay] = useState(false);

    //监听
    useEffect(() => {
        setNeedDisplay(visible)
    }, [visible])

    //打点接口
    const doHitEvent = async (type, remark = "") => {
        console.log("doHitEvent: " + type + " , " + remark);

        try {
            //获取Action
            const action = saveEvent(type, remark)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //点击监听
    const onHandleDidPaidClick = () => {
        // 打点
        doHitEvent("REPAY_DID_PAID_CLICK", "")
        // 回调
        onDidPaid && onDidPaid()
    }

    //点击监听
    const onHandleNotPaidClick = () => {
        // 打点
        doHitEvent("REPAY_NOT_PAID_CLICK", "")
        // 回调
        onNotPaid && onNotPaid()
    }

    return (
        <Mask visible={needDisplay} opacity={0.6}
            onMaskClick={() => { }}>
            <div className="paidaAskDialogOverlayBg">
                <div className="paidaAskDialogOverlayContent">
                    <div className='paidAskDialogTitle'>Have you paid for this order?</div>
                    <div className='paidAskDialogLine'></div>
                    <div className='paidAskDialogButtonContent'>
                        <div className='paidAskDialogButtonDidPaid' onClick={onHandleDidPaidClick}>I have paid</div>
                        <div className='paidAskDialogButtonSpace'></div>
                        <div className='paidAskDialogButtonNotPaid' onClick={onHandleNotPaidClick}>Not paid</div>
                    </div>
                </div>
            </div>
        </Mask >
    );
};
