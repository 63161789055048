import React from 'react';
import './index.css';
// import '@scss/index.scss'
import App from './App';
import store from './store';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import AppParams from "@/utils/appParams";

// ReactDOM.render(
//   <Provider store={store}>
//     <App/>
//   </Provider>,
//   document.getElementById('root')
// )

// 获取完整的URL链接
const currentUrl = window.location.href;
console.log("=========入口链接：" + currentUrl)

// 获取URL参数
const searchParams = new URLSearchParams(window.location.search);
const referrer = searchParams.get('referrer');
const tid = searchParams.get('tid');

//保存app入参
AppParams.urlParams = searchParams.toString()
AppParams.referrer = referrer || ""
AppParams.tid = tid || ""
AppParams.printlog()

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// reportWebVitals();
