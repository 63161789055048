
import http, { baseParams } from "@/utils/request";
import { UrlGetDataSmsCode } from "@/utils/apiUrl";
import { isEmptyString } from "@/utils/utils";
import { Toast } from "antd-mobile";

//类型：申请成功
export const SEND_DATA_SMS_CODE_REQ_SUCCESS = "SEND_DATA_SMS_CODE_REQ_SUCCESS"

//类型：申请失败
export const SEND_DATA_SMS_CODE_REQ_FAIL = "SEND_DATA_SMS_CODE_REQ_FAIL"

//类型：清空请求
export const SEND_DATA_SMS_CODE_REQ_CLEAR = "SEND_DATA_SMS_CODE_REQ_CLEAR"


/**
 * 获取运营商验证码
 * @returns thunk
 */
export const sendDataSmsCode = (mobile) => {
    return async (dispatch) => {
        //组装参数
        const params = baseParams()
        params.mobile = mobile+""

        //发起请求
        const res = await http.post(UrlGetDataSmsCode, params)
        const resCode = parseInt(res.code)
        const resObj = res.obj
        console.log("获取运营商验证码回调-结束：=======")

        //保存
        const success = (resCode === 0)
        dispatch(saveSendDataSmsCodeState(resObj, success))

        //如果有错误，提示
        if (!success) {
            if (!isEmptyString(res.msg)) {
                Toast.show(res.msg, 1)
            }
        }
    }
}

/**
 * 将 返回状态 信息保存到 Redux 中
 * @param {*} data 
 * @returns 
 */
export const saveSendDataSmsCodeState = (data, success) => {
    return {
        type: success ? SEND_DATA_SMS_CODE_REQ_SUCCESS : SEND_DATA_SMS_CODE_REQ_FAIL,
        payload: data
    }
}

/**
 * 将 返回状态 信息保存到 Redux 中
 * @param {*} tokens 
 * @returns 
 */
export const cleanSendDataSmsCodeState = (obj) => {
    return {
        type: SEND_DATA_SMS_CODE_REQ_CLEAR,
        payload: {...obj}
    }
}