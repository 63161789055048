import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { isEmptyString } from './utils';

/**
 * 对字符串进行hash值
 * @param {*} text 
 * @returns hash后的值
 */
export const hashText = text => {
    let hash = 0;
    if (text.length === 0) return hash

    for (let i = 0; i < text.length; i++) {
        let char = text.charCodeAt(i)
        hash = ((hash << 5) - hash) + char
        hash = hash & hash
    }
    return hash
}

/**
 * 生成新的 aduid值
 */
export const generateAudidV2 = () => {
    //先判断缓存存不存在
    const cookieKey = "aduid"
    const aduid = Cookies.get(cookieKey) || "";
    console.log("generate unid cookies: " + aduid)

    //写cookies
    if (isEmptyString(aduid)) {
        console.log("generate unid is empty: ")
        const unid = uuidv4();
        console.log("generate unid new: " + unid)

        //保存
        //https://www.loansalad.info
        Cookies.set(cookieKey, unid, { expires: 1000 }); // 设置过期时间为1000天

        //返回
        return unid
    }

    return aduid
}

/**
 * 绘画图标
 */
export const generateAudid = () => {
    var result = "";
    try {
        // Very simple now, need to make it more complex (geo shapes etc)
        // var canvas = document.createElement('canvas');
        // canvas.width = 2000;
        // canvas.height = 200;
        // canvas.style.display = 'inline';
        // var ctx = canvas.getContext('2d');

        // Text with lowercase/uppercase/punctuation symbols
        // 绘制一个透明的像素点
        // const x = 0; // 像素点的x坐标
        // const y = 0; // 像素点的y坐标
        // const size = 1; // 像素点的大小
        // const alpha = 0; // 透明度 (0 表示完全透明)
        // ctx.fillStyle = `rgba(0, 0, 0, ${alpha})`; // 使用rgba颜色来表示透明度
        // ctx.fillRect(x, y, size, size);

        // 获取用户代理字符串
        // const userAgent = navigator.userAgent;
        // console.log("userAgent: " + userAgent)

        const unid = uuidv4();
        console.log("generate unid: " + unid)


        // const combinedDeviceId = `${userAgent}-${unid}`;
        // console.log("combinedDeviceId: " + combinedDeviceId)

        // const buffer = Buffer.from(combinedDeviceId, "utf-8");
        // result = buffer.toString("base64");
        // result = btoa(combinedDeviceId)

        result = unid
        console.log("generate unid: " + result)

        // if (canvas.toDataURL) {
        //     result += ';canvas fp:' + canvas.toDataURL();
        //     console.log("result: " + result)
        // }
    } catch (e) {
        console.log(e)
    }

    return result;
}
