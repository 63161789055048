
import http, { baseParams } from "@/utils/request";
import { UrlGetLoanApplyDetailByAmount, UrlGetLoanDetail } from "@/utils/apiUrl";
import { isEmptyObject, isEmptyString } from "@/utils/utils";
import { Toast } from "antd-mobile";
import { AmountRange } from "@/utils/amountRange";



//类型：请求成功
export const LOAN_DETAIL_REQ_SUCCESS = "LOAN_DETAIL_REQ_SUCCESS"

//类型：请求成功
export const LOAN_DETAIL_BY_AMOUNT_REQ_SUCCESS = "LOAN_DETAIL_BY_AMOUNT_REQ_SUCCESS"

//类型：请求失败
export const LOAN_DETAIL_REQ_FAIL = "LOAN_DETAIL_REQ_FAIL"

//类型：请求成功
export const LOAN_DETAIL_UPDATE_REQ_SUCCESS = "LOAN_DETAIL_UPDATE_REQ_SUCCESS"

//类型：请求清空
export const LOAN_DETAIL_REQ_CLEAR = "LOAN_DETAIL_REQ_CLEAR"


/**
 * 获取申请页贷款详情
 * @returns thunk
 */
export const getLoanDetail = () => {
    return async (dispatch) => {
        //组装参数
        const params = baseParams()

        //发起请求
        const res = await http.post(UrlGetLoanDetail, params)
        const resCode = parseInt(res.code)
        const resObj = res.obj
        console.log("获取申请页贷款详情回调-结束：=======")

        //判断返回是否成功
        const success = (resCode === 0)
        if (success) {
            //货币符号
            resObj.currency = "NGN"

            //用户类型 OLD NEW
            const userType = resObj.userType
            if (userType) {
                resObj.isOldUser = userType === 'OLD'
                resObj.isNewUser = userType === 'NEW'
            } else {
                resObj.isOldUser = false
                resObj.isNewUser = true
            }

            //测试数据
            resObj.amountList = [AmountRange.rank1, AmountRange.rank2, AmountRange.rank3]
            resObj.daysList = [7, 14, 30]
            resObj.canSelectAmount = true
            resObj.canSelectDay = false

            //组装页面数据-金额选项
            //如果有返回 amountList，以它为准
            if (resObj.amountList && resObj.amountList.length > 0) {
                resObj.amountOptions = []
                for (let i = 0; i < resObj.amountList.length; i++) {
                    resObj.amountOptions[i] = {
                        label: resObj.amountList[i] + "",
                        value: "1",
                        selected: (i === 0)
                    }
                }
            } else {
                const amount1 = parseInt(resObj.amount)
                const amount2 = amount1 * 5
                const amount3 = amount1 * 25
                resObj.amountOptions = [
                    { label: amount1 + "", value: "1", selected: true },
                    { label: amount2 + "", value: "2", disabled: false, selected: false },
                    { label: amount3 + "", value: "3", disabled: false, selected: false }
                ]
            }

            //组装页面数据-日期选项
            //如果有返回 daysList，以它为准
            if (resObj.daysList && resObj.daysList.length > 0) {
                resObj.dayOptions = []
                for (let i = 0; i < resObj.daysList.length; i++) {
                    resObj.dayOptions[i] = {
                        label: resObj.daysList[i] + "",
                        value: "1",
                        selected: (i === 0)
                    }
                }
            } else {
                const days1 = parseInt(resObj.days)
                const days2 = days1 * 2
                let days3Temp = days1 * 3
                const days3 = (days3Temp < 30) ? 30 : days3Temp
                resObj.dayOptions = [
                    { label: days1 + " days", value: "1", selected: true },
                    { label: days2 + " days", value: "2", disabled: false, selected: false },
                    { label: days3 + " days", value: "3", disabled: false, selected: false }
                ]
            }
        }

        //保存
        dispatch(saveLoanDetailState(resObj, success))

        //如果有错误，提示
        if (!success) {
            if (!isEmptyString(res.msg)) {
                Toast.show(res.msg, 1)
            }
        }
    }
}

/**
 * 获取申请页贷款详情
 * @returns thunk
 */
export const getLoanDetailByAmount = (amount, loanDetailInfo = {}) => {
    return async (dispatch) => {
        //2000,4000,6000
        let selectRank = "1"
        if (amount === AmountRange.rank2) {
            selectRank = "2"
        } else if (amount === AmountRange.rank3) {
            selectRank = "3"
        }

        //组装参数
        const params = baseParams()
        params.amount = amount
        params.selectRank = selectRank

        //发起请求
        const res = await http.post(UrlGetLoanApplyDetailByAmount, params)
        const resCode = parseInt(res.code)
        const resObj = res.obj
        const success = (resCode === 0)
        console.log("获取申请页金额试算详情回调-结束：=======")

        //保存
        if (success) {
            //货币符号
            resObj.currency = "NGN"

            if (isEmptyObject(loanDetailInfo)) {
                //测试数据
                resObj.amountList = [AmountRange.rank1, AmountRange.rank2, AmountRange.rank3]
                resObj.daysList = [7, 14, 30]
                resObj.canSelectAmount = true
                resObj.canSelectDay = false

                //用户类型 OLD NEW
                const userType = resObj.userType
                if (userType) {
                    resObj.isOldUser = userType === 'OLD'
                    resObj.isNewUser = userType === 'NEW'
                } else {
                    resObj.isOldUser = false
                    resObj.isNewUser = true
                }

                //如果是老客，直接显示真实金额
                if (resObj.isOldUser) {
                    resObj.amountList = [resObj.amount, resObj.amount, resObj.amount]
                }

                //组装金额
                if (resObj.amountList && resObj.amountList.length > 0) {
                    resObj.amountOptions = []
                    let firstCheck = false
                    for (let i = 0; i < resObj.amountList.length; i++) {
                        if (resObj.isOldUser && (i === 0)) {
                            firstCheck = true
                        } else {
                            firstCheck = false
                        }
                        resObj.amountOptions[i] = {
                            label: resObj.amountList[i] + "",
                            value: "1",
                            locked: (i !== 0),
                            selected: firstCheck
                        }
                    }
                }

                //组装日期
                if (resObj.daysList && resObj.daysList.length > 0) {
                    resObj.dayOptions = []
                    for (let i = 0; i < resObj.daysList.length; i++) {
                        resObj.dayOptions[i] = {
                            label: resObj.daysList[i] + "",
                            value: "1",
                            selected: (i === 0)
                        }
                    }
                }
            } else {
                resObj.canSelectAmount = loanDetailInfo.canSelectAmount
                resObj.canSelectDay = loanDetailInfo.canSelectDay
                resObj.isOldUser = loanDetailInfo.isOldUser
                resObj.isNewUser = loanDetailInfo.isNewUser
                resObj.amountOptions = loanDetailInfo.amountOptions
                resObj.dayOptions = loanDetailInfo.dayOptions
            }


            //优惠券
            resObj.couponAmount = resObj.couponAmount || ""
            resObj.couponFlag = resObj.couponFlag || ""
            resObj.couponType = resObj.couponType || ""
            resObj.couponOrderAmount = resObj.couponOrderAmount || ""
            resObj.couponRepayAmount = resObj.couponRepayAmount || ""

            // else {
            //     newLoanDetailInfo.amount = resObj.amount
            //     newLoanDetailInfo.days = resObj.days
            //     newLoanDetailInfo.interest = resObj.interest
            //     newLoanDetailInfo.interestAndServiceFee = resObj.interestAndServiceFee
            //     newLoanDetailInfo.interestRate = resObj.interestRate
            //     newLoanDetailInfo.overdueRate = resObj.overdueRate
            //     newLoanDetailInfo.processingFee = resObj.processingFee
            //     newLoanDetailInfo.realAmount = resObj.realAmount
            //     newLoanDetailInfo.repayAmount = resObj.repayAmount
            //     newLoanDetailInfo.repayProFee = resObj.repayProFee
            // }
        }

        //保存状态
        dispatch(saveLoanDetailByAmountState(resObj, success))

        //如果有错误，提示
        if (!success) {
            if (!isEmptyString(res.msg)) {
                Toast.show(res.msg, 1)
            }
        }
    }
}

/**
 * 将 返回状态 信息保存到 Redux 中
 * @param {*} data 
 * @returns 
 */
export const saveLoanDetailState = (data, success) => {
    return {
        type: success ? LOAN_DETAIL_REQ_SUCCESS : LOAN_DETAIL_REQ_FAIL,
        payload: data
    }
}

/**
 * 将 返回状态 信息保存到 Redux 中
 * @param {*} data 
 * @returns 
 */
export const saveLoanDetailByAmountState = (data, success) => {
    return {
        type: success ? LOAN_DETAIL_BY_AMOUNT_REQ_SUCCESS : LOAN_DETAIL_REQ_FAIL,
        payload: data
    }
}

/**
 * 将 返回状态 信息更新到 Redux 中
 * @param {*} data 
 * @returns 
 */
export const updateLoanDetailState = (data, success = true) => {
    return {
        type: success ? LOAN_DETAIL_UPDATE_REQ_SUCCESS : LOAN_DETAIL_REQ_FAIL,
        payload: data
    }
}

/**
 * 将 返回状态 信息保存到 Redux 中
 * @param {*} data 
 * @returns 
 */
export const cleanLoanDetailState = () => {
    console.log("清空 loan detail state: ")
    return {
        type: LOAN_DETAIL_REQ_CLEAR,
        payload: {}
    }
}