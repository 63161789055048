
import http, { baseParams } from "@/utils/request";
import { UrlGetAppConfig } from "@/utils/apiUrl";
import { isEmptyString, toList } from "@/utils/utils";
import { Toast } from "antd-mobile";

//类型：请求成功
export const APP_CONFIG_REQ_SUCCESS = "APP_CONFIG_REQ_SUCCESS"

//类型：请求失败
export const APP_CONFIG_REQ_FAIL = "APP_CONFIG_REQ_FAIL"

/**
 * 获取app配置
 * @returns thunk
 */
export const getAppConfig = () => {
    return async (dispatch) => {
        //组装参数
        const params = baseParams()

        //发起请求
        const res = await http.post(UrlGetAppConfig, params)
        const resCode = parseInt(res.code)
        const resObj = res.obj
        console.log("获取app配置回调-结束：=======" + resCode)
        console.log(resObj)
        console.log("获取app配置回调-结束：=======")

        //配置mobile列表
        const mobileContent = resObj.mobile
        const mobileList = toList(mobileContent, ",")
        resObj.mobileList = mobileList

        //配置email列表
        const emailContent = resObj.email
        const emailList = toList(emailContent, ",")
        resObj.emailList = emailList

        //保存
        const success = (resCode === 0)
        dispatch(saveAppConfigState(resObj, success))

        //如果有错误，提示
        if (!success) {
            if (!isEmptyString(res.msg)) {
                Toast.show(res.msg, 1)
            }
        }
    }
}

/**
 * 将 返回状态 信息保存到 Redux 中
 * @param {*} data 
 * @returns 
 */
export const saveAppConfigState = (data, success) => {
    return {
        type: success ? APP_CONFIG_REQ_SUCCESS : APP_CONFIG_REQ_FAIL,
        payload: data
    }

}