
import { LOAN_LIMIT_REQ_FAIL, LOAN_LIMIT_REQ_SUCCESS } from "../actions/loanLimit"

// 初始状态
const initState = {
    amount: '',
    increasedAmount1: '',
    increasedAmount2: '',
    currency: ''
}

// 操作 Token 状态信息的 reducer 函数
export const loanLimit = (state = initState, action) => {
    const { type, payload } = action
    //console.log('loan type: ' + type)

    switch (type) {
        case LOAN_LIMIT_REQ_SUCCESS: return { ...payload, status: type }
        case LOAN_LIMIT_REQ_FAIL: return { status: type }
        default: return state
    }
}