import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { LOAN_LIMIT_REQ_FAIL, LOAN_LIMIT_REQ_SUCCESS, getLoanLimit } from "@/store/actions/loanLimit";
import { useDispatch, useSelector } from "react-redux";
import { HOME_USER_STATE_REQ_FAIL, HOME_USER_STATE_REQ_SUCCESS, LAUNCH_USER_STATE_REQ_FAIL, LAUNCH_USER_STATE_REQ_SUCCESS, cleanUserState, getUserState, isProofFace, isProofFrequentlyUsedApps, isProofMobile } from "@/store/actions/userState";
import NavigateBar from "@/components/NavigateBar";
import icon_home_step1 from '@/assets/icon_home_step1.png'
import icon_home_step2 from '@/assets/icon_home_step2.png'
import icon_home_step3 from '@/assets/icon_home_step3.png'
import icon_home_increase1 from '@/assets/icon_home_increase1.png'
import icon_home_increase2 from '@/assets/icon_home_increase2.png'
import icon_home_coupon_tips from "@/assets/icon_home_coupon_tips.png"
import { DotLoading, Mask } from "antd-mobile";
import { PageTag } from "@/utils/pageTag";
import { saveEvent } from "@/store/actions/saveEvent";
import './home.css';
import '../../App.css'
import { saveLocation } from "@/store/actions/saveLocation";
import TextCarouselHorizontal from "@/components/TextCarouselHorizontal";
import icon_proof_face_notify from '@/assets/icon_proof_face_notify.png'
import icon_proof_face_more from '@/assets/icon_proof_face_more.png'
import '../Proof/proof.css';
import AddToHomeGifDialog from "@/components/AddToHomeGifDialog/AddToHomeGifDialog";
import AddHomeEvent from "@/utils/addHomeEvent";
import FacebookData from "@/utils/facebookData";
import CouponGuideDialog from "@/components/CouponGuideDialog/CouponGuideDialog";
import { CouponGuideMode } from "@/components/CouponGuideDialog/CouponGuideMode";
import { getNotRemindCoupon } from "@/utils/storage";
import { CouponFromSource } from "../Coupon/CouponFromSource";
import CouponClick from "../Coupon/CouponClick";


/**
 * 首页
 */
export default function Home() {

    //loading状态
    const [maskVisible, setMaskVisible] = useState(false)
    const [addHomeMaskIsHomeMode, setAddHomeMaskIsHomeMode] = useState(false);

    //显示add home弹框
    const [addHomeMaskVisible, setAddHomeMaskVisible] = useState(false);

    //显示coupon弹框
    const [couponGuideMaskVisible, setCouponGuideMaskVisible] = useState({
        show: false,
        mode: CouponGuideMode.newNotFree,
        amount: "300"
    });

    //显示add home弹框
    // const [linkUrl, setLinkUrl] = useState(null);

    // 获取路由历史 history 对象
    const history = useHistory()

    // 获取路由信息 location 对象
    const location = useLocation()

    //获取 Redux 分发器
    const dispatch = useDispatch()

    //获取Redux Store中的额度信息
    const loanLimitInfo = useSelector(state => state.loanLimit)

    //获取Redux Store中的用户状态信息
    const userStateInfo = useSelector(state => state.userState)

    //获取Redux Store中的用户状态信息
    // const userNotExistInfo = useSelector(state => state.userNotExist)

    //启动时请求首页额度
    useEffect(() => {
        //获取首页额度
        try {
            //添加 fb 像素
            // eslint-disable-next-line no-undef
            fbq('track', 'Page_Home');
            // eslint-disable-next-line no-undef
            gtag('track', 'Page_Home');

            // 优惠券入口
            CouponClick.fromParentPage = PageTag.HOME

            // 查询用户状态
            checkUserStateInfoForLaunch()

            //打点
            doHitEvent("HOME_START")

            return () => {
                //打点
                doHitEvent("HOME_END")

                // 清空用户数据
                dispatch(cleanUserState())
            }

        } catch (e) {
            console.log(e)
        }
    }, [])

    // 查询用户流程状态返回监听
    useEffect(() => {
        try {

            console.log("Home-查询用户流程状态返回监听")
            console.log(userStateInfo)

            if (userStateInfo.status === HOME_USER_STATE_REQ_SUCCESS) {
                //隐藏loading
                setMaskVisible(false)

                if (userStateInfo.isProofFinish === true) {
                    //跳转申请页
                    history.push("/apply")

                } else if (userStateInfo.isProofFinish === false) {
                    //是否为号码认证
                    if (isProofMobile(userStateInfo.stepTitle)) {
                        //跳转申请页
                        history.push('/proofmobile')

                    } else if (isProofFrequentlyUsedApps(userStateInfo.stepTitle)) {
                        //跳转申请页
                        history.push('/prooffrequentlyapps')

                    } else if (isProofFace(userStateInfo.stepTitle)) {
                        //跳转申请页
                        history.push('/proofface')

                    } else {
                        //跳转认证页
                        history.push("/proof")
                    }
                }

            } else if (userStateInfo.status === HOME_USER_STATE_REQ_FAIL) {
                //隐藏loading
                setMaskVisible(false)

            } else if (userStateInfo.status === LAUNCH_USER_STATE_REQ_SUCCESS) {
                //隐藏loading
                setMaskVisible(false)

                //获取首页数据
                checkHomeLimit()

            } else if (userStateInfo.status === LAUNCH_USER_STATE_REQ_FAIL) {
                //隐藏loading
                setMaskVisible(false)

            }
        } catch (e) {
            console.log(e)
        }
    }, [userStateInfo])

    // 查询首页额度返回监听
    useEffect(() => {
        try {
            console.log("Home-查询首页额度返回监听")
            console.log(loanLimitInfo)

            if (loanLimitInfo.status === LOAN_LIMIT_REQ_SUCCESS) {
                //隐藏loading
                setMaskVisible(false)

                //查询用户定位
                checkUserLocation()


                // 判断优惠券显示逻辑
                console.log("判断优惠券显示逻辑")
                console.log(userStateInfo)
                if (userStateInfo.couponFlag === "YES") {
                    // 区分老客
                    if (userStateInfo.userType === "OLD") {
                        const canShowDialog = getNotRemindCoupon() !== "YES"

                        setCouponGuideMaskVisible({
                            show: canShowDialog,
                            mode: CouponGuideMode.oldReLoan,
                            amount: userStateInfo.amount
                        })
                        if (canShowDialog) {
                            // 打点
                            doHitEvent("coupon_dialog_show", CouponGuideMode.oldReLoan)
                        }

                    } else {
                        // 如果所有认证步骤已完，弹模式 C
                        if (userStateInfo.isProofFinish === true) {
                            setCouponGuideMaskVisible({
                                show: true,
                                mode: CouponGuideMode.newNotLoan,
                                amount: userStateInfo.amount
                            })
                            // 打点---需要改正！！！
                            doHitEvent("coupon_dialog_show", CouponGuideMode.newNotLoan)

                        } else {
                            if (userStateInfo.couponType && userStateInfo.couponType === "FREE") {
                                setCouponGuideMaskVisible({
                                    show: true,
                                    mode: CouponGuideMode.newHadFree,
                                    amount: userStateInfo.amount
                                })
                                // 打点
                                doHitEvent("coupon_dialog_show", CouponGuideMode.newHadFree)

                            } else if (userStateInfo.couponType && userStateInfo.couponType === "REPAY") {
                                setCouponGuideMaskVisible({
                                    show: true,
                                    mode: CouponGuideMode.newNotFree,
                                    amount: userStateInfo.amount
                                })
                                // 打点
                                doHitEvent("coupon_dialog_show", CouponGuideMode.newNotFree)
                            }
                        }
                    }
                }

            } else if (loanLimitInfo.status === LOAN_LIMIT_REQ_FAIL) {
                //隐藏loading
                setMaskVisible(false)

                //查询用户定位
                checkUserLocation()
            }

        } catch (e) {
            console.log(e)
        }
    }, [loanLimitInfo])

    //监听用户是否存在
    // useEffect(() => {
    //     if (userNotExistInfo.notExist === true) {

    //         //history.replace('/login')
    //          history.replace('/loginfb')
    //     }
    // }, [userNotExistInfo])


    //查询用户定位
    const checkUserLocation = async () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    console.log("=============查询定位成功返回：")
                    console.log(position)
                    const latitude = position.coords.latitude + ""
                    const longitude = position.coords.longitude + ""
                    doSaveLocation(longitude, latitude)
                },
                (error) => {
                    console.log("====== 查询定位错误返回：" + error.message)
                    if (error.code === 1) {
                        // 用户拒绝了位置权限
                        console.error("====== 查询定位错误返回: User denied location permission.");
                        doHitEvent("LOCATION_PERMISSION_DENIED")
                    } else if (error.code === 2) {
                        // 浏览器无法获取位置信息
                        console.error("====== 查询定位错误返回: Browser cannot determine the location.");
                        doHitEvent("LOCATION_CANT_DETERMINE")
                    } else {
                        // 其他错误
                        console.error(`====== 查询定位错误返回: ${error.message}`);
                        doHitEvent("LOCATION_ERROR", error.message)
                    }
                }
            );
        } else {
            console.log("=======Geolocation is not available in your browser.")
            doHitEvent("LOCATION_NOT_AVAILABLE")
        }
    }

    //查询用户流程状态
    const checkUserStateInfo = async () => {
        console.log("Home-checkUserStateInfo: ");

        //获取用户状态
        try {
            //显示loading
            setMaskVisible(true)

            //获取Action
            const action = getUserState(PageTag.HOME)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //查询用户流程状态
    const checkUserStateInfoForLaunch = async () => {
        console.log("Home-checkUserStateInfoForLaunch: ");

        //获取用户状态
        try {
            //显示loading
            setMaskVisible(true)

            //获取Action
            const action = getUserState(PageTag.LAUNCH)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    // 查询首页额度
    const checkHomeLimit = async () => {
        //显示loading
        setMaskVisible(true)

        //获取Action
        const action = getLoanLimit()

        //触发接口
        dispatch(action)
    }

    //保存位置接口
    const doSaveLocation = async (longitude = "", latitude = "") => {
        console.log("doSaveLocation:  latitude " + longitude + " ,latitude " + latitude);

        try {
            //获取Action
            const action = saveLocation(longitude, latitude)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //打点接口
    const doHitEvent = async (type, remark = "") => {
        console.log("doHitEvent: " + type + " , " + remark);

        try {
            //获取Action
            const action = saveEvent(type, remark)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //点击申请
    const onApplyClick = () => {
        //打点
        doHitEvent("HOME_COMMIT_CLICK")

        //查询用户状态
        checkUserStateInfo()
    }

    //去朋友首页
    const onHandleToFriendsLink = () => {
        if (FacebookData.facebookUserInfo) {
            const link = FacebookData.facebookUserInfo.link
            console.log("==== open user link: " + link)
            if (link) {
                window.location.href = link
            } else {
                window.location.href = "https://www.facebook.com/id100066806351639/"
            }

            // setLinkUrl(link)

            // const newWindow = window.open(link, "_parent")
            // setTimeout(() => {

            //     if (newWindow) {
            //         console.log("=======重定向后的地址: " + window.location.href)
            //         // newWindow.close()

            //         // setTimeout(()=>{
            //         //     console.log("=======重定向后的地址: 当前 " + window.location.href)
            //         // },1000)
            //     }
            // }, 5000)
        }
    }

    // 点击add home监听
    const onHandleAddToHome = (type) => {
        console.log(`点击add home监听: ${type}`)
        if (type === 'home') {
            AddHomeEvent.onHandleAddHomeEventClick()
        } else {
            AddHomeEvent.onHandleAddFavoriteEventClick()
        }
    }

    // 关闭add home
    const onHandleAddToHomeClose = () => {
        console.log(`点击close add home监听`)
        setAddHomeMaskVisible(false)
    }

    // 刷新操作
    const onHandleRefresh = () => {
        // 查询用户流程状态
        checkUserStateInfoForLaunch()
    }

    // 优惠券弹框-点击确认
    const onHandleCouponGuideDialogConfirm = () => {
        CouponClick.fromParentPage = PageTag.HOME

        console.log(`优惠券弹框-点击确认`)
        setCouponGuideMaskVisible({
            show: false,
            mode: CouponGuideMode.newNotFree,
            amount: "300"
        })

        if (userStateInfo.userType === "OLD") {
            // 正常申请
            onApplyClick()

        } else {
            // 如果所有认证步骤已完
            if (userStateInfo.isProofFinish === true) {
                // 正常申请
                onApplyClick()
            } else {
                CouponClick.showCouponFromSource = PageTag.HOME+"_COUPON_DIALOG"

                // 打点
                doHitEvent("COUPON_DIALOG_TO_COUPON", PageTag.HOME || "")

                // 去权益列表页
                history.push('/coupon')
            }
        }
    }

    // 优惠券弹框-点击关闭
    const onHandleCouponGuideDialogClose = () => {
        console.log(`优惠券弹框-点击关闭`)
        setCouponGuideMaskVisible({
            show: false,
            mode: CouponGuideMode.newNotFree,
            amount: "300"
        })
    }

    //显示iframe
    const onHandleUserLink = () => {
        var linkFrame = document.getElementById("linkFrame")
        var url = linkFrame.contentWindow.location.href
        console.log("====== iframe 重定身后的url: " + url)

        setInterval(() => {
            var url = linkFrame.contentWindow.location.href
            console.log("====== iframe 重定身后的url: " + url)
        }, 1000)
    }

    return (
        <div className="homeRoot">
            {/* 顶部背景 */}
            <div className="homeTopBackground"></div>
            <NavigateBar theme="showCoupon" onAddFavorite={() => { setAddHomeMaskIsHomeMode(false); setAddHomeMaskVisible(true) }} onAddHome={() => { setAddHomeMaskIsHomeMode(true); setAddHomeMaskVisible(true) }} onRefresh={onHandleRefresh}>Loan Salad</NavigateBar>

            {/* 使用朋友轮播 */}
            <div className="rowAlignStartFullWidth proofHorPadding16Px" onClick={onHandleToFriendsLink}>
                <div className="proofFaceNotifyContent">
                    <img className="proofFaceImageNotify proofRightMargin6Px" src={icon_proof_face_notify} alt="" />
                    <span className="proofFaceUseFriendsTitle proofRightMargin6Px">Using friends:</span>
                    <div className="proofFaceUseFriendsScrollNotify">
                        <TextCarouselHorizontal className="rowAlignStartFullWidth" />
                    </div>
                    <img className="proofFaceImageNotify" src={icon_proof_face_more} alt="" />
                </div>
            </div>

            <div className="homeAmountContent">
                <div className="homeAmountTitle">Loan Amount</div>

                {/* 金额 */}
                <div className="contentRowCenter homeAmountAmountBottomAlign">
                    <div className="homeAmountCurrency">{loanLimitInfo.currency}</div>
                    <div className="homeAmountValue">{loanLimitInfo.amount}</div>
                </div>

                {/* 首页点击申请 */}
                <div className="homeApplyBtn" onClick={onApplyClick}>
                    <div className="mainButtonGradient">Apply Now</div>
                </div>

                {/* 优惠券模块 */}
                {userStateInfo.couponFlag === "YES" && <div className="homeCouponTipsContent">
                    <img className="homeCouponTipsImage" src={icon_home_coupon_tips} alt="" />
                    <div className="homeCouponTipsText">A repayment coupon Available</div>
                </div>}
            </div>

            <div className="contentRowCenter contentHorizontalPadding homeRowTopEdge contentVerticalPadding">
                <div className="contentColumnCenter contentWidthAvag ">
                    <img className="homeStepImage" src={icon_home_step1} alt="" />
                    <span className="homeStepTitle">Submit</span>
                    <span className="homeStepDetail">information</span>
                </div>
                <div className="contentColumnCenter contentWidthAvag ">
                    <img className="homeStepImage" src={icon_home_step2} alt="" />
                    <span className="homeStepTitle">Confirm</span>
                    <span className="homeStepDetail">your loan need</span>
                </div>
                <div className="contentColumnCenter contentWidthAvag ">
                    <img className="homeStepImage" src={icon_home_step3} alt="" />
                    <span className="homeStepTitle">Get</span>
                    <span className="homeStepDetail">money</span>
                </div>
            </div>

            <div className="contentColumnCenter homeRowTopEdge contentHorizontalPadding contentVerticalPadding">
                <span className="homeHeaderTitle">Maintain good loan record will continue to increase loan amount.</span>

                <div className="contentRowStart homeInsideTopEdge homeIncreaseContent contentHorizontalPadding">
                    <img className="homeStepImage" src={icon_home_increase1} alt="" />
                    <span className="homeIncreaseTitle">Loan{'\n'}Amount</span>
                    <div className="flexRowStart">
                        <div className="homeIncreaseCurrency">{loanLimitInfo.currency}</div>
                        <div className="homeIncreaseAmount mainTextColor">{loanLimitInfo.increasedAmount1}</div>
                    </div>
                </div>

                <div className="contentRowStart homeInsideTopEdge homeInsideTopEdge homeIncreaseContent contentHorizontalPadding">
                    <img className="homeStepImage" src={icon_home_increase2} alt="" />
                    <span className="homeIncreaseTitle">Loan{'\n'}Amount</span>
                    <div className="flexRowStart">
                        <div className="homeIncreaseCurrency">{loanLimitInfo.currency}</div>
                        <div className="homeIncreaseAmount redTextColor">{loanLimitInfo.increasedAmount2}</div>
                    </div>
                </div>
            </div>

            {/* add home gif dialog */}
            <AddToHomeGifDialog visible={addHomeMaskVisible} onConfirm={onHandleAddToHome} onClose={onHandleAddToHomeClose} isHomeMode={addHomeMaskIsHomeMode} />

            {/* coupon guide dialog */}
            <CouponGuideDialog visible={couponGuideMaskVisible.show} mode={couponGuideMaskVisible.mode} counponAmount={couponGuideMaskVisible.amount} onConfirm={onHandleCouponGuideDialogConfirm} onClose={onHandleCouponGuideDialogClose} />

            <Mask visible={maskVisible} opacity={0.1}
                onMaskClick={() => { }}>
                <div className="overlayContent">
                    <span style={{ fontSize: 24, color: '#ff3141' }} >
                        <DotLoading color="currentColor" />
                    </span>
                </div>
            </Mask>

            {/* {linkUrl &&
                <iframe
                    id="linkFrame"
                    src={linkUrl}
                    width="800"
                    height="600"
                    title="Link"
                    onLoad={onHandleUserLink}
                ></iframe>} */}
        </div>
    )
}