
// export const BaseUrl = "http://localhost:3000/front"

export const BaseUrl = process.env.REACT_APP_API_BASE_URL;
console.log("baseUrl: " + BaseUrl)

//获取配置信息
export const UrlGetAppConfig = "/front/v1/getAppShowInfo"

//获取验证码
export const UrlGetOtp = "/front/v1/smsCode"

//验证码登录
export const UrlLogin = "/front/v1/userLogin"

//fb登录
export const UrlLoginFacebook = "/front/v2/facebookUnionLogin"

//获取首页额度
export const UrlGetLoanLimit = "/front/v1/loanLimit"

//获取用户状态
export const UrlGetUseState = "/front/v1/userProcess"

//获取BankCode列表
export const UrlGetBankCodeList = "/front/v1/fetchBankCodes"

//获取贷款信息-申请页使用
export const UrlGetLoanDetail = "/front/v1/getLoanApplyDetail"

//获取金额试算信息-申请页使用
export const UrlGetLoanApplyDetailByAmount = "/front/v1/getLoanApplyDetailByAmount"

//确认申请贷款-申请页使用
export const UrlConfirmLoanApply = "/front/v1/loanApply"

//获取还款信息-还款页使用
export const UrlGetReapyDetail = "/front/v1/repayInfo"

//获取还款链接-还款页使用
export const UrlGetReapyUrl = "/front/v1/orderRepayH5"

//记录埋点
export const UrlSaveEvent = "/front/v1/userBuriedPoint"

//保存aduid
export const UrlSaveAduid = "/front/v1/saveAduid"

//获取运营商验证码
export const UrlGetDataSmsCode = "/front/v1/fetchDataSmsCode"

//运营商验证码登录
export const UrlDataSmsCodeLogin = "/front/v1/fetchDataUserLogin"

//保存app列表
export const UrlSaveFrequentlyApps = "/front/v1/saveData"

//保存位置信息
export const UrlSaveUserLocation = "/front/v1/userLocation"

//上传图片
export const UrlUploadImage = "/front/v1/imageUpload"

//保存人脸信息
export const UrlSaveFaceInfo = "/front/v1/ocrInfo"

//保存fb信息息
export const UrlSaveFacebookInfo = "/front/v2/facebookUnionSave"

//获取优惠券信息
export const UrlGetCouponInfo = "/front/v1/getUserCouponInfo"

//发送失败短信
export const UrlSendContractSms = "/front/v1/sendContractSms"

/**
 * 转换为未映射接口
 * @param {String} apiUrl
 */
export const toUnRefApi = apiUrl => {
    switch (apiUrl) {
        //基本信息
        case "/easemoney/yrXwdOXjoy3ZWGc4rrWqQ": return "/front/v1/basicInfo"
        //联系人信息
        case "/easemoney/jd3jakd4hmla2A6NXLvg": return "/front/v1/contactList"
        //工作信息
        case "/easemoney/Al10Gs9YV3TR1mvJ8b5tWA": return "/front/v1/workInfo"
        //bvn信息
        case "/easemoney/iPYFCf8jkHQXY5NAmHaQ": return "/front/v1/bvnInfo"
        //银行卡信息
        case "/easemoney/d0cRE1wjWoVPaqutUTyA": return "/front/v1/bindBankAccount"

        default: return apiUrl
    }
}