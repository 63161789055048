

export default class CouponClick {
    /** 点击receive */
    static hadClickReceive = false

    /** 来源父页面 */
    static fromParentPage = ""

    /** 入口类型 */
    static showCouponFromSource = ""
}