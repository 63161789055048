
import http, { baseParams } from "@/utils/request";
import { UrlGetReapyUrl } from "@/utils/apiUrl";
import { isEmptyString } from "@/utils/utils";
import { Toast } from "antd-mobile";

//类型：获取还款链接成功
export const REPAY_URL_REQ_SUCCESS = "REPAY_URL_REQ_SUCCESS"

//类型：获取还款链接失败
export const REPAY_URL_REQ_FAIL = "REPAY_URL_REQ_FAIL"

/**
 * 获取还款链接
 * @returns thunk
 */
export const getRepayUrl = () => {
    return async (dispatch) => {
        //组装参数
        const params = baseParams()

        //发起请求
        const res = await http.post(UrlGetReapyUrl, params)
        const resCode = parseInt(res.code)
        const resObj = res.obj
        console.log("获取还款链接回调-结束：=======")

        //保存
        const success = (resCode === 0)
        dispatch(saveRepayUrlState(resObj, success))

        //如果有错误，提示
        if (!success) {
            if (!isEmptyString(res.msg)) {
                Toast.show(res.msg, 1)
            }
        }
    }
}

/**
 * 将 返回状态 信息保存到 Redux 中
 * @param {*} data 
 * @returns 
 */
export const saveRepayUrlState = (data, success) => {
    return {
        type: success ? REPAY_URL_REQ_SUCCESS : REPAY_URL_REQ_FAIL,
        payload: data
    }
}