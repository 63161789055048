
import { isStatusSuccess } from "@/utils/utils"
import { isString } from "formik"
import { BANK_CODE_REQ_KEY } from "../actions/bankCode"

// 初始状态
const initState = {
}

// 操作 Token 状态信息的 reducer 函数
export const bankCode = (state = initState, action) => {
    const { type, payload } = action

    if (isString(type) && (type.indexOf(BANK_CODE_REQ_KEY) !== -1)) {
        if (isStatusSuccess(type)) {
            return { ...payload, status: type }
        } else {
            return { status: type }
        }
    } else {
        return state
    }
}