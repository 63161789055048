
import LoginStatus from "./loginStatus"
import { isEmptyObject, isEmptyString } from "./utils"

// 用户 信息的本地缓存键名
const KEY_USER_INFO = "KEY_USER_INFO"

// 设备标识 信息的本地缓存键名
const KEY_DEVICE_ID_INFO = "KEY_DEVICE_ID_INFO"

// aduid 信息的本地缓存键名
const KEY_ADUID_INFO = "KEY_ADUID_INFO"

// 已经评价过app 信息的本地缓存键名
const KEY_APP_REVIEW = "KEY_APP_REVIEW"

// chat mobile 信息的本地缓存键名
const KEY_CHAT_MOBILE = "KEY_CHAT_MOBILE"

// not remind coupon 信息的本地缓存键名
const KEY_NOT_REMIND_COUPON = "KEY_NOT_REMIND_COUPON"

// 订单号 信息的本地缓存键名
const KEY_ORDER_ID = "KEY_ORDER_ID"


/**
 * 从本地缓存中获取 用户 信息
 */
export const getUserInfo = () => {
    console.log("读用户缓存：")
    if (!isEmptyObject(LoginStatus.loginInfo)) {
        console.log(LoginStatus.loginInfo)
        return LoginStatus.loginInfo
    }
    const userInfo = JSON.parse(localStorage.getItem(KEY_USER_INFO) || "{}")
    LoginStatus.loginInfo = userInfo
    console.log(userInfo)
    return userInfo
}

/**
 * 将 用户 信息存入缓存
 * @param {Object} userInfo 从后端获取到的 用户 信息
 */
export const setUserInfo = userInfo => {
    //保存静态数据
    LoginStatus.loginInfo = userInfo
    try {
        //再保存本地
        localStorage.setItem(KEY_USER_INFO, JSON.stringify(userInfo || {}))
    } catch (e) {
    }
}

/**
 * 删除本地缓存中的 用户 信息
 */
export const removeUserInfo = () => {
    LoginStatus.loginInfo = null
    localStorage.removeItem(KEY_USER_INFO)
}

/**
 * 判断本地缓存中是否存在 用户 信息
 */
export const isLogin = () => {
    return !isEmptyString(getUserInfo().token)
}


/**
 * 从本地缓存中获取 设备标识 信息
 * 
 * @returns {String} device id
 */
export const getDeviceIDInfo = () => {
    const deviceIDInfo = localStorage.getItem(KEY_DEVICE_ID_INFO) || ""
    console.log("读设备ID缓存: " + deviceIDInfo)
    return deviceIDInfo
}

/**
 * 将 设备标识 信息存入缓存
 * @param {String} device id 
 */
export const setDeviceIDInfo = deviceID => {
    try {
        //再保存本地
        localStorage.setItem(KEY_DEVICE_ID_INFO, (deviceID || ""))
    } catch (e) {
    }
}

/**
 * 删除本地缓存中的 device id 信息
 */
export const removeDeviceIDInfo = () => {
    localStorage.removeItem(KEY_DEVICE_ID_INFO)
}


/**
 * 将 订单号 信息存入缓存
 * @param {String} order id 
 */
export const setOrderInfo = orderId => {
    try {
        //再保存本地
        localStorage.setItem(KEY_ORDER_ID, (orderId || ""))
    } catch (e) {
    }
}

/**
 * 从本地缓存中获取 订单号 信息
 * 
 * @returns {String} order id
 */
export const getOrderIdInfo = () => {
    const orderId = localStorage.getItem(KEY_ORDER_ID) || ""
    console.log("读Order ID缓存: " + orderId)
    return orderId
}



/**
 * 从本地缓存中获取 aduid 信息
 * 
 * @returns {String} aduid
 */
export const getAduidInfo = () => {
    const aduidInfo = localStorage.getItem(KEY_ADUID_INFO) || ""
    console.log("读aduid缓存: " + aduidInfo)
    return aduidInfo
}

/**
 * 将 aduid 信息存入缓存
 * @param {String} aduid  
 */
export const setAduidInfo = aduid => {
    try {
        localStorage.setItem(KEY_ADUID_INFO, (aduid || ""))
    } catch (e) {
    }
}

/**
 * 删除本地缓存中的 aduid 信息
 */
export const removeAduidInfo = () => {
    localStorage.removeItem(KEY_ADUID_INFO)
}



/**
 * 将 chat mobile 信息存入缓存
 */
export const setChatMobile = (mobile) => {
    try {
        localStorage.setItem(KEY_CHAT_MOBILE, mobile || "")
    } catch (e) {
    }
}

/** 
 * 从本地缓存中获取 chat mobile 信息
 * 
 * @returns {String} mobile
 */
export const getChatMobile = () => {
    const mobile = localStorage.getItem(KEY_CHAT_MOBILE) || ""
    console.log("读 chat mobile 缓存: " + mobile)
    return mobile
}

/**
 * 将 app review 信息存入缓存
 */
export const setAppReviewFlag = () => {
    try {
        localStorage.setItem(KEY_APP_REVIEW, "YES")
    } catch (e) {
    }
}

/**
 * 从本地缓存中获取 app review 信息
 * 
 * @returns {String} appReviewFlag
 */
export const getAppReviewFlag = () => {
    const appReviewFlag = localStorage.getItem(KEY_APP_REVIEW) || ""
    console.log("读 app review flag 缓存: " + appReviewFlag)
    return appReviewFlag
}

/**
 * 将 not remind coupon 信息存入缓存
 */
export const setNotRemindCoupon = (flag) => {
    try {
        localStorage.setItem(KEY_NOT_REMIND_COUPON, flag || "")
    } catch (e) {
    }
}

/** 
 * 从本地缓存中获取 not remind coupon 信息
 * 
 * @returns {String} flag
 */
export const getNotRemindCoupon = () => {
    const flag = localStorage.getItem(KEY_NOT_REMIND_COUPON) || ""
    console.log("读 not remind coupon 缓存: " + flag)
    return flag
}
