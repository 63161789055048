import React from "react";
import './input.css'

export default function Input({ isAdMode = false, isFreeMode = false, title, hintImg, extra, onExtraClick, ...rest }) {
    if (isAdMode) {
        return (
            <div className="inputContent">
                {title && <span className="inputTitle inputTitleColorWhite">{title}</span>}
                <div className="ipnutValueContent">
                    <input className="inputValueAdMode" {...rest} />
                    {extra && <div className="inputExtra" onClick={onExtraClick}>{extra}</div>}
                </div>
                {/* <div className="inputSplitLine"></div> */}
            </div>
        )
    } else if (isFreeMode) {
        return (
            <div className="inputContent">
                {title && <span className="inputTitle inputTitleColorBlack">{title}</span>}
                <div className="ipnutValueContentFreeMode">
                    {hintImg && <img className="inputHintImgFreeMode" src={hintImg} alt="" />}
                    <input className="inputValueFreeMode" {...rest} />
                    {extra && <div className="inputExtra" onClick={onExtraClick}>{extra}</div>}
                </div>
                {/* <div className="inputSplitLine"></div> */}
            </div>
        )
    } else {
        return (
            <div className="inputContent">
                {title && <span className="inputTitle">{title}</span>}
                <div className="ipnutValueContent">
                    <input className="inputValue" {...rest} />
                    {extra && <div className="inputExtra" onClick={onExtraClick}>{extra}</div>}
                </div>
                {/* <div className="inputSplitLine"></div> */}
            </div>
        )
    }
}