import React, { useEffect, useState } from "react";
import '../proof.css';
import '../../Login/login.css';
import '../../../App.css'
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { Mask, DotLoading, Toast } from "antd-mobile";
import { useDispatch, useSelector } from "react-redux";
import { PROOF_FACE_USER_STATE_REQ_FAIL, PROOF_FACE_USER_STATE_REQ_SUCCESS, getUserState, isProofFace, isProofFrequentlyUsedApps, isProofMobile } from "@/store/actions/userState";
import { PageTag } from "@/utils/pageTag";
import ProofNavigateBar from "@/components/ProofNavigateBar";
import { saveEvent } from "@/store/actions/saveEvent";
import icon_proof_face_tips from '@/assets/icon_proof_face_tips.png'
import icon_retake from '@/assets/icon_retake.png'
import icon_proof_face_bg from '@/assets/icon_proof_face_bg.png'
import { isLoanLoaning, isLoanReject, isLoanRepayNow, isLoanReviewing, isLoanSigning } from "@/utils/loanStatus";
import { uploadImage } from "@/store/actions/uploadImage";
import { saveFace } from "@/store/actions/saveFace";
import Compressor from 'compressorjs';
import AddFavorite from "@/components/AddFavorite";
import AddToHomeGifDialog from "@/components/AddToHomeGifDialog/AddToHomeGifDialog";
import AddHomeEvent from "@/utils/addHomeEvent";
import PrivacyPolicyLink from "@/components/PrivacyPolicyLink/PrivacyPolicyLink";
import CouponClick from "@/pages/Coupon/CouponClick";


/**
 * 人脸自拍页
 */
export default function ProofFace() {

    //loading状态
    const [maskVisible, setMaskVisible] = useState(false)

    //显示add home弹框
    const [addHomeMaskVisible, setAddHomeMaskVisible] = useState(false);
    const [addHomeMaskIsHomeMode, setAddHomeMaskIsHomeMode] = useState(true);

    //选择图片
    const [pictureSelected, setPictureSelected] = useState(null)

    //图片文件
    const [pictureFile, setPictureFile] = useState(null)


    // 获取路由历史 history 对象
    const history = useHistory()

    // 获取路由信息 location 对象
    const location = useLocation()

    //获取 Redux 分发器
    const dispatch = useDispatch()

    //获取Redux Store中的用户状态信息
    const userStateInfo = useSelector(state => state.userState)

    //获取Redux Store中的用户状态信息
    // const userNotExistInfo = useSelector(state => state.userNotExist)

    //查询用户流程状态
    const checkUserStateInfo = async () => {
        console.log("ProofFace-checkUserStateInfo: ");

        //获取用户状态
        try {
            //显示loading
            setMaskVisible(true)

            //获取Action
            const action = getUserState(PageTag.PROOF_FACE)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //请求保存接口
    const doUploadImage = async () => {
        console.log("ProofFace-doUploadImage: ");
        console.log(pictureFile)
        try {
            if (pictureFile) {
                //显示loading
                setMaskVisible(true)

                //保存打点
                doHitEvent("PROOF_FACE_COMMIT_CLICK")

                //获取Action
                const action = uploadImage(pictureFile, (success, url) => {
                    //显示loading
                    setMaskVisible(false)

                    if (success && url.length > 0) {
                        //保存人脸信息
                        doSaveFace(url)
                    } else {
                        //清空
                        setPictureSelected(null)
                    }
                })

                //触发接口
                dispatch(action)
            }
        } catch (e) {
            console.log(e)
        }
    }

    //请求保存接口
    const doSaveFace = async (url) => {
        console.log("ProofFace-doSaveFace: ");
        console.log(url)
        try {
            if (url.length > 0) {
                //显示loading
                setMaskVisible(true)

                //获取Action
                const action = saveFace(url, (success) => {
                    //显示loading
                    setMaskVisible(false)

                    if (success) {
                        //获取新的用户状态
                        dispatch(getUserState(PageTag.PROOF_FACE))
                    }
                })

                //触发接口
                dispatch(action)
            }
        } catch (e) {
            console.log(e)
        }
    }

    //点击提交
    const onHandleSubmit = async () => {
        try {
            //上传图片
            if (pictureFile) {
                doUploadImage()
            } else {
                //线上版本
                //Toast.show("Please take picture first")

                //准备更新版本：直接打开拍照
                onHandleImagePick()
            }

        } catch (e) {
            console.log(e)
        }
    }

    //点击返回
    const onHandleNavGoBack = () => {
        console.log("go back")
        history.goBack()
    }

    //点击拍照
    const onHandleImagePick = () => {
        // const customCameraInput = document.getElementById('loanSaladProofFaceImageInput');
        // customCameraInput.click()

        const customCameraInput = document.getElementById('loanSaladProofFaceImageInput');
        setTimeout(() => {
            customCameraInput.click()
        }, 200);
    }

    //拍照返回
    const onHandleImageUpload = (event) => {
        console.log("onHandleImageUpload")
        //更新图片文件
        const file = event.target.files[0];
        console.log(file)

        if (file) {
            console.log("file size " + file.size)
            let quality = 0.4
            if (file.size < 1000 * 1000) { //小于1M
                quality = 0.6
            } else if (file.size < 2000 * 1000) { //小于5M
                quality = 0.4
            } else if (file.size < 10000 * 1000) { //小于10M
                quality = 0.2
            } else { //大于10M
                quality = 0.1
            }

            console.log("quality: " + quality)

            new Compressor(file, {
                quality: quality,
                success(result) {
                    console.log("result file size " + result.size)
                    setPictureFile(result)
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        setPictureSelected(reader.result)
                    };
                    reader.readAsDataURL(result);
                }
            })
        } else {
            setPictureSelected(null)
        }
    }

    //打点接口
    const doHitEvent = async (type, remark = "") => {
        console.log("doHitEvent: " + type + " , " + remark);
        try {
            //获取Action
            const action = saveEvent(type, remark)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    // 点击add home监听
    const onHandleAddToHome = (type) => {
        console.log(`点击add home监听: ${type}`)
        if (type === 'home') {
            AddHomeEvent.onHandleAddHomeEventClick()
        } else {
            AddHomeEvent.onHandleAddFavoriteEventClick()
        }
    }

    // 关闭add home
    const onHandleAddToHomeClose = () => {
        console.log(`点击close add home监听`)
        setAddHomeMaskVisible(false)
    }

    // 刷新操作
    const onHandleRefresh = () => {
        //获取用户状态
        checkUserStateInfo()
    }

    //启动时请求首页额度
    useEffect(() => {
        // 优惠券入口
        CouponClick.fromParentPage = PageTag.PROOF_FACE

        //获取用户状态
        checkUserStateInfo()

        //打点
        doHitEvent("PROOF_FACE_START")

        return () => {
            //打点
            doHitEvent("PROOF_FACE_END")
        }
    }, [])

    // 查询用户流程状态返回监听
    useEffect(() => {
        try {
            console.log("ProofFace-查询用户流程状态返回监听")
            console.log(userStateInfo)

            if (userStateInfo.status === PROOF_FACE_USER_STATE_REQ_SUCCESS) {
                //隐藏loading
                setMaskVisible(false)
                console.log("Launch-signOrderId: " + userStateInfo.signOrderId)
    
                //是否为签约中
                const isHomeSigning = isLoanSigning(userStateInfo.signFlag, userStateInfo.signOrderId)
                //是否为审核中
                const isHomeReviewing = isLoanReviewing(userStateInfo.applyStatus)
                //是否为拒绝
                const isHomeReject = isLoanReject(userStateInfo.applyStatus, userStateInfo.orderStatus)
                //是否为放款中
                const isHomeLoaning = isLoanLoaning(userStateInfo.orderStatus)
                //是否为待还款
                const isHomeRepay = isLoanRepayNow(userStateInfo.orderStatus)
                //是否为待进件
                const isHomeNotLoan = ((!isHomeReviewing) && (!isHomeReject) && (!isHomeLoaning) && (!isHomeRepay))
                console.log("Launch-isHomeReviewing: " + isHomeReviewing)
                console.log("Launch-isHomeReject: " + isHomeReject)
                console.log("Launch-isHomeLoaning: " + isHomeLoaning)
                console.log("Launch-isHomeRepay: " + isHomeRepay)
                console.log("Launch-isHomeNotLoan: " + isHomeNotLoan)
                console.log("Launch-isHomeSigning: " + isHomeSigning)

                //跳转状态页
                if (isHomeSigning) {
                    history.replace('/guide')
                    return

                } else if (isHomeReviewing) {
                    history.replace('/review')
                    return

                } else if (isHomeReject) {
                    history.replace('/reject')
                    return

                } else if (isHomeLoaning) {
                    history.replace('/loaning')
                    return

                } else if (isHomeRepay) {
                    history.replace('/repay')
                    return
                }

                //判断下是否已经做完所有认证
                if (userStateInfo.isProofFinish === true) {
                    //跳转申请页
                    history.replace('/apply')
                    return
                }

                //是否为号码认证
                if (isProofMobile(userStateInfo.stepTitle)) {
                    //跳转申请页
                    history.replace('/proofmobile')
                    return
                }

                //是否为常用app认证
                if (isProofFrequentlyUsedApps(userStateInfo.stepTitle)) {
                    //跳转申请页
                    history.replace('/prooffrequentlyapps')
                    return
                }

                //是否为人脸认证
                if (!isProofFace(userStateInfo.stepTitle)) {
                    //跳转申请页
                    history.replace('/proof')
                    return
                }

            } else if (userStateInfo.status === PROOF_FACE_USER_STATE_REQ_FAIL) {
                //隐藏loading
                setMaskVisible(false)
            }

        } catch (e) {
            console.log(e)
        }
    }, [userStateInfo])


    //监听用户是否存在
    // useEffect(() => {
    //     if (userNotExistInfo.notExist === true) {
    //         //history.replace('/login')
    //         history.replace('/loginfb')
    //     }
    // }, [userNotExistInfo])

    return (
        <div className="proofRoot">
            {/* <NavBar onBack={() => { history.goBack() }} style={{ 'width': '100%', 'backgroundColor': 'white' }}>Proof</NavBar> */}
            <ProofNavigateBar theme="white" onBackClick={onHandleNavGoBack} onAddFavorite={() => { setAddHomeMaskIsHomeMode(false); setAddHomeMaskVisible(true) }} onAddHome={() => { setAddHomeMaskIsHomeMode(true); setAddHomeMaskVisible(true) }} onRefresh={onHandleRefresh}>{userStateInfo.pageTitle}</ProofNavigateBar>

            {/* 使用添加 home screen bar */}
            {/* <AddFavorite onAddHome={() => { setAddHomeMaskIsHomeMode(true); setAddHomeMaskVisible(true) }} /> */}

            <div className="proofContent proofTopMargin0Px">

                <div className="proofFace">

                    {/* 使用朋友轮播 */}
                    {/* <div className="rowAlignStartFullWidth proofHorPadding16Px">
                        <div className="proofFaceNotifyContent">
                            <img className="proofFaceImageNotify proofRightMargin6Px" src={icon_proof_face_notify} alt="" />
                            <span className="proofFaceUseFriendsTitle proofRightMargin6Px">Using friends:</span>
                            <div className="proofFaceUseFriendsScrollNotify">
                                <TextCarouselHorizontal className="rowAlignStartFullWidth" />
                            </div>
                            <img className="proofFaceImageNotify" src={icon_proof_face_more} alt="" />
                        </div>
                    </div> */}

                    <input className="proofFaceImageInput"
                        id="loanSaladProofFaceImageInput"
                        type="file"
                        accept="image/*"
                        capture="user"
                        onChange={onHandleImageUpload} />

                    <span className="proofFaceHeaderTitle proofBottomMargin10Px">Take a selfie, make sure your face is clearly and well it.</span>

                    {(!pictureSelected) && <div className="proofFaceImageContent" onClick={onHandleImagePick}>
                        <img className="proofFaceImagePlaceholder" src={icon_proof_face_bg} alt="" />
                    </div>}

                    {pictureSelected && <div className="proofFaceImageContent">

                        <img className="proofFaceImageResult" src={pictureSelected} alt="" />

                        <div className="proofFaceImageDeleteContent" onClick={onHandleImagePick}>
                            <img className="proofFaceImageDelete" src={icon_retake} alt="" />
                        </div>
                    </div>}

                    <div className="rowAlignStartFullWidth proofBottomMargin30Px proofHorPadding16Px proofTopMargin40Px">
                        <div className="proofFaceTipsContent">
                            <div className="proofFaceTipsRowIcon">
                                <img className="proofFaceIconTips proofRightMargin6Px" src={icon_proof_face_tips} alt="" />
                                <span className="proofFaceTitle">Tips:</span>
                            </div>
                            <div className="proofFaceLine proofTopMargin16Px proofBottomMargin10Px" ></div>
                            <span className="proofFaceTips proofTopMargin10Px">1.No shadow on the face</span>
                            <span className="proofFaceTips proofTopMargin6Px">2.Don't be in a backlit environment</span>
                            <span className="proofFaceTips proofTopMargin6Px">3.Don't wear a mask</span>
                            <span className="proofFaceTips proofTopMargin6Px">4.Please show your forehead</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* 点击确定 */}
            <div className="proofApplyBtn">
                <div className="mainButtonGradient" onClick={onHandleSubmit}>{pictureSelected ? 'Confirm and submit' : 'Start selfie'}</div>

                {!pictureSelected && <input className="proofFaceImageInputSubmit"
                    id="loanSaladProofFaceImageInputSubmit"
                    type="file"
                    accept="image/*"
                    capture="user"
                    onChange={onHandleImageUpload} />
                }
            </div>
            {/* </div> */}

            {/* privacy policy */}
            <PrivacyPolicyLink pageTag={userStateInfo.pageTitle} showCheckbox={false} linkText="Privacy policy" />

            {/* add home gif dialog */}
            <AddToHomeGifDialog visible={addHomeMaskVisible} onConfirm={onHandleAddToHome} onClose={onHandleAddToHomeClose} isHomeMode={addHomeMaskIsHomeMode} />


            <Mask visible={maskVisible} opacity={0.1}
                onMaskClick={() => { }}>
                <div className="overlayContent">
                    <span style={{ fontSize: 24, color: '#ff3141' }} >
                        <DotLoading color="currentColor" />
                    </span>
                </div>
            </Mask>
        </div>
    )
}