/** 页面标识 */
export const PageTag = {

    /**
    * LAUNCH
    */
    LAUNCH: "LAUNCH",
    
    /**
    * LAUNCH_REFERRER
    */
    LAUNCH_REFERRER: "LAUNCH_REFERRER",

    /**
    * LOGIN
    */
    LOGIN: "LOGIN",

    /**
     * HOME
     */
    HOME: "HOME",

    /**
     * PROOF
     */
    PROOF: "PROOF",

    /**
     * PROOF_MOBILE
     */
    PROOF_MOBILE: "PROOF_MOBILE",

    /**
    * PROOF_FREQUENTLY_APPS
    */
    PROOF_FREQUENTLY_APPS: "PROOF_FREQUENTLY_APPS",

    /**
    * PROOF_FACE
    */
    PROOF_FACE: "PROOF_FACE",

    /**
     * APPLY
     */
    APPLY: "APPLY",

    /**
     * REVIEW
     */
    REVIEW: "REVIEW",

    /**
     * REJECT
     */
    REJECT: "REJECT",

    /**
     * LOANING
     */
    LOANING: "LOANING",

    /**
     * REPAY
     */
    REPAY: "REPAY",

    /**
     * COUPON
     */
    COUPON: "COUPON",

    /**
    * GUIDE
    */
    GUIDE: "GUIDE"
}

