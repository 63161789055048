
import { APP_CONFIG_REQ_SUCCESS, APP_CONFIG_REQ_FAIL } from "../actions/appConfig"

// 初始状态
const initState = {
    status: ""
}

// 操作 app配置 状态信息的 reducer 函数
export const appConfig = (state = initState, action) => {
    const { type, payload } = action
    //console.log('loan type: ' + type)

    switch (type) {
        case APP_CONFIG_REQ_SUCCESS: return { ...payload, status: type }
        case APP_CONFIG_REQ_FAIL: return { status: type }
        default: return state
    }
}