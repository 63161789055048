
import { isStatusSuccess, isString } from "@/utils/utils"

// 初始状态
const initState = {
}

// 操作 用户 状态信息的 reducer 函数
export const userState = (state = initState, action) => {
    const { type, payload } = action
    console.log('userState type: ' + type)
    console.log('userState type: ' + type)

    const matchFlag = "USER_STATE_REQ"

    if (isString(type) && (type.indexOf(matchFlag) !== -1)) {
        if (isStatusSuccess(type)) {
            return { ...payload, status: type }
        } else {
            return { status: type }
        }
    } else {
        return state
    }
}