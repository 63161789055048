
import http, { baseParams } from "@/utils/request";
import { UrlGetLoanLimit } from "@/utils/apiUrl";
import { isEmptyString } from "@/utils/utils";
import { Toast } from "antd-mobile";

//类型：请求成功
export const LOAN_LIMIT_REQ_SUCCESS = "LOAN_LIMIT_REQ_SUCCESS"

//类型：请求失败
export const LOAN_LIMIT_REQ_FAIL = "LOAN_LIMIT_REQ_FAIL"

//类型：请求失败
export const LOAN_LIMIT_REQ_CLEAR = "LOAN_LIMIT_REQ_CLEAR"


/**
 * 获取首页额度
 * @returns thunk
 */
export const getLoanLimit = () => {
    return async (dispatch) => {
        //组装参数
        const params = baseParams()

        //发起请求
        const res = await http.post(UrlGetLoanLimit, params)
        const resCode = parseInt(res.code)
        const resObj = res.obj
        console.log("获取首页额度回调-结束：=======")

        //保存
        const success = (resCode === 0)
        dispatch(saveLoanLimitState(resObj, success))

        //如果有错误，提示
        if (!success) {
            if (!isEmptyString(res.msg)) {
                Toast.show(res.msg, 1)
            }
        }
    }
}

/**
 * 将 返回状态 信息保存到 Redux 中
 * @param {*} data 
 * @returns 
 */
export const saveLoanLimitState =  (data, success) => {
    const firstLimit = data.limit[0]
    return {
        type: success ? LOAN_LIMIT_REQ_SUCCESS : LOAN_LIMIT_REQ_FAIL,
        payload: firstLimit
    }
}

/**
 * 将 返回状态 信息保存到 Redux 中
 * @param {*} data 
 * @returns 
 */
export const cleanLoanLimitState = () => {
    console.log("清空LoanLimit state: ")
    return {
        type: LOAN_LIMIT_REQ_CLEAR,
        payload: {}
    }
}
