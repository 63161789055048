
import http, { baseParams } from "@/utils/request";
import { getUserState } from "./userState";
import { PageTag } from "@/utils/pageTag";
import { isEmptyString } from "@/utils/utils";
import { Toast } from "antd-mobile";
import { saveEvent } from "./saveEvent";
import { UrlSaveFrequentlyApps } from "@/utils/apiUrl";

//类型：请求成功
export const SAVE_FREQUENTYLY_APPS_REQ_SUCCESS = "SAVE_FREQUENTYLY_APPS_REQ_SUCCESS"

//类型：申请失败
export const SAVE_FREQUENTYLY_APPS_REQ_FAIL = "SAVE_FREQUENTYLY_APPS_REQ_FAIL"

//类型：重置
export const SAVE_FREQUENTYLY_APPS_REQ_RESET = "SAVE_FREQUENTYLY_APPS_REQ_RESET"

/**
 * 保存常用app列表
 * @param {String} mobile
 * @param {String} code
 * @param {String} fetchOrderId
 * @returns thunk
 */
export const saveFrequentlyApps = (appList) => {
    return async (dispatch) => {
        //组装参数
        const baseParam = baseParams()
        baseParam.frequentlyUserApp = appList + ""

        //发起请求
        const res = await http.post(UrlSaveFrequentlyApps, baseParam)
        const resCode = parseInt(res.code)
        console.log("保存常用app列表回调-结束：=======")

        //保存
        const success = (resCode === 0)
        if (success) {
            //获取新的用户状态
            dispatch(getUserState(PageTag.PROOF_FREQUENTLY_APPS))
        }

        //如果有错误，提示
        if (!success) {
            if (!isEmptyString(res.msg)) {
                Toast.show(res.msg, 1)
            }
            dispatch(saveProofFrequentlyAppsDataFailState())
        }
    }
}

/**
 * 将 返回状态 信息保存到 Redux 中
 * @returns 
 */
export const saveProofFrequentlyAppsDataFailState = () => {
    return {
        type: SAVE_FREQUENTYLY_APPS_REQ_FAIL,
        payload: {}
    }
}

/**
 * 将 返回状态 信息保存到 Redux 中
 * @returns 
 */
export const resetProofFrequentlyAppsDataFailState = () => {
    return {
        type: SAVE_FREQUENTYLY_APPS_REQ_RESET,
        payload: {}
    }
}

