import React from "react";
import './privacypolicylink.css'
import icon_checkbox_yes from '@/assets/icon_checkbox_yes.png'
import icon_checkbox_no from '@/assets/icon_checkbox_no.png'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { saveEvent } from "@/store/actions/saveEvent";
import { useDispatch } from "react-redux";

export default function PrivacyPolicyLink({ pageTag, showCheckbox = false, isChecked = true, preText, linkText = "Privacy policy", onCheckboxChangedCallback }) {

    //导航
    const history = useHistory()

    // 获取 Redux 分发器
    const dispatch = useDispatch()

    //点击 checkbox 回调
    const onHandleCheckboxClick = () => {
        onCheckboxChangedCallback && onCheckboxChangedCallback(!isChecked)
    }

    //点击 link text 回调
    const onHandleLinkTextClick = () => {
        //记录点击事件
        if (pageTag) {
            doHitEvent(pageTag + "_PRIVACY_POLICY_LINK_CLICK")
        } else {
            doHitEvent("PRIVACY_POLICY_LINK_CLICK")
        }

        //跳转协议页
        history.push('/privacy')
    }

    //打点接口
    const doHitEvent = async (type, remark = "") => {
        console.log("doHitEvent: " + type + " , " + remark);

        try {
            //获取Action
            const action = saveEvent(type, remark)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="privacyPolicyLinkRoot">
            {/* checkbox */}
            {showCheckbox && <img className="privacyPolicyLinkCheckbox" src={isChecked ? icon_checkbox_yes : icon_checkbox_no} alt="" onClick={() => onHandleCheckboxClick()} />}

            {/* pre text */}
            {preText && <div className="privacyPolicyLinkPreText">{preText}</div>}

            {/* link text */}
            {linkText && <div className="privacyPolicyLinkText" onClick={() => onHandleLinkTextClick()}>{linkText}</div>}
        </div>
    )
}