import { REPAY_BILL_REQ_FAIL, REPAY_BILL_REQ_SUCCESS } from "../actions/repayDetail"



// 初始状态
const initState = {
    status: ""
}

// 操作 贷款详情 状态信息的 reducer 函数
export const repayDetail = (state = initState, action) => {
    const { type, payload } = action
    //console.log('loan type: ' + type)

    switch (type) {
        case REPAY_BILL_REQ_SUCCESS: return { ...payload, status: type }
        case REPAY_BILL_REQ_FAIL: return { status: type }
        default: return state
    }
}