
import http, { baseParams } from "@/utils/request";
import { UrlSaveEvent } from "@/utils/apiUrl";
import { toEventTagString } from "@/utils/utils";

//类型：请求成功
export const SAVE_EVENT_REQ_SUCCESS = "SAVE_EVENT_REQ_SUCCESS"

//类型：请求失败
export const SAVE_EVENT_REQ_FAIL = "SAVE_EVENT_REQ_FAIL"

/**
 * 保存埋点
 * @returns thunk
 */
export const saveEvent = (type, remark = "") => {
    return async (dispatch) => {
        //组装参数
        const params = baseParams()
        params.type = toEventTagString(type)
        params.remark = remark

        //发起请求
        await http.post(UrlSaveEvent, params)
        console.log("保存埋点回调-结束：=======" + type)
    }
}
