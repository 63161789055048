import React, { useEffect, useState } from "react";
import './login.css';
import '../Loan/Apply/apply.css'
import NavigateBar from "@/components/NavigateBar";
import { useDispatch, useSelector } from "react-redux";
import { DotLoading, Mask, Toast } from "antd-mobile";
import { LOGIN_REQ_FAIL, LOGIN_REQ_SUCCESS, cleanLoginState, doFbUnionLogin } from "@/store/actions/login";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import icon_app_logo from "@/assets/icon_app_logo.jpg"
import icon_login_refuse from "@/assets/icon_login_refuse.png"
import icon_login_account_creat from '@/assets/icon_login_account_creat.png'
import icon_login_account from '@/assets/icon_login_account.png'
import icon_facebook from '@/assets/icon_facebook.png'
import icon_login_phone from '@/assets/icon_login_phone.png'
import icon_login_not_in_age_range from '@/assets/icon_login_not_in_age_range.png'
import icon_login_not_in_location from '@/assets/icon_login_not_in_location.png'
import icon_login_service_tips from '@/assets/icon_login_service_tips.png'
import "../../App.css";
import { LOGIN_USER_STATE_REQ_SUCCESS, cleanUserState, getUserState } from "@/store/actions/userState";
import { PageTag } from "@/utils/pageTag";
import { isLoanLoaning, isLoanReject, isLoanRepayNow, isLoanReviewing, isLoanSigning } from "@/utils/loanStatus";
import { removeUserInfo } from "@/utils/storage";
import { saveEvent } from "@/store/actions/saveEvent";
import { isArray } from "@/utils/utils";
import FacebookData from "@/utils/facebookData";
import { cleanOtpState } from "@/store/actions/sendOtp";
import AddToHomeGifDialog from "@/components/AddToHomeGifDialog/AddToHomeGifDialog";
import AddHomeEvent from "@/utils/addHomeEvent";
import SmartChat from "@/utils/smartChat";
// import AddFavorite from "@/components/AddFavorite";

/**
 * 登录页
 */
export default function LoginFB() {

    //loading状态
    const [maskVisible, setMaskVisible] = useState(false)

    //显示add home弹框
    const [addHomeMaskVisible, setAddHomeMaskVisible] = useState(false);
    const [addHomeMaskIsHomeMode, setAddHomeMaskIsHomeMode] = useState(false);

    //需要转登录状态
    const [needToLoginPage, setNeedToLoginPage] = useState(false);

    //需要转注册状态
    const [needToRegisterPage, setNeedToRegisterPage] = useState(false);

    //不在服务地区弹框
    const [notInServiceLocation, setNotInServiceLocation] = useState(false);

    //不在年龄区弹框
    const [notInServiceAgeRange, setNotInServiceAgeRange] = useState(false);

    //fb登录状态
    const [fbLoginMsg, setFbLoginMsg] = useState(null);

    //fb用户状态
    const [fbUserMsg, setFbUserMsg] = useState(null)

    //fb好友状态
    const [fbFriendsMsg, setFbFriendsMsg] = useState(null);

    //fb用户状态
    const [fbLogoutMsg, setFbLogoutMsg] = useState(null)

    //fb用户id
    const [fbUserId, setFbUserId] = useState(null)

    //fb用户名
    const [fbUserName, setFbUserName] = useState(null)

    //fb用户信息
    // const [fbUserInfo, setFbUserInfo] = useState(null)

    //获取Redux Store中的信息
    const loginInfo = useSelector(state => state.login)

    //获取Redux Store中的用户状态信息
    const userStateInfo = useSelector(state => state.userState)

    //router
    const history = useHistory()

    // 获取 Redux 分发器
    const dispatch = useDispatch()

    //清空登录信息
    useEffect(() => {
        console.log("LOGIN-移除登录信息")
        //移除登录信息
        removeUserInfo()

        //清除登录信息
        dispatch(cleanLoginState())
        dispatch(cleanUserState())
        dispatch(cleanOtpState())

        //来源
        const dreferrer = document.referrer || ""
        console.log("document.referrer:==========" + dreferrer)

        //fb登录状态查询
        checkFacebookLoginStatus()

        //打点
        doHitEvent("LOGIN_FB_UNION_START")

        return () => {
            //打点
            doHitEvent("LOGIN_FB_UNION_END")
        }
    }, [])

    //监听登录接口返回
    useEffect(() => {
        //获取短信发送成功返回
        if (loginInfo.status === LOGIN_REQ_SUCCESS) {
            console.log("Login-登录成功")
            console.log(loginInfo)
            //关闭loading
            setMaskVisible(false)

            // 设置salesmartly
            //let mobile = form.values.mobile
            //SmartChat.saveUserInfo(mobile);

            //查询用户状态
            // getUserStateInfo()

            //是否做了基本信息，做了的话，不需要拦截
            const basicAuthState = loginInfo.basicAuthState
            if (basicAuthState && basicAuthState === 'YES') {
                //查询用户状态
                getUserStateInfo()

            } else {
                //判断是否合法的地区下年龄段
                const isNigeriaZone = true//FacebookData.isNigeriaZone()
                const isValidAgeRange = true//FacebookData.isValidAgeRange()

                if (!isNigeriaZone) {
                    setNotInServiceLocation(true)
                }
                if (!isValidAgeRange) {
                    setNotInServiceAgeRange(true)
                }
                if (isNigeriaZone && isValidAgeRange) {
                    //查询用户状态
                    getUserStateInfo()
                }
            }

            //保存fb user info
            FacebookData.saveUserInfo()

            //抓取fb联系人信息
            FacebookData.saveUserFriends()

        } else if (loginInfo.status === LOGIN_REQ_FAIL) {
            //关闭loading
            setMaskVisible(false)

            //判断弹框
            const otpResCode = loginInfo.resCode || -1
            if (otpResCode === 2001) {
                //需要弹提示--你是新客，需要注册
                setNeedToRegisterPage(true)
                setNeedToLoginPage(false)

            } else if (otpResCode === 2002) {
                //需要弹提示--你是旧客，需要登录
                setNeedToRegisterPage(false)
                setNeedToLoginPage(true)
            }
        }
    }, [loginInfo])

    useEffect(() => {
        console.log("LOGIN-待等用户流程信息返回")
        console.log(userStateInfo)
        if (userStateInfo.status === LOGIN_USER_STATE_REQ_SUCCESS) {
            //关闭loading
            setMaskVisible(false)

            console.log("LOGIN-再次查询user state:")
            console.log("LOGIN-applyStatus: " + userStateInfo.applyStatus)
            console.log("LOGIN-orderStatus: " + userStateInfo.orderStatus)
            console.log("LOGIN-signFlag: " + userStateInfo.signFlag)
            console.log("Launch-signOrderId: " + userStateInfo.signOrderId)

            //是否为签约中
            const isHomeSigning = isLoanSigning(userStateInfo.signFlag, userStateInfo.signOrderId)
            //是否为审核中
            const isHomeReviewing = isLoanReviewing(userStateInfo.applyStatus)
            //是否为拒绝
            const isHomeReject = isLoanReject(userStateInfo.applyStatus, userStateInfo.orderStatus)
            //是否为放款中
            const isHomeLoaning = isLoanLoaning(userStateInfo.orderStatus)
            //是否为待还款
            const isHomeRepay = isLoanRepayNow(userStateInfo.orderStatus)
            //是否为待进件
            const isHomeNotLoan = ((!isHomeReviewing) && (!isHomeReject) && (!isHomeLoaning) && (!isHomeRepay))
            console.log("LOGIN-isHomeReviewing: " + isHomeReviewing)
            console.log("LOGIN-isHomeReject: " + isHomeReject)
            console.log("LOGIN-isHomeLoaning: " + isHomeLoaning)
            console.log("LOGIN-isHomeRepay: " + isHomeRepay)
            console.log("LOGIN-isHomeNotLoan: " + isHomeNotLoan)
            console.log("LOGIN-isHomeSigning: " + isHomeSigning)

            //页面转发
            setTimeout(() => {
                if (isHomeSigning) {
                    history.replace('/guide')
                } else if (isHomeReviewing) {
                    history.replace('/review')
                } else if (isHomeReject) {
                    history.replace('/reject')
                } else if (isHomeLoaning) {
                    history.replace('/loaning')
                } else if (isHomeRepay) {
                    history.replace('/repay')
                } else {
                    history.replace('/home')
                }
            }, 500)
        } else if (userStateInfo.status === LOGIN_USER_STATE_REQ_SUCCESS) {
            //关闭loading
            setMaskVisible(false)
        }
    }, [userStateInfo])

    //获取用户流程信息
    const getUserStateInfo = () => {
        console.log("LOGIN-获取用户流程信息=====")
        try {
            //显示loading
            setMaskVisible(true)

            //获取Action
            const action = getUserState(PageTag.LOGIN)

            //触发接口
            dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //打点接口
    const doHitEvent = async (type, remark = "") => {
        console.log("doHitEvent: " + type + " , " + remark);

        try {
            //获取Action
            const action = saveEvent(type, remark)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    // 用fb联合登录
    const doFacebookUnionLogin = (pageType = "LOGINPAGE") => {
        try {
            console.log("====== doFacebookUnionLogin " + pageType)
            if (FacebookData.facebookUserInfo) {
                //打点
                doHitEvent("LOGIN_FB_" + pageType)

                //验证fb id
                let facebookUserId = FacebookData.facebookUserInfo.id || ""

                //显示loading
                setMaskVisible(true)

                //获取Action
                const action = doFbUnionLogin(facebookUserId, pageType)

                //触发接口
                dispatch(action)

            } else {
                console.log("===========set fbUserInfo not success")
            }
        } catch (e) {
            console.log(e)
        }
    }

    // fb登录成功-获取数据
    const doFetchFbFriendsInfo = async (userID) => {

        let resMsg = "Friends Info:\n"

        // 使用访问令牌调用 Facebook Graph API 获取用户信息
        window.FB && window.FB.api("/me/friends", { fields: 'id,name' }, function (userResponse) {
            console.log(userResponse)

            if (userResponse && !userResponse.error) {
                resMsg = resMsg + "Fetch frinds success:\n"
                const list = userResponse.data;
                const isArrayList = isArray(list)
                resMsg = resMsg + "isArrayList: " + isArrayList + "\n"
                if (isArrayList) {
                    resMsg = resMsg + "list size: " + list.length + "\n"
                }
                list.forEach((item) => {
                    const userID = item.id;
                    const userName = item.name;
                    const userPicture = item.picture;
                    const userEmail = item.email;
                    const userLink = item.link;
                    const userLocation = item.location;
                    const userHometown = item.hometown;
                    const userBirthday = item.birthday;
                    const userAgeRange = item.age_range;
                    const userGender = item.gender;

                    resMsg = resMsg + "userID: " + userID + "\n"
                    resMsg = resMsg + "userName: " + userName + "\n"
                    resMsg = resMsg + "userPicture: " + userPicture + "\n"
                    resMsg = resMsg + "userEmail: " + userEmail + "\n"
                    resMsg = resMsg + "userLink: " + userLink + "\n"
                    resMsg = resMsg + "userLocation: " + userLocation + "\n"
                    resMsg = resMsg + "userHometown: " + userHometown + "\n"
                    resMsg = resMsg + "userBirthday: " + userBirthday + "\n"
                    resMsg = resMsg + "userAgeRange: " + userAgeRange + "\n"
                    resMsg = resMsg + "userGender: " + userGender + "\n"
                    resMsg = resMsg + "==============\n"
                });

            } else {
                resMsg = resMsg + "Fetch frinds err:\n"
                resMsg = resMsg + userResponse.error + "\n"

                // 处理错误
                console.log('Fetch frinds err:', userResponse.error);
            }

            //刷新
            setFbFriendsMsg(resMsg)
        });
    }

    // fb登录成功-获取数据
    const doFetchFbUserInfoWhenLoginSuccess = async (pageType = "LOGINPAGE") => {
        // 使用访问令牌调用 Facebook Graph API 获取用户信息
        window.FB && window.FB.api('/me', { fields: 'id,name,picture,email,link,location,hometown,birthday,age_range,gender' }, function (userResponse) {
            console.log(userResponse)

            if (userResponse && !userResponse.error) {
                //保存fb id
                const userID = userResponse.id || "";
                FacebookData.facebookUserId = userID
                FacebookData.facebookUserInfo = { ...userResponse }
                console.log("======= set facebookUserId: " + userID)

                //保存用户数据
                console.log("========= set fbUserInfo")
                console.log(FacebookData.facebookUserInfo)

                //系统登录
                doFacebookUnionLogin(pageType)

                //判断是否合法的地区下年龄段
                // const isNigeriaZone = FacebookData.isNigeriaZone()
                // const isValidAgeRange = FacebookData.isValidAgeRange()

                // if (!isNigeriaZone) {
                //     setNotInServiceLocation(true)
                // }
                // if (!isValidAgeRange) {
                //     setNotInServiceAgeRange(true)
                // }
                // if (isNigeriaZone && isValidAgeRange) {
                //     //系统登录
                //     doFacebookUnionLogin(pageType)
                // }

                //只针对视频账号
                // if (userID === "640344431535763") {
                //     setNotInServiceAgeRange(true)
                //     setNotInServiceLocation(true)
                // } else {
                //     //系统登录
                //     doFacebookUnionLogin(pageType)
                // }


                // resMsg = resMsg + "Fetch user info success:\n"

                // const userID = userResponse.id || "";
                // const userName = userResponse.name || "";
                // const userPicture = userResponse.picture || "";
                // const userEmail = userResponse.email || "";
                // const userLink = userResponse.link || "";
                // const userLocationid = (userResponse.location || {}).id || "";
                // const userLocationname = (userResponse.location || {}).name || "";
                // const userLocationlatitude = (userResponse.location || {}).latitude || "";
                // const userLocationlongitude = (userResponse.location || {}).longitude || "";
                // const userLocationcity = (userResponse.location || {}).city || "";
                // const userLocationcountry = (userResponse.location || {}).country || "";
                // const userLocationstate = (userResponse.location || {}).state || "";
                // const userLocationzip = (userResponse.location || {}).zip || "";

                // const userHometown = userResponse.hometown || "";
                // const userBirthday = userResponse.birthday || "";
                // const userAgeRangeMin = (userResponse.age_range || {}).min || "";
                // const userAgeRangeMax = (userResponse.age_range || {}).max || "";
                // const userGender = userResponse.gender || "";

                // resMsg = resMsg + "userID: " + userID + "\n"
                // resMsg = resMsg + "userName: " + userName + "\n"
                // resMsg = resMsg + "userPicture: " + userPicture + "\n"
                // resMsg = resMsg + "userEmail: " + userEmail + "\n"
                // resMsg = resMsg + "userLink: " + userLink + "\n"
                // resMsg = resMsg + "userLocationid: " + userLocationid + "\n"
                // resMsg = resMsg + "userLocationname: " + userLocationname + "\n"
                // resMsg = resMsg + "userLocationlatitude: " + userLocationlatitude + "\n"
                // resMsg = resMsg + "userLocationlongitude: " + userLocationlongitude + "\n"
                // resMsg = resMsg + "userLocationcountry: " + userLocationcountry + "\n"
                // resMsg = resMsg + "userLocationcity: " + userLocationcity + "\n"
                // resMsg = resMsg + "userLocationstate: " + userLocationstate + "\n"
                // resMsg = resMsg + "userLocationzip: " + userLocationzip + "\n"
                // resMsg = resMsg + "userHometown: " + userHometown + "\n"
                // resMsg = resMsg + "userBirthday: " + userBirthday + "\n"
                // resMsg = resMsg + "userAgeRangeMin: " + userAgeRangeMin + "\n"
                // resMsg = resMsg + "userAgeRangeMax: " + userAgeRangeMax + "\n"
                // resMsg = resMsg + "userGender: " + userGender + "\n"

                // //获取好友列表
                // doFetchFbFriendsInfo(userID)

            } else {
                Toast.show(userResponse.error)
                // resMsg = resMsg + "Fetch user info err:\n"
                // resMsg = resMsg + userResponse.error + "\n"
                // 处理错误
                console.log('Fetch user info err:', userResponse.error);

                //打点
                doHitEvent("FB_SDK_GET_USER_INFO_ERR", userResponse.error || "error")
            }

            //刷新
            // setFbUserMsg(resMsg)
        });
    }

    // 登录-fb登录
    const doFacebookLogin = async (pageType = "LOGINPAGE") => {
        window.FB && window.FB.login(function (response) {
            console.log("============ FB Login: ")

            //打点
            doHitEvent("FB_SDK_LOGIN_STATUS", response.status || "unknown")

            if (response.status === 'connected') {
                // 用户已登录并授权你的应用程序
                //获取用户信息
                doFetchFbUserInfoWhenLoginSuccess(pageType)

            } else if (response.status === 'not_authorized') {
                // 用户已登录但未授权你的应用程序
                Toast.show("Facebook login not authorized")

            } else {
                // 用户未登录 Facebook，或者登录状态未知
                Toast.show("Facebook login fail")
            }

        }, { scope: 'public_profile,email,user_friends,user_link,user_location,user_hometown,user_birthday,user_age_range,user_gender' });
    }

    // 注册-fb登录
    const onHandleRegisterWithFacebookAccount = () => {
        //打点
        doHitEvent("LOGIN_CREATE_WITH_FACEBOOK_CLICK")

        //判断是否已经登录
        window.FB && window.FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                // 用户已登录并授权你的应用，则退出
                window.FB && window.FB.logout(function (response) {
                    //这个时候再去登录
                    doFacebookLogin("REGPAGE")
                });
            } else {
                //这个时候再去登录
                doFacebookLogin("REGPAGE")
            }
        });
    }

    // 登录-退出fb登录
    const onHandleLogoutFacebookAccount = () => {
        let resMsg = "Logout: \n"

        // 注销用户
        window.FB && window.FB.logout(function (response) {
            if (response.status === 'unknown') {
                resMsg = resMsg + "success\n"

                // 用户已成功退出登录
                console.log('用户已退出登录');
            } else {
                resMsg = resMsg + "error\n"

                // 注销时发生错误
                console.log('注销时出错:', response);
            }

            //刷新
            setFbLogoutMsg(resMsg)
        });
    }

    // 登录-判断facebook登录状态
    const checkFacebookLoginStatus = () => {
        window.FB && window.FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                // 用户已登录并授权你的应用
                var accessToken = response.authResponse.accessToken;
                console.log('用户已登录，访问令牌：' + accessToken);

                // 使用访问令牌调用 Facebook Graph API 获取用户信息
                window.FB && window.FB.api('/me', { fields: 'id,name' }, function (userResponse) {
                    console.log(userResponse)

                    if (userResponse && !userResponse.error) {
                        const userID = userResponse.id || null;
                        const userName = userResponse.name || null;

                        //更新button显示
                        setFbUserId(userID)
                        setFbUserName(userName)

                    } else {
                        //更新button显示
                        setFbUserId(null)
                        setFbUserName(null)
                    }
                });

            } else if (response.status === 'not_authorized') {
                // 用户已登录到Facebook，但未授权你的应用
                console.log('用户已登录到Facebook,但未授权你的应用');
                //更新button显示
                setFbUserId(null)
                setFbUserName(null)

            } else {
                // 用户未登录到Facebook
                console.log('用户未登录到Facebook');
                //更新button显示
                setFbUserId(null)
                setFbUserName(null)
            }
        });
    }

    // 登录-fb登录
    const onHandleLoginWithFacebookAccount = () => {
        //打点
        doHitEvent("LOGIN_WITH_FACEBOOK_CLICK")

        //判断是否已经登录
        window.FB && window.FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                // 用户已登录并授权你的应用，则退出
                window.FB && window.FB.logout(function (response) {
                    //这个时候再去登录
                    doFacebookLogin("LOGINPAGE")
                });
            } else {
                //这个时候再去登录
                doFacebookLogin("LOGINPAGE")
            }
        });
    }


    // 登录-fb登录--已登录状态
    const onHandleLoginWithFacebookAccountWhenFacebookDidLogin = () => {
        //打点
        doHitEvent("LOGIN_WITH_FACEBOOK_CLICK")

        //这个时候再去登录
        doFacebookLogin("LOGINPAGE")
    }

    // 切换手机号登录
    const onHandlePhoneMobileLogin = () => {
        //打点
        doHitEvent("LOGIN_WITH_PHONE_NUMBER_CLICK")

        history.push({ pathname: '/login', search: '?isRegister=NO' })
    }

    // 切换手机号注册
    const onHandlePhoneMobileRegister = () => {
        //打点
        doHitEvent("LOGIN_WITH_PHONE_NUMBER_CLICK")

        history.push({ pathname: '/login', search: '?isRegister=YES' })
    }

    // 点击add home监听
    const onHandleAddToHome = (type) => {
        console.log(`点击add home监听: ${type}`)
        if (type === 'home') {
            AddHomeEvent.onHandleAddHomeEventClick()
        } else {
            AddHomeEvent.onHandleAddFavoriteEventClick()
        }
    }

    // 关闭add home
    const onHandleAddToHomeClose = () => {
        console.log(`点击close add home监听`)
        setAddHomeMaskVisible(false)
    }


    return (
        <div className="login">
            <NavigateBar theme="white" showLogout={false} onAddFavorite={() => { setAddHomeMaskIsHomeMode(false); setAddHomeMaskVisible(true) }} onAddHome={() => { setAddHomeMaskIsHomeMode(true); setAddHomeMaskVisible(true) }}></NavigateBar>

            {/* 使用添加 home screen bar */}
            {/* <AddFavorite /> */}

            <div className="pageContentColumn contentHorizontalPadding loginTopEdge10Px">
                {/* logo与名称 */}
                <div className='loginApp'>
                    <div className='loginAppLogoContent'>
                        <img className="loginAppLogo" src={icon_app_logo} alt='' />
                        <span className='loginAppName'>Loan Salad</span>
                    </div>
                    <span className='loginAppUser'>www.loansalad.info</span>
                </div>

                <div className="columnAlignStartFullWidth">
                    <div className="rowAlignStart loginTitle">Safe and fast personal loan platform</div>

                    {/* Create an account for new user */}
                    <div className="columnAlignStartFullWidth loginMenuBackground">
                        <div className="rowAlignStart">
                            <img className="loginMenuLogo" src={icon_login_account_creat} alt='' />
                            <span className='loginMenuTitle'>Create an account for new user</span>
                        </div>

                        <div className="rowAlignCenterFullWidth loginMenuBtnBackground">
                            <div className="rowAlignCenterFullWidth loginMenuBtn" onClick={onHandleRegisterWithFacebookAccount}>
                                <img className="loginMenuLogo" src={icon_facebook} alt='' />
                                <span>Continue with Facebook</span>
                            </div>
                        </div>

                        {/* 测试模式下可以打开 */}
                        {/* <div className="rowAlignCenterFullWidth" onClick={onHandlePhoneMobileRegister}>
                            <span className='loginTitleNotAccount'>I don't have Facebook</span>
                        </div> */}

                        {/* <div className="rowAlignCenterFullWidth" onClick={onHandleLogoutFacebookAccount}>
                            <span className='loginTitleNotAccount'>Logout Facebook</span>
                        </div> */}
                    </div>

                    {/* Login my account */}
                    <div className="columnAlignStartFullWidth loginMenuBackground">
                        <div className="rowAlignStart">
                            <img className="loginMenuLogo" src={icon_login_account} alt='' />
                            <span className='loginMenuTitle'>Login my account</span>
                        </div>

                        <div className="rowAlignCenterFullWidth loginMenuBtnBackground">
                            {!fbUserName && <div className="rowAlignCenterFullWidth loginMenuBtn" onClick={onHandleLoginWithFacebookAccount}>
                                <img className="loginMenuLogo" src={icon_facebook} alt='' />
                                <span>Login with Facebook</span>
                            </div>}
                            {fbUserName && <div className="rowAlignCenterFullWidth loginMenuBtn" onClick={onHandleLoginWithFacebookAccountWhenFacebookDidLogin}>
                                <img className="loginMenuLogo" src={icon_facebook} alt='' />
                                <span>Continue as {(fbUserName.length > 7) ? (fbUserName.substring(0, 5) + "...") : fbUserName}</span>
                            </div>}
                        </div>

                        <div className="rowAlignCenterFullWidth loginMenuBtnBackground" onClick={onHandlePhoneMobileLogin}>
                            <div className="rowAlignCenterFullWidth loginMenuBtnForPhone">
                                <img className="loginMenuLogo" src={icon_login_phone} alt='' />
                                <span>Login with Phone number</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* {fbLoginMsg && <div className="columnAlignStartFullWidth loginTopEdge">{fbLoginMsg}</div>}
                {fbUserMsg && <div className="columnAlignStartFullWidth loginTopEdge">{fbUserMsg}</div>}
                {fbFriendsMsg && <div className="columnAlignStartFullWidth loginTopEdge">{fbFriendsMsg}</div>}
                {fbLogoutMsg && <div className="columnAlignStartFullWidth loginTopEdge">{fbLogoutMsg}</div>} */}


                {/* 转登录页弹框 */}
                <Mask visible={needToLoginPage} opacity={0.1}
                    onMaskClick={() => { }}>
                    <div className="applyOverlayBg">
                        <div className="applyOverlayContent">
                            <div className="applyOverlayContentIconCenter" onClick={() => { setNeedToLoginPage(false) }}>
                                <img className="applyOverlayIconCenter" src={icon_login_refuse} alt="" />
                            </div>

                            <div className="applyOverlayContentRowTitle">It is detected that you have already registered an account.</div>
                            <div className="applyOverlayContentRowTitle">Please login to your account through the login portal.</div>

                            <div className="homeApplyBtnLarge loginTopEdge" onClick={() => { setNeedToLoginPage(false) }}>
                                <div className="mainButtonGradient">OK</div>
                            </div>
                        </div>
                    </div>
                </Mask>

                {/* 转注册页弹框 */}
                <Mask visible={needToRegisterPage} opacity={0.1}
                    onMaskClick={() => { }}>
                    <div className="applyOverlayBg">
                        <div className="applyOverlayContent">
                            <div className="applyOverlayContentIconCenter" onClick={() => { setNeedToRegisterPage(false) }}>
                                <img className="applyOverlayIconCenter" src={icon_login_refuse} alt="" />
                            </div>

                            <div className="applyOverlayContentRowTitle">No account has been registered with this identity information.</div>
                            <div className="applyOverlayContentRowTitle">Please change your identity to log in or register a new account.</div>

                            <div className="homeApplyBtnLarge loginTopEdge" onClick={() => { setNeedToRegisterPage(false) }}>
                                <div className="mainButtonGradient">OK</div>
                            </div>
                        </div>
                    </div>
                </Mask>

                {/* 不在服务地区弹框 */}
                {/* <Mask visible={notInServiceLocation} opacity={0.1}
                    onMaskClick={() => { }}>
                    <div className="applyOverlayBg">
                        <div className="applyOverlayContent">
                            <div className="applyOverlayContentIconCenter" onClick={() => { setNotInServiceLocation(false) }}>
                                <img className="applyOverlayIconCenter" src={icon_login_not_in_location} alt="" />
                            </div>

                            <div className="applyOverlayContentRowHeaderTitle">Your area is outside our service area.</div>
                            <div className="applyOverlayContentRowDataSource">
                                <img className="applyOverlayContentIconDataSource loginRightEdge8Px" src={icon_login_service_tips} alt="" />
                                <div className="applyOverlayContentRowTitleDataSource">
                                    <span>
                                        Data source: <span className="applyOverlayContentRowTitleDataSourceValue">Your Facebook public profile_hometown.</span>
                                    </span>
                                </div>
                            </div>

                            <div className="homeApplyBtnLarge loginTopEdge" onClick={() => { setNotInServiceLocation(false) }}>
                                <div className="mainButtonGradient">OK!</div>
                            </div>
                        </div>
                    </div>
                </Mask> */}

                {/* 不在服务年龄区弹框 */}
                {/* <Mask visible={notInServiceAgeRange} opacity={0.1}
                    onMaskClick={() => { }}>
                    <div className="applyOverlayBg">
                        <div className="applyOverlayContent">
                            <div className="applyOverlayContentIconCenter" onClick={() => { setNotInServiceAgeRange(false) }}>
                                <img className="applyOverlayIconCenter" src={icon_login_not_in_age_range} alt="" />
                            </div>

                            <div className="applyOverlayContentRowHeaderTitle">Sorry, your age does not meet the age range of customers for our product services.</div>
                            <div className="applyOverlayContentRowDataSource">
                                <img className="applyOverlayContentIconDataSource loginRightEdge8Px" src={icon_login_service_tips} alt="" />
                                <div className="applyOverlayContentRowTitleDataSource">
                                    <span>
                                        Data source: <span className="applyOverlayContentRowTitleDataSourceValue">Your Facebook public profile_Birthday; Your Facebook public profile_age range.</span>
                                    </span>
                                </div>
                            </div>

                            <div className="homeApplyBtnLarge loginTopEdge" onClick={() => { setNotInServiceAgeRange(false) }}>
                                <div className="mainButtonGradient">OK!</div>
                            </div>
                        </div>
                    </div>
                </Mask> */}

                {/* add home gif dialog */}
                <AddToHomeGifDialog visible={addHomeMaskVisible} onConfirm={onHandleAddToHome} onClose={onHandleAddToHomeClose} isHomeMode={addHomeMaskIsHomeMode} />

                {/* load mask */}
                <Mask visible={maskVisible} opacity={0.1}
                    onMaskClick={() => { }}>
                    <div className="overlayContent">
                        <span style={{ fontSize: 24, color: '#ff3141' }} >
                            <DotLoading color="currentColor" />
                        </span>
                    </div>
                </Mask>
            </div>
        </div >
    )
}