import React, { useEffect, useState } from "react";
import './repay.css';
import '../../../App.css'
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Mask, DotLoading } from "antd-mobile";
import { useDispatch, useSelector } from "react-redux";
import icon_coupon_dialog_select from "@/assets/icon_coupon_dialog_select.png"
import icon_uncheck from "@/assets/icon_uncheck.png"
import icon_repay_rollover from "@/assets/icon_repay_rollover.png"
import icon_repay_ext_down from "@/assets/icon_repay_ext_down.png"
import icon_repay_ext_up from "@/assets/icon_repay_ext_up.png"
import icon_repay_tips1 from "@/assets/icon_repay_tips1.png"
import icon_repay_tips2 from "@/assets/icon_repay_tips2.png"
import icon_repay_tips3 from "@/assets/icon_repay_tips3.png"
import icon_repay_tips4 from "@/assets/icon_repay_tips4.png"

import { REPAY_BILL_REQ_FAIL, REPAY_BILL_REQ_SUCCESS, getRepayDetail } from "@/store/actions/repayDetail";
import { REPAY_URL_REQ_FAIL, REPAY_URL_REQ_SUCCESS, getRepayUrl } from "@/store/actions/repayUrl";
import { saveEvent } from "@/store/actions/saveEvent";
// import AddFavorite from "@/components/AddFavorite";
import AddHomeEvent from "@/utils/addHomeEvent";
import AddToHomeGifDialog from "@/components/AddToHomeGifDialog/AddToHomeGifDialog";
import { REPAY_USER_STATE_REQ_FAIL, REPAY_USER_STATE_REQ_SUCCESS, getUserState } from "@/store/actions/userState";
import { PageTag } from "@/utils/pageTag";
import { isLoanLoaning, isLoanReject, isLoanRepayNow, isLoanReviewing } from "@/utils/loanStatus";
import NavigateBarRepay from "@/components/NavigateBarRepay/NavigateBarRepay";
import PaidAskDialog from "@/components/PaidAskDialog/PaidAskDialog";
import icon_coupon_apply_item_bg from "@/assets/icon_coupon_apply_item_bg.png"
import icon_coupon_apply_checkbox_yes from "@/assets/icon_coupon_apply_checkbox_yes.png"
import icon_apply_coupon_reduce from "@/assets/icon_apply_coupon_reduce.png"
import CouponClick from "@/pages/Coupon/CouponClick";
import { CouponFromSource } from "@/pages/Coupon/CouponFromSource";


/**
 * 还款页
 */
export default function RepayCoupon() {

    //loading状态
    const [maskVisible, setMaskVisible] = useState(false)

    //显示add home弹框
    const [addHomeMaskVisible, setAddHomeMaskVisible] = useState(false);
    const [addHomeMaskIsHomeMode, setAddHomeMaskIsHomeMode] = useState(true);

    //显示支付提示弹框
    const [paidAskMaskVisible, setPaidAskMaskVisible] = useState(false);

    //显示支付详细
    const [billDetallVisible, setBillDetallVisible] = useState(false);

    // 获取路由历史 history 对象
    const history = useHistory()

    //获取 Redux 分发器
    const dispatch = useDispatch()

    //获取Redux Store中的信息
    const repayDetailInfo = useSelector(state => state.repayDetail)

    //获取Redux Store中的信息
    const repayUrlInfo = useSelector(state => state.repayUrl)

    //获取Redux Store中的用户状态信息
    const userNotExistInfo = useSelector(state => state.userNotExist)

    //获取Redux Store中的用户状态信息
    const userStateInfo = useSelector(state => state.userState)

    //确认申请
    const doRepay = async () => {
        console.log("doRepay: ");

        //确认申请
        try {
            //获取Action
            const action = getRepayUrl()

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //打点接口
    const doHitEvent = async (type, remark = "") => {
        console.log("doHitEvent: " + type + " , " + remark);

        try {
            //获取Action
            const action = saveEvent(type, remark)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    // 点击展示还款信息
    const onHandleBillDetailExtClick = () => {
        setBillDetallVisible(!billDetallVisible)
    }

    //点击提交
    const onHandleSubmit = () => {
        //打点
        doHitEvent("REPAY_COMMIT_CLICK")

        //显示loading
        setMaskVisible(true)
        //获取还款链接
        doRepay()
    }

    // 点击add home监听
    const onHandleAddToHome = (type) => {
        console.log(`点击add home监听: ${type}`)
        if (type === 'home') {
            AddHomeEvent.onHandleAddHomeEventClick()
        } else {
            AddHomeEvent.onHandleAddFavoriteEventClick()
        }
    }

    // 关闭add home
    const onHandleAddToHomeClose = () => {
        console.log(`点击close add home监听`)
        setAddHomeMaskVisible(false)
    }

    // 刷新操作
    const onHandleRefresh = () => {
        // 查询用户流程状态
        checkUserStateInfo()
    }

    // 关闭支付提示弹框
    const onHandlePaidAskDialogClose = () => {
        // 关闭弹框
        setPaidAskMaskVisible(false)
    }

    //已支付
    const onHandleDidPaid = () => {
        // 关闭弹框
        setPaidAskMaskVisible(false)

        // 查询状态
        checkUserStateInfo()
    }

    // 未支付
    const onHandleNotPaid = () => {
        setPaidAskMaskVisible(false)
    }

    //查询用户流程状态
    const checkUserStateInfo = async () => {
        console.log("REPAY-checkUserStateInfo: ");

        //获取用户状态
        try {
            //显示loading
            setMaskVisible(true)

            //获取Action
            const action = getUserState(PageTag.REPAY)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //查询还款信息
    const checkRepayInfo = async () => {
        console.log("REPAY-checkRepayInfo: ");

        //获取用户状态
        try {
            //显示loading
            setMaskVisible(true)

            //获取Action
            const action = getRepayDetail()

            //触发接口
            dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //启动请求
    useEffect(() => {
        //获取贷款详情
        try {
            // 优惠券入口
            CouponClick.fromParentPage = PageTag.REPAY

            // 查询用户状态
            checkUserStateInfo()

            //打点
            doHitEvent("REPAY_START")

            return () => {
                //打点
                doHitEvent("REPAY_END")
            }
        } catch (e) {
            console.log(e)
        }
    }, [])

    //获取还款详情成功返回
    useEffect(() => {
        //获取还款详情成功返回
        if (repayDetailInfo.status === REPAY_BILL_REQ_SUCCESS) {
            console.log("Repay-还款详情获取成功")
            console.log(repayDetailInfo)
            //关闭loading
            setMaskVisible(false)

        } else if (repayDetailInfo.status === REPAY_BILL_REQ_FAIL) {
            //关闭loading
            setMaskVisible(false)
        }
    }, [repayDetailInfo])

    //获取还款链接功返回
    useEffect(() => {
        //获取还款链接功返回
        if (repayUrlInfo.status === REPAY_URL_REQ_SUCCESS) {
            console.log("Repay-还款链接获取成功")
            console.log(repayUrlInfo)

            // 显示支付询问弹框
            setPaidAskMaskVisible(true)

            setTimeout(() => {
                //关闭loading
                setMaskVisible(false)

                const url = repayUrlInfo.url
                window.open(url, "_blank")
            }, 500)

        } else if (repayUrlInfo.status === REPAY_URL_REQ_FAIL) {
            //关闭loading
            setMaskVisible(false)
        }
    }, [repayUrlInfo])


    // 查询用户流程状态返回监听
    useEffect(() => {
        try {

            console.log("REPAY-查询用户流程状态返回监听")
            console.log(userStateInfo)

            if (userStateInfo.status === REPAY_USER_STATE_REQ_SUCCESS) {
                //隐藏loading
                setMaskVisible(false)

                console.log("LOAING-再次查询user state:")
                console.log("LOAING-applyStatus: " + userStateInfo.applyStatus)
                console.log("LOAING-orderStatus: " + userStateInfo.orderStatus)
                console.log("LOAING-signFlag: " + userStateInfo.signFlag)

                //是否为审核中
                const isHomeReviewing = isLoanReviewing(userStateInfo.applyStatus)
                //是否为拒绝
                const isHomeReject = isLoanReject(userStateInfo.applyStatus, userStateInfo.orderStatus)
                //是否为放款中
                const isHomeLoaning = isLoanLoaning(userStateInfo.orderStatus)
                //是否为待还款
                const isHomeRepay = isLoanRepayNow(userStateInfo.orderStatus)
                //是否为待进件
                const isHomeNotLoan = ((!isHomeReviewing) && (!isHomeReject) && (!isHomeLoaning) && (!isHomeRepay))
                console.log("LOAING-isHomeReviewing: " + isHomeReviewing)
                console.log("LOAING-isHomeReject: " + isHomeReject)
                console.log("LOAING-isHomeLoaning: " + isHomeLoaning)
                console.log("LOAING-isHomeRepay: " + isHomeRepay)
                console.log("LOAING-isHomeNotLoan: " + isHomeNotLoan)

                //页面转发
                setTimeout(() => {
                    if (isHomeReviewing) {
                        history.replace('/review')
                    } else if (isHomeReject) {
                        history.replace('/reject')
                    } else if (isHomeLoaning) {
                        history.replace('/loaning')
                    } else if (isHomeRepay) {
                        //获取还款信息
                        checkRepayInfo()

                    } else {
                        history.replace('/home')
                    }
                }, 500)

            } else if (userStateInfo.status === REPAY_USER_STATE_REQ_FAIL) {
                //隐藏loading
                setMaskVisible(false)
            }
        } catch (e) {
            console.log(e)
        }
    }, [userStateInfo])


    //监听用户是否存在
    useEffect(() => {
        if (userNotExistInfo.notExist === true) {
            history.replace('/login')
            // history.replace('/loginfb')
        }
    }, [userNotExistInfo])

    return (
        <div className="repayRoot">
            <NavigateBarRepay theme="white" onAddFavorite={() => { setAddHomeMaskIsHomeMode(false); setAddHomeMaskVisible(true) }} onAddHome={() => { setAddHomeMaskIsHomeMode(true); setAddHomeMaskVisible(true) }} onRefresh={onHandleRefresh}>Loan repayment</NavigateBarRepay>

            {/* 使用添加 home screen bar */}
            {/* <AddFavorite onAddHome={() => { setAddHomeMaskIsHomeMode(true); setAddHomeMaskVisible(true) }} /> */}

            <div className="repayScrollBody">
                <div className="repayTopEdge"></div>

                <div className="repayContent">
                    <div className="repayContentScroll">
                        <div className="repayPlan">
                            <div className="repayPlanHeader">
                                <div className="repayPlanHeaderContent">
                                    <img
                                        className="repayPlanHeaderIcon"
                                        src={icon_coupon_dialog_select}
                                        alt="" />
                                    <span className="repayItemHeaderTitle">Make payment in full:</span>
                                </div>
                                <span className="repayItemHeaderTips">You can borrow again immediately after repayment.</span>
                            </div>

                            {/* 还款信息 */}
                            <div className="repayItemList">
                                <div className="repayItemBillContent">
                                    <span className="repayItemBillHeaderTitle">Total repayment</span>
                                    <span className="repayItemBillHeaderAmount">{repayDetailInfo.repayAmount} {repayDetailInfo.currency}</span>
                                    <span className="repayItemBillCurrency"></span>
                                </div>
                                <div className="repayItemBillContent">
                                    <span className="repayItemBillHeaderTitle">Due date</span>
                                    <span className="repayItemBillHeaderAmount">{repayDetailInfo.repayDate}</span>
                                    <span className="repayItemBillCurrency"></span>
                                </div>

                                <span className='repayRowLine'></span>

                                {billDetallVisible && <div className="columnAlignStartFullWidth">
                                    <div className="repayItemBillContent">
                                        <span className="repayItemBillItemTitle">Loan amount</span>
                                        <span className="repayItemBillItemAmount">{repayDetailInfo.amount}</span>
                                        <span className="repayItemBillCurrency">{repayDetailInfo.currency}</span>
                                    </div>
                                    <div className="repayItemBillContent">
                                        <span className="repayItemBillItemTitle">Interest</span>
                                        <span className="repayItemBillItemAmount">{repayDetailInfo.interest}</span>
                                        <span className="repayItemBillCurrency">{repayDetailInfo.currency}</span>
                                    </div>
                                    <div className="repayItemBillContent">
                                        <span className="repayItemBillItemTitle">Service fee</span>
                                        <span className="repayItemBillItemAmount">{repayDetailInfo.repayProFee}</span>
                                        <span className="repayItemBillCurrency">{repayDetailInfo.currency}</span>
                                    </div>
                                    <div className="repayItemBillContent">
                                        <span className="repayItemBillItemTitle">Overdue days</span>
                                        <span className="repayItemBillItemAmount">{repayDetailInfo.overdueDay}</span>
                                        <span className="repayItemBillCurrency"> days</span>
                                    </div>
                                    <div className="repayItemBillContent">
                                        <span className="repayItemBillItemTitle">Overdue penalty</span>
                                        <span className="repayItemBillItemAmount">{repayDetailInfo.overdueAmount}</span>
                                        <span className="repayItemBillCurrency">{repayDetailInfo.currency}</span>
                                    </div>
                                </div>}

                                <div className="repayBillExtContent" onClick={onHandleBillDetailExtClick}>
                                    <img className="repayBillExtImage" src={billDetallVisible ? icon_repay_ext_up : icon_repay_ext_down} alt="" />
                                </div>
                            </div>
                        </div>

                        {/* 展期还款 */}
                        {repayDetailInfo.canDeferred && <div className="repayPlan">
                            <div className="repayPlanHeader">
                                <div className="repayPlanHeaderContent">
                                    <img
                                        className="repayPlanHeaderIcon"
                                        src={icon_uncheck}
                                        alt="" />
                                    <span className="repayItemHeaderTitle">Rollover repayment:</span>
                                </div>
                                <span className="repayItemHeaderTips">After paying the extended service charges, your loan can be deferred for closing..</span>
                            </div>
                            {/* 展期信息 */}
                            <div className="repayItemList">
                                <div className="repayItemBillContent">
                                    <span className="repayItemBillItemTitle">Extended service charges</span>
                                    <span className="repayItemBillHeaderAmount">{repayDetailInfo.deferredRepay.currentRepayAmount} {repayDetailInfo.currency}</span>
                                    <span className="repayItemBillCurrency"></span>
                                </div>
                                <div className="repayItemBillContent">
                                    <span className="repayItemBillItemTitle">Days of extension</span>
                                    <span className="repayItemBillHeaderAmount">{repayDetailInfo.deferredRepay.days}</span>
                                    <span className="repayItemBillCurrency"> days</span>
                                </div>

                                {/* 展期日期 */}
                                <div className="repayItemBillContent">
                                    <span className="repayItemBillHeaderTitle">Due date after extension</span>
                                </div>
                                <div className="repayItemBillContent">
                                    <span className="repayRolloverTitle">{repayDetailInfo.deferredRepay.currentRepayTime}</span>
                                    <img
                                        className="repayRolloverIcon"
                                        src={icon_repay_rollover}
                                        alt="" />
                                    <span className="repayItemBillItemTitle">{repayDetailInfo.deferredRepay.deferredRepayTime}</span>
                                </div>

                                {/* 展期金额 */}
                                <div className="repayItemBillContent">
                                    <span className="repayItemBillHeaderTitle">Total repayment due</span>
                                </div>
                                <div className="repayItemBillContent">
                                    <span className="repayItemBillHeaderTitle">{repayDetailInfo.deferredRepay.deferredRepayAmount} {repayDetailInfo.currency}</span>
                                </div>
                            </div>
                        </div>}

                        {/* 优惠券模块 */}
                        {repayDetailInfo.couponFlag === "YES" && <div className="repayCouponBg repayMarginTop12Px">
                            <div className="repayCouponContent">
                                <img className="repayCouponImg" src={icon_coupon_apply_item_bg} alt="" />
                                <div className="repayCouponAmountContent">
                                    <img className="repayCouponCheckboxImg" src={icon_coupon_apply_checkbox_yes} alt="" />
                                    <div className="repayCouponTextContent">
                                        <div className="repayCouponTitle">Repayment Coupon</div>
                                        <div className="repayCouponAmount">₦{repayDetailInfo.couponAmount}</div>
                                        <div className="repayCouponTitle">Discount on repayment</div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {/* 还款说明模块 */}
                        <div className="columnAlignStartFullWidth">
                            <div className="repayTipsTitle">Complete the repayment</div>
                            <div className="repayTIpsRowContent">
                                <div className="repayTipsRow">
                                    <img className="repayTipsImage" src={icon_repay_tips1} alt="" />
                                    <div className="repayTipsText">You will be able to apply for loan again immediately.</div>
                                </div>
                                <div className="repayTipsRow">
                                    <img className="repayTipsImage" src={icon_repay_tips2} alt="" />
                                    <div className="repayTipsText">Loan limit will be increased.</div>
                                </div>
                            </div>

                            <div className="repayTipsTitle">Serious overdue will result in</div>
                            <div className="repayTIpsRowContent">
                                <div className="repayTipsRow">
                                    <img className="repayTipsImage" src={icon_repay_tips3} alt="" />
                                    <div className="repayTipsText">Your credit is damaged and you will no longer be able to get  loan.</div>
                                </div>
                                <div className="repayTipsRow">
                                    <img className="repayTipsImage" src={icon_repay_tips4} alt="" />
                                    <div className="repayTipsText">If you are seriously overdue, you will face litigation, which will result in high litigation costs for you.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {repayDetailInfo.couponFlag === "YES" && <div className="repayItemBillCouponContent">
                    <img className="repayItemBillItemImg" src={icon_apply_coupon_reduce} alt="" />
                    <span className="repayItemBillItemTitle repayItemCouponColor">Discount on repayment</span>
                    <span className="repayItemBillItemAmount applyCouponReduceAmount repayItemCouponColor">-{repayDetailInfo.couponAmount}</span>
                    <span className="repayItemBillCurrency repayItemCouponColor">{repayDetailInfo.currency}</span>
                </div>}

                <div className="repayBottomEdge"></div>
            </div>

            <div className="repayBottom">
                <div className="repayBottomAmountContent">
                    <span className="repayBottomAmountTitle">Payment amount</span>
                    {repayDetailInfo.couponFlag === "YES" && <span className="repayBottomCouponAmount">{repayDetailInfo.repayAmount} {repayDetailInfo.currency}</span>}
                    <span className="repayBottomAmount">{repayDetailInfo.couponFlag === "YES" ? repayDetailInfo.couponRepayAmount : repayDetailInfo.repayAmount} {repayDetailInfo.currency}</span>
                </div>
                <button type="submit" className="repayApplyBtn" onClick={onHandleSubmit}>Pay</button>
            </div>

            {/* add home gif dialog */}
            <AddToHomeGifDialog visible={addHomeMaskVisible} onConfirm={onHandleAddToHome} onClose={onHandleAddToHomeClose} isHomeMode={addHomeMaskIsHomeMode} />

            {/* 支付提示弹框 */}
            <PaidAskDialog visible={paidAskMaskVisible} onClose={onHandlePaidAskDialogClose} onDidPaid={onHandleDidPaid} onNotPaid={onHandleNotPaid} />

            <Mask visible={maskVisible} opacity={0.1}
                onMaskClick={() => { }}>
                <div className="overlayContent">
                    <span style={{ fontSize: 24, color: '#ff3141' }} >
                        <DotLoading color="currentColor" />
                    </span>
                </div>
            </Mask>
        </div >
    )
}