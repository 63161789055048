/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import './launch.css';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LAUNCH_REFERRER_USER_STATE_REQ_SUCCESS, LAUNCH_USER_STATE_REQ_SUCCESS, getUserState } from "@/store/actions/userState";
import icon_app_logo from "@/assets/icon_app_logo.jpg"
import { isLogin } from "@/utils/storage";
import { isLoanLoaning, isLoanReject, isLoanRepayNow, isLoanReviewing, isLoanSigning } from "@/utils/loanStatus";
import { APP_CONFIG_REQ_SUCCESS, getAppConfig } from "@/store/actions/appConfig";
import { PageTag } from "@/utils/pageTag";
import { saveEvent } from "@/store/actions/saveEvent";
import SmartChat from "@/utils/smartChat";
import AppParams from "@/utils/appParams";

/**
 * 审核中页
 */
export default function Launch() {

    //判断是否登录
    const checkLogin = isLogin()
    console.log("checkLogin: " + checkLogin)

    // 获取路由历史 history 对象
    const history = useHistory()

    //获取 Redux 分发器
    const dispatch = useDispatch()

    //获取Redux Store中的app配置状态信息
    const appConfigInfo = useSelector(state => state.appConfig)

    //获取Redux Store中的用户状态信息
    const userStateInfo = useSelector(state => state.userState)

    //获取Redux Store中的用户状态信息
    const userNotExistInfo = useSelector(state => state.userNotExist)

    //获取app配置信息
    const getAppConfigInfo = () => {
        console.log("获取app配置信息=====")
        try {
            //获取Action
            const action = getAppConfig()

            //触发接口
            dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //获取用户流程信息
    const getUserStateInfo = () => {
        console.log("获取用户流程信息=====")
        try {
            //获取Action
            const action = getUserState(PageTag.LAUNCH)

            //触发接口
            dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //获取用户流程信息
    const getUserStateInfoFromReferrer = () => {
        console.log("获取用户流程信息=====")
        try {
            //获取Action
            const action = getUserState(PageTag.LAUNCH_REFERRER)

            //触发接口
            dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //打点接口
    const doHitEvent = async (type, remark = "") => {
        console.log("doHitEvent: " + type + " , " + remark);

        try {
            //获取Action
            const action = saveEvent(type, remark)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //启动时请求用户状态
    useEffect(() => {
        //打点
        doHitEvent("LAUNCH_START")

        //如果没有登录，直接转登录页
        setTimeout(() => {
            console.log("查询启动时请求用户状态：" + checkLogin)

            if (AppParams.tid.length > 0) {
                //获取app配置
                getAppConfigInfo()

            } else if (!checkLogin) {
                // 跳转登录页
                history.replace("/loginad")
                // history.replace('/loginfb')

            } else {
                //保存smart chat 用户信息
                console.log("=====保存 smart chat user info")
                SmartChat.init();

                //获取app配置
                getAppConfigInfo()
            }
        }, 3000)

        return () => {
            //打点
            doHitEvent("LAUNCH_END")
        }
    }, [])

    useEffect(() => {
        if (appConfigInfo.status === APP_CONFIG_REQ_SUCCESS) {
            console.log("Launch-待等app配置信息返回")
            console.log(appConfigInfo)

            if (AppParams.tid.length > 0) {
                //获取用户状态
                getUserStateInfoFromReferrer()

            } else {
                //获取用户状态
                getUserStateInfo()
            }
        }
    }, [appConfigInfo])

    useEffect(() => {
        console.log("Launch-待等用户流程信息返回")
        console.log(userStateInfo)
        if (userStateInfo.status === LAUNCH_USER_STATE_REQ_SUCCESS) {
            console.log("Launch-再次查询user state:")
            console.log("Launch-applyStatus: " + userStateInfo.applyStatus)
            console.log("Launch-orderStatus: " + userStateInfo.orderStatus)
            console.log("Launch-signFlag: " + userStateInfo.signFlag)
            console.log("Launch-signOrderId: " + userStateInfo.signOrderId)

            //是否为签约中
            const isHomeSigning = isLoanSigning(userStateInfo.signFlag, userStateInfo.signOrderId)
            //是否为审核中
            const isHomeReviewing = isLoanReviewing(userStateInfo.applyStatus)
            //是否为拒绝
            const isHomeReject = isLoanReject(userStateInfo.applyStatus, userStateInfo.orderStatus)
            //是否为放款中
            const isHomeLoaning = isLoanLoaning(userStateInfo.orderStatus)
            //是否为待还款
            const isHomeRepay = isLoanRepayNow(userStateInfo.orderStatus)
            //是否为待进件
            const isHomeNotLoan = ((!isHomeReviewing) && (!isHomeReject) && (!isHomeLoaning) && (!isHomeRepay))
            console.log("Launch-isHomeReviewing: " + isHomeReviewing)
            console.log("Launch-isHomeReject: " + isHomeReject)
            console.log("Launch-isHomeLoaning: " + isHomeLoaning)
            console.log("Launch-isHomeRepay: " + isHomeRepay)
            console.log("Launch-isHomeNotLoan: " + isHomeNotLoan)
            console.log("Launch-isHomeSigning: " + isHomeSigning)

            //页面转发
            setTimeout(() => {
                if (isHomeSigning) {
                    history.replace('/guide')
                } else if (isHomeReviewing) {
                    history.replace('/review')
                } else if (isHomeReject) {
                    history.replace('/reject')
                } else if (isHomeLoaning) {
                    history.replace('/loaning')
                } else if (isHomeRepay) {
                    history.replace('/repay')
                } else {
                    history.replace('/home')
                }
            }, 500)

        } else if (userStateInfo.status === LAUNCH_REFERRER_USER_STATE_REQ_SUCCESS) {
            //保存smart chat 用户信息
            console.log("=====保存 smart chat user info")
            SmartChat.init()

            console.log("Launch-再次查询user state:")
            console.log("Launch-applyStatus: " + userStateInfo.applyStatus)
            console.log("Launch-orderStatus: " + userStateInfo.orderStatus)
            console.log("Launch-signFlag: " + userStateInfo.signFlag)
            console.log("Launch-signOrderId: " + userStateInfo.signOrderId)

            //是否为签约中
            const isHomeSigning = isLoanSigning(userStateInfo.signFlag, userStateInfo.signOrderId)
            //是否为审核中
            const isHomeReviewing = isLoanReviewing(userStateInfo.applyStatus)
            //是否为拒绝
            const isHomeReject = isLoanReject(userStateInfo.applyStatus, userStateInfo.orderStatus)
            //是否为放款中
            const isHomeLoaning = isLoanLoaning(userStateInfo.orderStatus)
            //是否为待还款
            const isHomeRepay = isLoanRepayNow(userStateInfo.orderStatus)
            //是否为待进件
            const isHomeNotLoan = ((!isHomeReviewing) && (!isHomeReject) && (!isHomeLoaning) && (!isHomeRepay))
            console.log("Launch-isHomeReviewing: " + isHomeReviewing)
            console.log("Launch-isHomeReject: " + isHomeReject)
            console.log("Launch-isHomeLoaning: " + isHomeLoaning)
            console.log("Launch-isHomeRepay: " + isHomeRepay)
            console.log("Launch-isHomeNotLoan: " + isHomeNotLoan)
            console.log("Launch-isHomeSigning: " + isHomeSigning)

            //页面转发
            setTimeout(() => {
                if (isHomeSigning) {
                    history.replace('/guide')
                } else if (isHomeReviewing) {
                    history.replace('/review')
                } else if (isHomeReject) {
                    history.replace('/reject')
                } else if (isHomeLoaning) {
                    history.replace('/loaning')
                } else if (isHomeRepay) {
                    history.replace('/repay')
                } else {
                    history.replace('/home')
                }
            }, 500)
        }
    }, [userStateInfo])

    //监听用户是否存在
    useEffect(() => {
        if (userNotExistInfo.notExist === true) {
            history.replace('/loginad')
            // history.replace('/loginfb')
        }
    }, [userNotExistInfo])


    return (
        <div className="launchRoot">
            <img
                className="launchImageSmall"
                src={icon_app_logo}
                alt="" />
            <span className="launchTitle">Loan Salad</span>
        </div>
        // <div className="loanRootImage">
        //     <div hidden className="loanContent">
        //         <div className="loanContentFlexCenter">
        //             <img
        //                 className="statusImageSmall"
        //                 src={icon_app_logo}
        //                 alt="" />
        //             <span className="statusTitle">Loan Salad</span>
        //         </div>

        //     </div>
        // </div >
    )
}