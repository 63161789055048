
import http, { baseParams } from "@/utils/request";
import { UrlSaveFaceInfo } from "@/utils/apiUrl";
import { Toast } from "antd-mobile";

//类型：请求成功
export const SAVE_FACE_REQ_SUCCESS = "SAVE_FACE_REQ_SUCCESS"

//类型：请求失败
export const SAVE_FACE_REQ_FAIL = "SAVE_FACE_REQ_FAIL"

/**
 * 保存人脸信息
 * @returns thunk
 */
export const saveFace = (imgUrl, resCallback) => {
    return async (dispatch) => {
        //组装参数
        const params = baseParams()
        params.faceImgUrl = imgUrl
        params.cardType = ""

        //发起请求
        await http.post(UrlSaveFaceInfo, params)
            .then((response) => {
                // 处理上传成功的响应
                const resCode = parseInt(response.code)
                if (resCode === 0) {
                    console.log('=======人脸保存成功');
                    console.log(response);
                    resCallback(true)
                } else {
                    Toast.show(response.msg || "")
                    resCallback(false)
                }
            })
            .catch((error) => {
                // 处理上传失败和网络错误
                console.error('=======人脸保存失败', error);
                Toast.show(error)
                resCallback(false)
            });
    }
}
