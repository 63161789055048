import React from "react";
import PropTypes from 'prop-types'
import './index.css'

function Icon({ picUrl, onItemClick }) {
    return (
        <img className="leftImg" src={picUrl} alt="" onClick={onItemClick} />
    )
}

Icon.prototype = {
    img: PropTypes.string.isRequired
}

export default Icon