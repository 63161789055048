import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import HotlineDrawer from '../HotlineDrawer';
import icon_hotline from "@/assets/icon_hotline.png"
import icon_hotline_white from "@/assets/icon_hotline_white.png"
import icon_app_logo from "@/assets/icon_app_logo.jpg"
import icon_add_home from "@/assets/icon_add_home.png"
import icon_privacy_policy from "@/assets/icon_privacy_policy.png"
import icon_add_favorite_white from "@/assets/icon_add_favorite_white.png"
import icon_customer_service from "@/assets/icon_customer_service.png"
import icon_add_home_theme from "@/assets/icon_add_home_theme.png"
import icon_signin_out from "@/assets/icon_signin_out.png"
import icon_add_subscribe from "@/assets/icon_add_subscribe.png"
import icon_add_favorite from "@/assets/icon_add_favorite.png"
import icon_user_center_bg from "@/assets/icon_user_center_bg.jpg"
import icon_member_level_silver from "@/assets/icon_member_level_silver.png"
import icon_refresh from "@/assets/icon_refresh.png"
import icon_coupon_nav from "@/assets/icon_coupon_nav.gif"
import icon_member_center from "@/assets/icon_member_center.png"
import icon_member_center_ext from "@/assets/icon_member_center_ext.png"
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo, removeUserInfo } from '@/utils/storage';
import { APP_CONFIG_REQ_SUCCESS, getAppConfig } from '@/store/actions/appConfig';
import { DotLoading, Mask, Toast } from 'antd-mobile';
import { cleanOtpState } from '@/store/actions/sendOtp';
import { cleanLoginState } from '@/store/actions/login';
import { cleanUserState } from '@/store/actions/userState';
import '../NavigateBar/navigateBar.css';
import '../../App.css'
import { saveEvent } from '@/store/actions/saveEvent';
import CouponClick from '@/pages/Coupon/CouponClick';
import { CouponFromSource } from '@/pages/Coupon/CouponFromSource';

/**
 * 
 * @param {*} theme 主题，black(背景黑色，文字为白色), white(背影白色，文字为黑色), transparent(背影透明，文字为白色)
 * @returns 
 */
function NavigateBarRepay({ theme = 'white', showLogout = true, onAddHome, onAddFavorite, onRefresh, children }) {

    //导航
    const history = useHistory()

    // 获取 Redux 分发器
    const dispatch = useDispatch()

    //loading状态
    const [maskVisible, setMaskVisible] = useState(false)

    //是否可以显示抽屉
    const [canShowDrawer, setCanShowDrawer] = useState(false)

    //用户手机号
    const [mobile, setMobile] = useState("")

    //获取Redux Store中的app配置状态信息
    const appConfigInfo = useSelector(state => state.appConfig)

    //客服抽屉状态
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    //显示抽屉
    const onHandleOpenDrawer = () => {
        //可以显示抽屉
        setCanShowDrawer(true)

        if (!appConfigInfo.mobileList) {
            getAppConfigInfo()
        } else {
            setIsDrawerOpen(true);
        }
    }

    //关闭抽屉
    const onHandleCloseDrawer = () => {
        setIsDrawerOpen(false);
    }

    //点击退出
    const onHandleSignOut = () => {
        //移除登录信息
        removeUserInfo()

        //清除验证码信息
        dispatch(cleanOtpState())

        //清除登录信息
        dispatch(cleanLoginState())

        //清除流程信息
        dispatch(cleanUserState())

        history.replace('/login')
        // history.replace('/loginfb')
    }

    //刷新
    const onHandleRefresh = () => {
        console.log("onHandleRefresh")
        onRefresh && onRefresh()
    }

    //添加桌面快捷方式
    const onHandleAddHome = () => {
        console.log("onHandleAddHome")

        //触发安装事件
        //AddHomeEvent.onHandleAddHomeEventClick()
        onAddHome && onAddHome()
    }

    //添加书签方式
    const onHandleAddFavorite = () => {
        console.log("onHandleAddFavorite")

        //触发安装事件
        //AddHomeEvent.onHandleAddHomeEventClick()
        onAddFavorite && onAddFavorite()
    }

    //跳转权益
    const onHandleCoupon = () => {
        console.log("跳转权益:")
        CouponClick.showCouponFromSource = CouponFromSource.REPAY+"_DRAWER"

        //记录点击事件
        doHitEvent("DRAWER_TO_COUPON", CouponFromSource.REPAY || "")
        history.push('/coupon')
    }

    //跳转权益-bar
    const onHandleCouponFromBar = () => {
        console.log("跳转权益: bar")
        CouponClick.showCouponFromSource = CouponFromSource.REPAY
        
        //记录点击事件
        doHitEvent("NAV_TO_COUPON", CouponFromSource.REPAY || "")
        history.push('/coupon')
    }

    //拨打电话
    const onHandlePhoneCall = (phoneNumber) => {
        console.log("拨打电话:" + phoneNumber)
        window.location.href = `tel:${phoneNumber}`
    }

    //复制号码
    const onHandlePhoneCopy = (phoneNumber) => {
        console.log("复制号码:" + phoneNumber)
        navigator.clipboard.writeText(phoneNumber)
            .then(() => {
                console.log('Text copied to clipboard');
                Toast.show("Copy success")
            })
            .catch(err => {
                console.error('Failed to copy text:', err);
            });
    }

    //复制邮箱
    const onHandleEmailCopy = (email) => {
        console.log("复制邮箱:" + email)
        navigator.clipboard.writeText(email)
            .then(() => {
                console.log('Text copied to clipboard');
                Toast.show("Copy success")
            })
            .catch(err => {
                console.error('Failed to copy text:', err);
            });
    }

    //点击privacy policy link
    const onHandleCheckPrivacyPolicyLink = () => {
        console.log("点击privacy policy link:")
        //记录点击事件
        doHitEvent("PRIVACY_POLICY_LINK_CLICK")

        //跳转协议页
        history.push('/privacy')
    }

    //打点接口
    const doHitEvent = async (type, remark = "") => {
        console.log("doHitEvent: " + type + " , " + remark);

        try {
            //获取Action
            const action = saveEvent(type, remark)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //获取app配置信息
    const getAppConfigInfo = () => {
        console.log("获取app配置信息=====")
        setMaskVisible(true)
        try {
            //获取Action
            const action = getAppConfig()

            //触发接口
            dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //监听app配置信息返回
    useEffect(() => {
        console.log("NAVIGATEBAR-待等app配置信息返回" + canShowDrawer)
        if ((appConfigInfo.status === APP_CONFIG_REQ_SUCCESS) && canShowDrawer) {
            console.log(appConfigInfo)
            setCanShowDrawer(false)
            setMaskVisible(false)
            setIsDrawerOpen(true);
        }
    }, [appConfigInfo])

    //启动初始化
    useEffect(() => {
        //缓存用户信息
        const userInfo = getUserInfo()
        //缓存mobile
        const mobile = userInfo.mobile
        //更新显示
        setMobile(mobile)

    }, [])

    //客服列表
    const renderCustomerService = () => {
        return (
            <div className="navCustomerService">

                {/* logo与名称 */}
                <div className='navCustomerServiceApp'>
                    <img className="navCustomerServiceAppBgImage" src={icon_user_center_bg} alt='' />
                    <div className='navCustomerServiceAppLogoContent'>
                        <img className="navCustomerServiceAppLogo" src={icon_app_logo} alt='' />
                        <img className="navCustomerServiceAppLevel" src={icon_member_level_silver} alt='' />
                        <span className='navCustomerServiceAppName'>Loan Salad</span>
                        <span className='navCustomerServiceAppUser'>Hi, {mobile}</span>
                    </div>
                </div>

                {/* 客服信息 */}
                <span className='navCustomerServiceRowLine'></span>
                <div className='navCustomerServiceHeaderRow navMemberCenterRow' onClick={onHandleCoupon}>
                    <img className="navRowIcon" src={icon_member_center} alt='' />
                    <span className="navCustomerServiceRowHeader navMemberCenterText">Member Center</span>
                    <img className="navRowIconSmall" src={icon_member_center_ext} alt='' />
                </div>


                <div className='navCustomerServiceContent'>
                    <div className='navCustomerServiceHeaderRow'>
                        <img className="navRowIcon" src={icon_customer_service} alt='' />
                        <span className="navCustomerServiceRowHeader">Customer service</span>
                    </div>

                    <div className='navCustomerServiceListContent'>
                        {appConfigInfo.mobileList && appConfigInfo.mobileList.map((value, index) => {
                            return (
                                <div className="navCustomerServiceRow" key={index}>
                                    <div className='navCustomerServiceRowLeft'>
                                        <span className='navCustomerServiceRowTitle'>Hotline</span>
                                        <span className='navCustomerServiceRowDetail'>{value}</span>
                                    </div>
                                    <div className='navCustomerServiceRowRight' onClick={() => { onHandlePhoneCopy(value) }}>Copy</div>
                                    <div className='navCustomerServiceSpace'></div>
                                    <div className='navCustomerServiceRowRight' onClick={() => { onHandlePhoneCall(value) }}>Dial</div>
                                </div>
                            )
                        })}
                        {appConfigInfo.emailList && appConfigInfo.emailList.map((value, index) => {
                            return (
                                <div className="navCustomerServiceRow" key={index}>
                                    <div className='navCustomerServiceRowLeft'>
                                        <span className='navCustomerServiceRowTitle'>E-mail</span>
                                        <span className='navCustomerServiceRowDetail'>{value}</span>
                                    </div>
                                    <div className='navCustomerServiceRowRight' onClick={() => { onHandleEmailCopy(value) }}>Copy</div>
                                </div>
                            )
                        })}
                    </div>

                    {/* 添加桌面快捷方式 */}
                    <span className='navCustomerServiceRowLine'></span>
                    <div className='navCustomerServiceHeaderRow' onClick={onHandleAddHome}>
                        <img className="navRowIcon" src={icon_add_home} alt='' />
                        <span className="navCustomerServiceRowHeader">Add website to home screen</span>
                        <img className="navRowIconSmall" src={icon_add_home_theme} alt='' />
                    </div>

                    {/* 接收通知 */}
                    <span className='navCustomerServiceRowLine'></span>
                    <div className='navCustomerServiceHeaderRow' onClick={onHandleAddFavorite}>
                        <img className="navRowIcon" src={icon_add_subscribe} alt='' />
                        <span className="navCustomerServiceRowHeader">Add bookmark</span>
                        <img className="navRowIconSmall" src={icon_add_home_theme} alt='' />
                    </div>

                    {/* 查看隐私协议 */}
                    <span className='navCustomerServiceRowLine'></span>
                    <div className='navCustomerServiceHeaderRow' onClick={onHandleCheckPrivacyPolicyLink}>
                        <img className="navRowIcon" src={icon_privacy_policy} alt='' />
                        <span className="navCustomerServiceRowHeader navCustomerServiceUnderLine">Privacy policy</span>
                    </div>

                    {/* 退出 */}
                    {showLogout && <span className='navCustomerServiceRowLine navCustomerServiceLoginoutTop'></span>}
                    {showLogout &&
                        <div className='navCustomerServiceHeaderRow' onClick={onHandleSignOut}>
                            <img className="navRowIcon" src={icon_signin_out} alt='' />
                            <span className="navCustomerServiceRowHeader">Sign out</span>
                        </div>
                    }
                </div>
            </div>
        )
    }

    let addHomeIcon = icon_add_favorite
    let hotlineIcon = icon_hotline
    let extBgStyle = "navBarThemeWhite"
    let extTextColor = "whiteTextColor"
    if (theme === 'black') {
        extBgStyle = "navBarThemeBlack"
        extTextColor = "whiteTextColor"
        hotlineIcon = icon_hotline_white
        addHomeIcon = icon_add_favorite_white

    } else if (theme === 'white') {
        extBgStyle = "navBarThemeWhite"
        extTextColor = "blackTextColor"
        hotlineIcon = icon_hotline
        addHomeIcon = icon_add_favorite

    } else if (theme === 'transparent') {
        extBgStyle = "navBarThemeTransparent"
        extTextColor = "whiteTextColor"
        hotlineIcon = icon_hotline_white
        addHomeIcon = icon_add_favorite_white
    }

    return (
        <div className={`navBarRoot ${extBgStyle}`}>
            {/* 后退按钮 */}
            <div className="navBarLeft">
                <img className="navBackImage" src={hotlineIcon} onClick={() => { onHandleOpenDrawer() }} alt='' />
            </div>

            {/* 居中标题 */}
            <div className={`navBarTitle ${extTextColor}`}>{children}</div>

            {/* 右侧内容 */}
            <div className="navBarRightRepay">
                <img className="navBackImageCoupon" src={icon_coupon_nav} onClick={() => { onHandleCouponFromBar() }} alt='' />
                <img className="navBackImageRepay" src={icon_refresh} onClick={() => { onHandleRefresh() }} alt='' />
                {/* <img className="navBackImage" src={addHomeIcon} onClick={() => { onHandleAddFavorite() }} alt='' /> */}
            </div>

            {/* 抽屉内容 */}
            <HotlineDrawer isOpen={isDrawerOpen} onClose={onHandleCloseDrawer}>
                {renderCustomerService()}
            </HotlineDrawer>

            <Mask visible={maskVisible} opacity={0.1}
                onMaskClick={() => { }}>
                <div className="overlayContent">
                    <span style={{ fontSize: 24, color: '#ff3141' }} >
                        <DotLoading color="currentColor" />
                    </span>
                </div>
            </Mask>
        </div>
    )
}

export default NavigateBarRepay