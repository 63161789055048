
import http, { baseParams } from "@/utils/request";
import { UrlGetReapyDetail } from "@/utils/apiUrl";
import { isEmptyString } from "@/utils/utils";
import { Toast } from "antd-mobile";
import { setUserNotExist } from "./userNotExist";

//类型：获取账单成功
export const REPAY_BILL_REQ_SUCCESS = "REPAY_BILL_REQ_SUCCESS"

//类型：获取账单失败
export const REPAY_BILL_REQ_FAIL = "REPAY_BILL_REQ_FAIL"

/**
 * 获取还款详情
 * @returns thunk
 */
export const getRepayDetail = () => {
    return async (dispatch) => {
        //组装参数
        const params = baseParams()

        //发起请求
        const res = await http.post(UrlGetReapyDetail, params)
        const resCode = parseInt(res.code)
        const resObj = res.obj
        resObj.currency = "NGN"

        //增加展期标识
        const deferredFlag = resObj.deferredFlag
        resObj.canDeferred = (deferredFlag === "YES")

        console.log("获取还款详情回调-结束：=======")

        //保存
        const success = (resCode === 0)
        dispatch(saveRepayDetailState(resObj, success))

        //如果有错误，提示
        if (!success) {
            if (!isEmptyString(res.msg)) {
                Toast.show(res.msg, 1)
            }
            if (res.msg === "User does not exist") {
                dispatch(setUserNotExist())
            }
        }
    }
}

/**
 * 将 返回状态 信息保存到 Redux 中
 * @param {*} data 
 * @returns 
 */
export const saveRepayDetailState = (data, success) => {
    return {
        type: success ? REPAY_BILL_REQ_SUCCESS : REPAY_BILL_REQ_FAIL,
        payload: data
    }
}
