import React from "react";
import './formmobileinput.css'

export default function FormMobileInput({ title, ...rest }) {
    return (
        <div className="formmobileContent">
            <span className="formmobileTitle">{title}</span>
            <div className="formmobileValueContent">
                <input className="formmobileValue" maxLength={14}  {...rest} clearable="true" />
            </div>
            <div className="formmobileSplitLine" hidden></div>
        </div>
    )
}