import React, { useEffect, useState } from 'react';
import './couponguidedialog.css'
import { saveEvent } from '@/store/actions/saveEvent';
import { useDispatch } from 'react-redux';
import { Mask } from 'antd-mobile';
import icon_coupon_guide_dialog_top from "@/assets/icon_coupon_guide_dialog_top.png"
import icon_coupon_apply_item_bg from "@/assets/icon_coupon_apply_item_bg.png"
import icon_coupon_guide_dialog_close from "@/assets/icon_coupon_guide_dialog_close.png"
import icon_coupon_dialog_select from "@/assets/icon_coupon_dialog_select.png"
import icon_coupon_dialog_unselect from "@/assets/icon_coupon_dialog_unselect.png"
import { CouponGuideMode } from './CouponGuideMode';
import { setNotRemindCoupon } from '@/utils/storage';


export default function CouponGuideDialog({ visible, mode = CouponGuideMode.newNotFree, counponAmount = "300", onConfirm, onClose, onNotRemind }) {

    //获取 Redux 分发器
    const dispatch = useDispatch()

    const [needDisplay, setNeedDisplay] = useState(false);
    const [tipsText, setTipsText] = useState("Apply for loan again and this time you\nwill be able to use a repayment\ndiscount coupon");
    const [confirmText, setConfirmText] = useState("Apply and use now");
    const [notRemindSelect, setNotRemindSelect] = useState(false);
    const [notRemindImage, setNotRemindImage] = useState(icon_coupon_dialog_unselect);

    //监听
    useEffect(() => {
        setNeedDisplay(visible)

        if (mode === CouponGuideMode.oldReLoan) {
            setTipsText("Apply for loan again and this time you\nwill be able to use a repayment\ndiscount coupon")
            setConfirmText("Apply and use now")
        } else if (mode === CouponGuideMode.newNotLoan) {
            setTipsText("You have a repayment disount\ncoupon that you can use on this loan.")
            setConfirmText("Finish apply and use now")
        } else if (mode === CouponGuideMode.newHadFree) {
            setTipsText("Congratulations on getting the\nfollowing benefits")
            setConfirmText("Check")
        } else if (mode === CouponGuideMode.newNotFree) {
            setTipsText("Congratulations on getting the\nfollowing benefits")
            setConfirmText("Check")
        }

    }, [visible])

    //打点接口
    const doHitEvent = async (type, remark = "") => {
        console.log("doHitEvent: " + type + " , " + remark);

        try {
            //获取Action
            const action = saveEvent(type, remark)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //点击监听
    const onHandleDidCheckedClick = () => {
        // 打点
        doHitEvent("COUPON_DIALOG_CHECK_CLICK", mode || "")
        // 回调
        onConfirm && onConfirm()
    }

    //点击监听
    const onHandleCloseClick = () => {
        // 打点
        doHitEvent("COUPON_DIALOG_CLOSE_CLICK", mode || "")
        // 回调
        onClose && onClose()
    }

    // 点击监听
    const onHandleNotRemindGagainClick = () => {
        // 打点
        doHitEvent("COUPON_DIALOG_NOT_REMIND_CLICK", mode || "")

        setNotRemindSelect(!notRemindSelect)
        if (notRemindSelect) {
            setNotRemindImage(icon_coupon_dialog_unselect)
            // 保存缓存
            setNotRemindCoupon("NO")

        } else {
            setNotRemindImage(icon_coupon_dialog_select)
            // 保存缓存
            setNotRemindCoupon("YES")
        }

        // 回调
        onNotRemind && onNotRemind()
    }

    return (
        <Mask visible={needDisplay} opacity={0.6}
            onMaskClick={() => { }}>
            <div className="couponGuideDialogOverlayBg">
                <div className="couponGuideDialogOverlayContent">
                    {/* <img className='couponGuideDialogBgImage' src={icon_coupon_guide_dialog_bg} alt='' /> */}

                    <div className='couponGuideDialogDetailContent'>
                        <div className='couponGuideDialogTitle'>Silver level benefits</div>
                        <div className='couponGuideDialogDetail'>{tipsText}</div>

                        {/* 优惠券模块-free */}
                        {mode !== CouponGuideMode.oldReLoan && <div className="couponGuideDialogBg couponGuideDialogMarginTop12Px">
                            <div className="couponGuideDialogContent">
                                <img className="couponGuideDialogImg" src={icon_coupon_apply_item_bg} alt="" />
                                <div className="couponGuideDialogAmountContent">
                                    <div className='couponGuideDialogFreeContent'>
                                        <div className="couponGuideDialogFreeAmount">0</div>
                                        <div className="couponGuideDialogFreeAmountDes">free for</div>
                                    </div>
                                    <div className="couponGuideDialogTips">Your first loan</div>
                                </div>
                            </div>
                        </div>}

                        {/* 优惠券模块-un free */}
                        <div className="couponGuideDialogBg couponGuideDialogMarginTop12Px">
                            <div className="couponGuideDialogContent">
                                <img className="couponGuideDialogImg" src={icon_coupon_apply_item_bg} alt="" />
                                <div className="couponGuideDialogAmountContent">
                                    <div className="couponGuideDialogTips">Repayment Coupon</div>
                                    <div className="couponGuideDialogAmount">₦{counponAmount}</div>
                                    <div className="couponGuideDialogTips">Discount on repayment</div>
                                </div>
                            </div>
                        </div>

                        {/* 确认模块 */}
                        <div className='couponGuideDialogButtonContent'>
                            <div className='couponGuideDialogButtonDidChecked' onClick={onHandleDidCheckedClick}>{confirmText}</div>
                        </div>

                        {/* 不再提示模块 */}
                        {mode === CouponGuideMode.oldReLoan && <div className='couponGuideDialogNotRemindContent'>
                            <img className='couponGuideDialogNotRemindImage' src={notRemindImage} alt='' onClick={onHandleNotRemindGagainClick} />
                            <div className='couponGuideDialogNotRemindText' onClick={onHandleNotRemindGagainClick}>Don't remind me again</div>
                        </div>}
                    </div>
                    <img className='couponGuideDialogTopImage' src={icon_coupon_guide_dialog_top} alt='' />
                    <img className='couponGuideDialogCloseImage' src={icon_coupon_guide_dialog_close} alt='' onClick={onHandleCloseClick} />
                </div>
            </div>
        </Mask >
    );
};
