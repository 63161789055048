
import { LOAN_DETAIL_BY_AMOUNT_REQ_SUCCESS, LOAN_DETAIL_REQ_CLEAR, LOAN_DETAIL_REQ_FAIL, LOAN_DETAIL_REQ_SUCCESS, LOAN_DETAIL_UPDATE_REQ_SUCCESS } from "../actions/loanDetail"

// 初始状态
const initState = {
}

// 操作 贷款详情 状态信息的 reducer 函数
export const loanDetail = (state = initState, action) => {
    const { type, payload } = action
    //console.log('loan type: ' + type)

    switch (type) {
        case LOAN_DETAIL_REQ_SUCCESS: return { ...payload, status: type }
        case LOAN_DETAIL_UPDATE_REQ_SUCCESS: return { ...payload, status: type }
        case LOAN_DETAIL_BY_AMOUNT_REQ_SUCCESS: return { ...payload, status: type }
        case LOAN_DETAIL_REQ_FAIL: return { status: type }
        case LOAN_DETAIL_REQ_CLEAR: return { status: type }
        default: return state
    }
}