
import http, { baseParams } from "@/utils/request";
import { UrlGetBankCodeList } from "@/utils/apiUrl";
import { isEmptyString } from "@/utils/utils";
import { Toast } from "antd-mobile";

export const BANK_CODE_REQ_KEY = "BANK_CODE_REQ"

//类型：请求成功
export const BANK_CODE_REQ_SUCCESS = "BANK_CODE_REQ_SUCCESS"

//类型：请求失败
export const BANK_CODE_REQ_FAIL = "BANK_CODE_REQ_FAIL"


/**
 * 获取bank code列表
 * @returns thunk
 */
export const getBankCodeList = () => {
    return async (dispatch) => {
        //组装参数
        const params = baseParams()

        //发起请求
        const res = await http.post(UrlGetBankCodeList, params)
        const resCode = parseInt(res.code)
        const resObj = res.obj
        console.log("获取bank code列表回调-结束：=======")

        //保存
        const success = (resCode === 0)
        dispatch(saveBankCodeListState(resObj, success))

        //如果有错误，提示
        if (!success) {
            if (!isEmptyString(res.msg)) {
                Toast.show(res.msg, 1)
            }
        }
    }
}

/**
 * 将 BankCodeList 信息保存到 Redux 中
 * @param {*} apiUrl 
 * @param {*} data 
 * @returns thunk
 */
export const saveBankCodeListState = (data, success) => {
    console.log(data)
    //组装数据[[{title:xxx, value:xxx, label:xxx}]]
    let formatData = []
    let columnData = []
    for (let i = 0; i < data.length; i++) {
        let item = {
            label: data[i].bankName,
            title: data[i].bankName,
            value: data[i].bankCode
        }
        columnData.push(item)
    }
    formatData.push(columnData)

    const resultData = {
        list: formatData
    }

    return {
        type: success ? BANK_CODE_REQ_SUCCESS : BANK_CODE_REQ_FAIL,
        payload: resultData
    }
}
