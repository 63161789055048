
import { LOAN_APPLY_REQ_CLEAR, LOAN_APPLY_REQ_FAIL, LOAN_APPLY_REQ_SUCCESS } from "../actions/loanApply"

// 初始状态
const initState = {
    status: ""
}

// 操作 贷款详情 状态信息的 reducer 函数
export const loanApply = (state = initState, action) => {
    const { type, payload } = action
    //console.log('loan type: ' + type)

    switch (type) {
        case LOAN_APPLY_REQ_SUCCESS: return { ...payload, status: type }
        case LOAN_APPLY_REQ_FAIL: return { status: type }
        case LOAN_APPLY_REQ_CLEAR: return { status: type }
        default: return state
    }
}