import React, { useEffect, useState } from "react";
import '../loan.css'
import '../Apply/apply.css'
import '../../Home/home.css'
import '../../../App.css'
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Mask, DotLoading } from "antd-mobile";
import { useDispatch, useSelector } from "react-redux";
import { REVIEW_USER_STATE_REQ_FAIL, REVIEW_USER_STATE_REQ_SUCCESS, getUserState } from "@/store/actions/userState";
import loan_reviewing from "@/assets/loan_reviewing.png"
import { isLoanLoaning, isLoanReject, isLoanRepayNow, isLoanReviewing, isLoanSigning } from "@/utils/loanStatus";
import { PageTag } from "@/utils/pageTag";
import NavigateBar from "@/components/NavigateBar";
import { saveEvent } from "@/store/actions/saveEvent";
import { updateConfirmApplyState } from "@/store/actions/loanApply";
import icon_close from "@/assets/icon_close.png"
import AddFavorite from "@/components/AddFavorite";
import AddHomeEvent from "@/utils/addHomeEvent";
import AddToHomeGifDialog from "@/components/AddToHomeGifDialog/AddToHomeGifDialog";
import CommendProducts from "@/components/CommendProducts/CommendProducts";
import StarReviewDialog from "@/components/StarReviewDialog/StarReviewDialog";
import { getAppReviewFlag } from "@/utils/storage";
import { isEmptyString } from "@/utils/utils";
import CouponClick from "@/pages/Coupon/CouponClick";

/**
 * 审核中页
 */
export default function Review() {

    //loading状态
    const [maskVisible, setMaskVisible] = useState(false)

    //显示add home弹框
    const [addHomeMaskVisible, setAddHomeMaskVisible] = useState(false);
    const [addHomeMaskIsHomeMode, setAddHomeMaskIsHomeMode] = useState(true);

    //loading状态
    const [whatsappTipsVisible, setWhatsappTipsVisible] = useState(false)

    //显示评价弹框
    const [starReviewMaskVisible, setStarReviewMaskVisible] = useState(false);

    // 获取路由历史 history 对象
    const history = useHistory()

    //获取 Redux 分发器
    const dispatch = useDispatch()

    //获取Redux Store中的用户状态信息
    const userStateInfo = useSelector(state => state.userState)

    //获取Redux Store中的用户状态信息
    // const userNotExistInfo = useSelector(state => state.userNotExist)

    //获取Redux Store中的confirm apply信息
    const loanApplyInfo = useSelector(state => state.loanApply)

    //查询用户流程状态
    const checkUserStateInfo = async () => {
        console.log("REVIEW-checkUserStateInfo: ");

        //获取用户状态
        try {
            //显示loading
            setMaskVisible(true)

            //获取Action
            const action = getUserState(PageTag.REVIEW)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //打点接口
    const doHitEvent = async (type, remark = "") => {
        console.log("doHitEvent: " + type + " , " + remark);

        try {
            //获取Action
            const action = saveEvent(type, remark)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //点击提交
    const onHandleSubmit = () => {
        //查询用户状态
        checkUserStateInfo()
    }

    //点击whatsapp
    const onWhatsappClick = () => {
        //whatsapp://send/?phone=+86123456789&text=Hello
        const phone = "23407050632659"
        const encodedText = encodeURIComponent("I want to get official services");
        let whatsappURL = `whatsapp://send/?phone=${phone}&text=${encodedText}`
        //跳转whatsapp
        window.location.href = whatsappURL
    }

    // 点击add home监听
    const onHandleAddToHome = (type) => {
        console.log(`点击add home监听: ${type}`)
        if (type === 'home') {
            AddHomeEvent.onHandleAddHomeEventClick()
        } else {
            AddHomeEvent.onHandleAddFavoriteEventClick()
        }
    }

    // 关闭add home
    const onHandleAddToHomeClose = () => {
        console.log(`点击close add home监听`)
        setAddHomeMaskVisible(false)
    }

    //关闭 star review dialog
    const onHandleStarReviewDialogClose = () => {
        console.log(`点击关闭 star review dialog监听`)
        setStarReviewMaskVisible(false)
    }

    // 刷新操作
    const onHandleRefresh = () => {
        // 查询用户流程状态
        checkUserStateInfo()
    }

    //启动监听
    useEffect(() => {
        //添加 fb 像素
        // eslint-disable-next-line no-undef
        fbq('track', 'Page_Review');
        // eslint-disable-next-line no-undef
        gtag('track', 'Page_Review');
        
        // 优惠券入口
        CouponClick.fromParentPage = PageTag.REVIEW

        //打点
        doHitEvent("REVIEW_START")

        //更新状态
        dispatch(updateConfirmApplyState(loanApplyInfo, true))

        //更新评价弹框状态
        const appReviewFlag = getAppReviewFlag()
        console.log("===== appReviewFlag: " + appReviewFlag)
        if (isEmptyString(appReviewFlag)) {
            setStarReviewMaskVisible(true)
        }

        return () => {
            //打点
            doHitEvent("REVIEW_END")
        }
    }, [])

    // 查询用户流程状态返回监听
    useEffect(() => {
        try {

            console.log("LOANING-查询用户流程状态返回监听")
            console.log(userStateInfo)

            if (userStateInfo.status === REVIEW_USER_STATE_REQ_SUCCESS) {
                //隐藏loading
                setMaskVisible(false)

                console.log("REVIEW-再次查询user state:")
                console.log("REVIEW-applyStatus: " + userStateInfo.applyStatus)
                console.log("REVIEW-orderStatus: " + userStateInfo.orderStatus)
                console.log("REVIEW-signFlag: " + userStateInfo.signFlag)
                console.log("Launch-signOrderId: " + userStateInfo.signOrderId)
    
                //是否为签约中
                const isHomeSigning = isLoanSigning(userStateInfo.signFlag, userStateInfo.signOrderId)
                //是否为审核中
                const isHomeReviewing = isLoanReviewing(userStateInfo.applyStatus)
                //是否为拒绝
                const isHomeReject = isLoanReject(userStateInfo.applyStatus, userStateInfo.orderStatus)
                //是否为放款中
                const isHomeLoaning = isLoanLoaning(userStateInfo.orderStatus)
                //是否为待还款
                const isHomeRepay = isLoanRepayNow(userStateInfo.orderStatus)
                //是否为待进件
                const isHomeNotLoan = ((!isHomeReviewing) && (!isHomeReject) && (!isHomeLoaning) && (!isHomeRepay))
                console.log("REVIEW-isHomeReviewing: " + isHomeReviewing)
                console.log("REVIEW-isHomeReject: " + isHomeReject)
                console.log("REVIEW-isHomeLoaning: " + isHomeLoaning)
                console.log("REVIEW-isHomeRepay: " + isHomeRepay)
                console.log("REVIEW-isHomeNotLoan: " + isHomeNotLoan)
                console.log("REVIEW-isHomeSigning: " + isHomeSigning)

                //页面转发
                setTimeout(() => {
                    if (isHomeSigning) {
                        history.replace('/guide')
                    } else if (isHomeReviewing) {

                    } else if (isHomeReject) {
                        history.replace('/reject')
                    } else if (isHomeLoaning) {
                        history.replace('/loaning')
                    } else if (isHomeRepay) {
                        history.replace('/repay')
                    } else {
                        history.replace('/home')
                    }
                }, 500)

            } else if (userStateInfo.status === REVIEW_USER_STATE_REQ_FAIL) {
                //隐藏loading
                setMaskVisible(false)
            }
        } catch (e) {
            console.log(e)
        }
    }, [userStateInfo])

    //监听用户是否存在
    // useEffect(() => {
    //     if (userNotExistInfo.notExist === true) {
    //         //history.replace('/login')
    //         history.replace('/loginfb')
    //     }
    // }, [userNotExistInfo])

    return (
        <div className="loanRoot mainBackgroundWhite">
            {/* <NavBar back={null} onBack={() => { history.goBack() }} style={{ 'width': '100%', 'backgroundColor': 'white' }}>Reviewing</NavBar> */}
            <NavigateBar theme="white" onAddFavorite={() => { setAddHomeMaskIsHomeMode(false); setAddHomeMaskVisible(true) }} onAddHome={() => { setAddHomeMaskIsHomeMode(true); setAddHomeMaskVisible(true) }} onRefresh={onHandleRefresh}>Loan Salad</NavigateBar>
            {/* 使用添加 home screen bar */}
            {/* <AddFavorite onAddHome={() => { setAddHomeMaskIsHomeMode(true); setAddHomeMaskVisible(true) }} /> */}

            <div hidden className="loanContent">
                <div className="statusImageContent">
                    <img
                        className="statusImage"
                        src={loan_reviewing}
                        alt="" />
                </div>

                <span className="statusTitle">Your application is under review</span>

                <span className="statusTips">it may take a few minutes.</span>

                {/* 点击确定 */}
                <div className="statusRefreshButtonContent" onClick={onHandleSubmit}>
                    <div className="mainButtonGradient">Refresh status</div>
                </div>

                {/* 推荐的产品列表 */}
                {/* <CommendProducts /> */}

            </div>

            {/* 评价弹框 */}
            <StarReviewDialog visible={starReviewMaskVisible} onClose={onHandleStarReviewDialogClose} />

            {/* load mask https://mobile.ant.design/zh/components/mask/ */}
            <Mask visible={whatsappTipsVisible} opacity={0.1}
                onMaskClick={() => { }}>
                <div className="applyOverlayBg">
                    <div className="applyOverlayContent">
                        <div className="applyOverlayContentIconClose" onClick={() => { setWhatsappTipsVisible(false) }}>
                            <img className="applyOverlayIconClose" src={icon_close} alt="" />
                        </div>
                        <div className="applyOverlayContentHeaderTitle">Hello,</div>
                        <div className="applyOverlayContentRowTitle">Enter whats'app business account to get more services.</div>

                        {/* 首页点击申请 */}
                        <div className="homeApplyBtn" onClick={onWhatsappClick}>
                            <div className="mainButtonGradient">Let's Go</div>
                        </div>
                    </div>
                </div>
            </Mask>

            {/* add home gif dialog */}
            <AddToHomeGifDialog visible={addHomeMaskVisible} onConfirm={onHandleAddToHome} onClose={onHandleAddToHomeClose} isHomeMode={addHomeMaskIsHomeMode} />

            <Mask visible={maskVisible} opacity={0.1}
                onMaskClick={() => { }}>
                <div className="overlayContent">
                    <span style={{ fontSize: 24, color: '#ff3141' }} >
                        <DotLoading color="currentColor" />
                    </span>
                </div>
            </Mask>
        </div >
    )
}