import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN_USER_STATE_REQ_SUCCESS, cleanUserState, getUserState } from "@/store/actions/userState";
import NavigateBar from "@/components/NavigateBar";
import icon_login_refuse from "@/assets/icon_login_refuse.png"
import { DotLoading, Mask, Slider } from "antd-mobile";
import { PageTag } from "@/utils/pageTag";
import { saveEvent } from "@/store/actions/saveEvent";
import { useFormik } from "formik";
import './loginFree.css';
import '../../App.css'
import '../Home/home.css'
import '../Proof/proof.css';
import AddToHomeGifDialog from "@/components/AddToHomeGifDialog/AddToHomeGifDialog";
import AddHomeEvent from "@/utils/addHomeEvent";
// import AddFavorite from "@/components/AddFavorite";
import { LOGIN_REQ_FAIL, LOGIN_REQ_SUCCESS, cleanLoginState, doLogin } from "@/store/actions/login";
import { SEND_OTP_REQ_FAIL, SEND_OTP_REQ_SUCCESS, cleanOtpState, sendOtp } from "@/store/actions/sendOtp";
import { removeUserInfo } from "@/utils/storage";
import { isLoanLoaning, isLoanReject, isLoanRepayNow, isLoanReviewing } from "@/utils/loanStatus";
import OtpInput from "@/components/OtpInput";
import Input from "@/components/Input";
import SmartChat from "@/utils/smartChat";
// import PrivacyPolicyLink from "@/components/PrivacyPolicyLink/PrivacyPolicyLink";
// import AppCallback from "@/utils/appCallback";
import icon_login_first_free from "@/assets/icon_login_first_free.png"
import icon_login_not_free from "@/assets/icon_login_not_free.png"
import icon_login_pig_notify from "@/assets/icon_login_pig_notify.png"
import icon_coupon_content_bg from "@/assets/icon_coupon_content_bg.png"
import icon_coupon_content_top_bg from "@/assets/icon_coupon_content_top_bg.png"
import icon_coupon_item_bg from "@/assets/icon_coupon_item_bg.png"
import icon_coupon_discount_mark from "@/assets/icon_coupon_discount_mark.png"
import icon_coupon_login_mark from "@/assets/icon_coupon_login_mark.png"
import icon_login_hint_phone from "@/assets/icon_login_hint_phone.png"
import icon_login_hint_otp from "@/assets/icon_login_hint_otp.png"
import { APP_CONFIG_REQ_SUCCESS, getAppConfig } from "@/store/actions/appConfig";




/**
 * 登录页-样式1
 */
export default function LoginCoupon() {

    //loading状态
    const [maskVisible, setMaskVisible] = useState(false)

    //显示add loginFree弹框
    const [addHomeMaskVisible, setAddHomeMaskVisible] = useState(false);
    const [addHomeMaskIsHomeMode, setAddHomeMaskIsHomeMode] = useState(false);

    //倒计时状态
    const [canStart, setCanStart] = useState(false);

    //需要转登录状态
    const [needToLoginPage, setNeedToLoginPage] = useState(false);

    //需要转注册状态
    const [needToRegisterPage, setNeedToRegisterPage] = useState(false);

    //按键文字
    const [isRegitsterType, setIsRegitsterType] = useState(false);

    //优惠券图标
    const [couponFlagIcon, setCouponFlagIcon] = useState(icon_login_not_free);

    //获取Redux Store中的信息
    const sendOtpInfo = useSelector(state => state.sendOtp)

    //获取Redux Store中的信息
    const loginInfo = useSelector(state => state.login)

    //获取Redux Store中的用户状态信息
    const userStateInfo = useSelector(state => state.userState)

    //获取Redux Store中的app配置状态信息
    const appConfigInfo = useSelector(state => state.appConfig)

    //router
    const history = useHistory()

    const location = useLocation()

    // 获取 Redux 分发器
    const dispatch = useDispatch()

    //清空登录信息
    useEffect(() => {
        console.log("LOGIN-移除登录信息")
        //移除登录信息
        removeUserInfo()

        //清除登录信息
        dispatch(cleanLoginState())
        dispatch(cleanUserState())
        dispatch(cleanOtpState())

        //来源
        const dreferrer = document.referrer || ""
        console.log("document.referrer:==========" + dreferrer)

        //来源参数
        const queryParams = new URLSearchParams(location.search);
        const isRegister = queryParams.get('isRegister') || ""
        if (isRegister === "YES") {
            setIsRegitsterType(true)
        } else {
            setIsRegitsterType(false)
        }

        // 获取配置信息
        getAppConfigInfo()

        //打点
        doHitEvent("LOGIN_START")

        return () => {
            //打点
            doHitEvent("LOGIN_END")
        }
    }, [])

    //监听验证码接口返回
    useEffect(() => {
        console.log("Login-监听验证码返回")
        console.log(sendOtpInfo)
        //获取短信发送成功返回
        if (sendOtpInfo.status === SEND_OTP_REQ_SUCCESS) {
            console.log("Login-短信发送成功")
            console.log(sendOtpInfo)
            //关闭loading
            setMaskVisible(false)

            //开始倒计时
            setCanStart(true)

            const directLoginFlag = sendOtpInfo.directLoginFlag || ""
            if (directLoginFlag === "YES") {
                //验证手机号
                let mobile = form.values.mobile

                //验证验证码
                let code = "0000"

                //请求登录
                doOtpLogin(mobile, code, "YES")
            }

        } else if (sendOtpInfo.status === SEND_OTP_REQ_FAIL) {
            //关闭loading
            setMaskVisible(false)

            //判断弹框----暂时关闭
            const otpResCode = sendOtpInfo.resCode || -1
            if (otpResCode === 2001) {
                //需要弹提示--你是新客，需要注册
                setNeedToRegisterPage(true)
                setNeedToLoginPage(false)

            } else if (otpResCode === 2002) {
                //需要弹提示--你是旧客，需要登录
                setNeedToRegisterPage(false)
                setNeedToLoginPage(true)
            }
        }
    }, [sendOtpInfo])

    //监听登录接口返回
    useEffect(() => {
        //获取短信发送成功返回
        if (loginInfo.status === LOGIN_REQ_SUCCESS) {
            console.log("Login-登录成功")
            console.log(loginInfo)
            //关闭loading
            setMaskVisible(false)

            // 设置salesmartly
            let mobile = form.values.mobile
            SmartChat.saveUserInfo(mobile);

            //查询用户状态
            getUserStateInfo()

        } else if (loginInfo.status === LOGIN_REQ_FAIL) {
            //关闭loading
            setMaskVisible(false)
        }
    }, [loginInfo])

    useEffect(() => {
        console.log("LOGIN-待等用户流程信息返回")
        console.log(userStateInfo)
        if (userStateInfo.status === LOGIN_USER_STATE_REQ_SUCCESS) {
            //关闭loading
            setMaskVisible(false)

            console.log("LOGIN-再次查询user state:")
            console.log("LOGIN-applyStatus: " + userStateInfo.applyStatus)
            console.log("LOGIN-orderStatus: " + userStateInfo.orderStatus)

            //是否为审核中
            const isHomeReviewing = isLoanReviewing(userStateInfo.applyStatus)
            //是否为拒绝
            const isHomeReject = isLoanReject(userStateInfo.applyStatus, userStateInfo.orderStatus)
            //是否为放款中
            const isHomeLoaning = isLoanLoaning(userStateInfo.orderStatus)
            //是否为待还款
            const isHomeRepay = isLoanRepayNow(userStateInfo.orderStatus)
            //是否为待进件
            const isHomeNotLoan = ((!isHomeReviewing) && (!isHomeReject) && (!isHomeLoaning) && (!isHomeRepay))
            console.log("LOGIN-isHomeReviewing: " + isHomeReviewing)
            console.log("LOGIN-isHomeReject: " + isHomeReject)
            console.log("LOGIN-isHomeLoaning: " + isHomeLoaning)
            console.log("LOGIN-isHomeRepay: " + isHomeRepay)
            console.log("LOGIN-isHomeNotLoan: " + isHomeNotLoan)

            //页面转发
            setTimeout(() => {
                if (isHomeReviewing) {
                    history.replace('/review')
                } else if (isHomeReject) {
                    history.replace('/reject')
                } else if (isHomeLoaning) {
                    history.replace('/loaning')
                } else if (isHomeRepay) {
                    history.replace('/repay')
                } else {
                    history.replace('/home')
                }
            }, 500)
        } else if (userStateInfo.status === LOGIN_USER_STATE_REQ_SUCCESS) {
            //关闭loading
            setMaskVisible(false)
        }
    }, [userStateInfo])

    useEffect(() => {
        console.log("LOGIN-待等app配置信息返回")
        console.log(appConfigInfo)

        //关闭loading
        setMaskVisible(false)

        if (appConfigInfo.status === APP_CONFIG_REQ_SUCCESS) {
            if (appConfigInfo.customize && appConfigInfo.customize.userCoupon === "YES") {
                setCouponFlagIcon(icon_login_first_free)
            } else {
                setCouponFlagIcon(icon_login_not_free)
            }
        }
    }, [appConfigInfo])

    // Formik 表单对象
    const form = useFormik({
        //设置表单初始值
        initialValues: {
            mobile: '',
            code: '',
            agreePrivacy: true
        },

        //提交
        onSubmit: values => {
            console.log(values)

            //提交登录
            onLoginSubmit()
        },

        //校验
        validate(values) {
            const errors = {}
            if (!values.mobile) {
                errors.mobile = "phone number can't be none"
            }
            if (!values.code) {
                errors.code = "otp can't be none"
            }
            if (!values.agreePrivacy) {
                errors.privacy = "read and agree pricayc policy"
            }

            return errors
        }
    })

    //获取用户流程信息
    const getUserStateInfo = () => {
        console.log("LOGIN-获取用户流程信息=====")
        try {
            //显示loading
            setMaskVisible(true)

            //获取Action
            const action = getUserState(PageTag.LOGIN)

            //触发接口
            dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //获取app配置信息
    const getAppConfigInfo = () => {
        console.log("获取app配置信息=====")
        try {
            // 显示loading
            setMaskVisible(true)

            //获取Action
            const action = getAppConfig()

            //触发接口
            dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //请求短信接口
    const doSendOtp = async (mobile) => {
        console.log("doSendOtp: " + mobile);
        try {
            //显示loading
            setMaskVisible(true)

            //获取Action -- 暂时关闭
            // const pageType = isRegitsterType ? "REGPAGE" : "LOGINPAGE"
            const pageType = ""
            const action = sendOtp(mobile, pageType)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //请求登录接口
    const doOtpLogin = async (mobile, code, directLoginFlag = "NO") => {
        console.log("doLogin: " + mobile + " , " + code);

        try {
            //显示loading
            setMaskVisible(true)

            //获取Action
            const action = doLogin(mobile, code, directLoginFlag)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //打点接口
    const doHitEvent = async (type, remark = "") => {
        console.log("doHitEvent: " + type + " , " + remark);

        try {
            //获取Action
            const action = saveEvent(type, remark)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //点击获取otp
    const onSendSmsCode = async () => {
        //验证是否在倒计时中
        //打点
        doHitEvent("LOGIN_OTP_CLICK")

        //验证手机号
        let mobile = form.values.mobile
        if (mobile.length < 9) {
            form.setTouched({ mobile: true })
            return
        }

        //请求发送短信
        doSendOtp(mobile)
    }

    //点击登录
    const onLoginSubmit = async () => {
        try {
            //测试回调app
            //回调给app
            //AppCallback.getJsCookies()

            //打点
            doHitEvent("LOGIN_COMMIT_CLICK")

            //验证手机号
            let mobile = form.values.mobile

            //验证验证码
            let code = form.values.code

            //请求登录
            doOtpLogin(mobile, code, "NO")

        } catch (e) {
            console.log(e)
        }
    }

    // 转登录弹框--点击确定
    const onHandleToLoginPageMaskConfirm = () => {
        setNeedToRegisterPage(false)
        setNeedToLoginPage(false)
        history.goBack()
    }

    // 转注册弹框--点击确定
    const onHandleToRegisterPageMaskConfirm = () => {
        setNeedToRegisterPage(false)
        setNeedToLoginPage(false)
        history.goBack()
    }

    // 点击add loginFree监听
    const onHandleAddToHome = (type) => {
        console.log(`点击add loginFree监听: ${type}`)
        if (type === 'home') {
            AddHomeEvent.onHandleAddHomeEventClick()
        } else {
            AddHomeEvent.onHandleAddFavoriteEventClick()
        }
    }

    // 关闭add loginFree
    const onHandleAddToHomeClose = () => {
        console.log(`点击close add loginFree监听`)
        setAddHomeMaskVisible(false)
    }

    return (
        <div className="loginFreeRoot">
            <div className="loginFreeRootTopContent">
                {/* 顶部背景 */}
                <div className="loginFreeTopBackground"> </div>

                <NavigateBar theme="coupon" showLogout={false} onAddFavorite={() => { setAddHomeMaskIsHomeMode(false); setAddHomeMaskVisible(true) }} onAddHome={() => { setAddHomeMaskIsHomeMode(true); setAddHomeMaskVisible(true) }}>Loan Salad</NavigateBar>

                {/* 使用添加 loginFree screen bar */}
                {/* <AddFavorite theme="transparent" onAddHome={() => { setAddHomeMaskIsHomeMode(true); setAddHomeMaskVisible(true) }} /> */}

                <div className="loginFreeTopBannerContent">
                    <img className="loginFreeTopBannerImage1" src={couponFlagIcon} alt="" />
                    <img className="loginFreeTopBannerImage2" src={icon_login_pig_notify} alt="" />
                </div>

                <div className="loginFreeTopCouponContent">
                    <img className="loginFreeTopCouponBgImg" src={icon_coupon_content_bg} alt="" />
                    <div className="loginFreeTopCouponBgDetail">
                        <div className="loginFreeTopCouponTopCenterContent">
                            <img className="loginFreeTopCouponTopCenterImg" src={icon_coupon_content_top_bg} alt="" />
                            <div className="loginFreeTopCouponTopCenterTitle">Welfare for newcomers</div>
                        </div>
                        <div className="loginFreeTopCouponDiscountContent loginFreeMarginTop12Px">
                            <img className="loginFreeTopCouponDiscountImg" src={icon_coupon_item_bg} alt="" />
                            <div className="loginFreeTopCouponDiscountTitle loginFreeMarginTopR5Px">Sign up and get</div>
                            <div className="loginFreeTopCouponDiscountTitle">discount coupons</div>
                        </div>
                        <div className="loginFreeTopCouponDiscountContent">
                            <img className="loginFreeTopCouponDiscountImg" src={icon_coupon_item_bg} alt="" />
                            <div className="loginFreeTopCouponDiscountTitle loginFreeMarginTopR5Px">Sign up and get</div>
                            <div className="loginFreeTopCouponDiscountAmount">108,000 NGN</div>
                        </div>
                    </div>
                    <img className="loginFreeTopCouponTopLeftImg" src={icon_coupon_discount_mark} alt="" />
                </div>

                <div className="loginFreeLoginContent">
                    <img className="loginFreeLoginCouponBgImg" src={icon_coupon_content_bg} alt="" />
                    <div className="loginFreeTopCouponBgDetail">
                        <div className="loginFreeTopCouponTopCenterContent">
                            <img className="loginFreeTopCouponTopCenterImg" src={icon_coupon_content_top_bg} alt="" />
                            <div className="loginFreeTopCouponTopCenterTitle">Sign up to apply for your loan</div>
                        </div>
                        <form className="pageContentColumn loginFreeMarginTop24Px loginFreePaddingHor24Px" onSubmit={form.handleSubmit}>
                            {/* 手机号码输入框 */}
                            <div className="loginFreeVerifyInput">
                                <Input
                                    isFreeMode={true}
                                    hintImg={icon_login_hint_phone}
                                    title="Enter your mobile number"
                                    name="mobile"
                                    placeholder="Enter"
                                    type="text"
                                    value={form.values.mobile}
                                    onBlur={form.handleBlur}
                                    onChange={form.handleChange}
                                    autoComplete="off" />
                            </div>
                            {form.errors.mobile && form.touched.mobile && (
                                <div className="validate">{form.errors.mobile}</div>
                            )}

                            <div className="loginFreeVerifyInput loginFreeMarginTop24Px">
                                {/* 验证码输入框 */}
                                <OtpInput
                                    isFreeMode={true}
                                    hintImg={icon_login_hint_otp}
                                    title="Please enter the verification code"
                                    name="code"
                                    placeholder="Enter"
                                    maxLength={6}
                                    type="text"
                                    value={form.values.code}
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                    onClickCallback={onSendSmsCode}
                                    canStart={canStart}
                                    autoComplete="off" />
                            </div>
                            {form.errors.code && form.touched.code && (
                                <div className="validate">{form.errors.code}</div>
                            )}
                        </form>

                        {/* 登录提交 */}
                        <div className="loginFreeSubmitContent">
                            <div className="loginFreeSubmit" onClick={form.handleSubmit}>Sign up</div>
                            {/* <div className={classNames('loginFreeSubmit', (form.isValid && form.dirty) ? '' : 'loginFreeSubmitBan disabled')} onClick={form.handleSubmit} disabled={true}>Sign up</div> */}
                        </div>

                        {/* privacy policy */}
                        {/* <PrivacyPolicyLink pageTag={"LOGIN"} showCheckbox={false} linkText="Privacy policy" /> */}
                    </div>
                    <img className="loginFreeTopCouponTopLeftImg" src={icon_coupon_login_mark} alt="" />
                </div>
            </div>

            {/* add loginFree gif dialog */}
            <AddToHomeGifDialog visible={addHomeMaskVisible} onConfirm={onHandleAddToHome} onClose={onHandleAddToHomeClose} isHomeMode={addHomeMaskIsHomeMode} />


            {/* 转登录页弹框 */}
            <Mask visible={needToLoginPage} opacity={0.1}
                onMaskClick={() => { }}>
                <div className="applyOverlayBg">
                    <div className="applyOverlayContent">
                        <div className="applyOverlayContentIconCenter" onClick={onHandleToLoginPageMaskConfirm}>
                            <img className="applyOverlayIconCenter" src={icon_login_refuse} alt="" />
                        </div>

                        <div className="applyOverlayContentRowTitle">It is detected that you have already registered an account.</div>
                        <div className="applyOverlayContentRowTitle">Please login to your account through the login portal.</div>

                        <div className="homeApplyBtnLarge loginTopEdge" onClick={onHandleToLoginPageMaskConfirm}>
                            <div className="mainButtonGradient">OK</div>
                        </div>
                    </div>
                </div>
            </Mask>

            {/* 转注册页弹框 */}
            <Mask visible={needToRegisterPage} opacity={0.1}
                onMaskClick={() => { }}>
                <div className="applyOverlayBg">
                    <div className="applyOverlayContent">
                        <div className="applyOverlayContentIconCenter" onClick={onHandleToRegisterPageMaskConfirm}>
                            <img className="applyOverlayIconCenter" src={icon_login_refuse} alt="" />
                        </div>

                        <div className="applyOverlayContentRowTitle">No account has been registered with this identity information.</div>
                        <div className="applyOverlayContentRowTitle">Please change your identity to log in or register a new account.</div>

                        <div className="homeApplyBtnLarge loginTopEdge" onClick={onHandleToRegisterPageMaskConfirm}>
                            <div className="mainButtonGradient">OK</div>
                        </div>
                    </div>
                </div>
            </Mask>

            <Mask visible={maskVisible} opacity={0.1}
                onMaskClick={() => { }}>
                <div className="overlayContent">
                    <span style={{ fontSize: 24, color: '#ff3141' }} >
                        <DotLoading color="currentColor" />
                    </span>
                </div>
            </Mask>

            {/* {linkUrl &&
                <iframe
                    id="linkFrame"
                    src={linkUrl}
                    width="800"
                    height="600"
                    title="Link"
                    onLoad={onHandleUserLink}
                ></iframe>} */}
        </div>
    )
}