/** 申请金额-额度列表 */
export const AmountRange = {

    /**
    * rank1
    */
    rank1: "3000",

    /**
     * rank2
     */
    rank2: "6000",

    /**
     * rank3
     */
    rank3: "9000"
}

