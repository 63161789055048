

/**
 * 判断对象是否为字符串
 * @param {Object} obj
 */
export const isString = obj => {
    return (typeof obj === "string")
}

/**
 * 判断对象是否为数组
 * @param {Object} obj
 */
export const isArray = obj => {
    return (Array.isArray(obj))
}

/**
 * 判断对象是否为空
 * @param {Object} obj
 */
export const isEmptyObject = obj => {
    for (let pro in obj) { return false }
    return true
}

/**
 * 判断对象是否为null
 * @param {Object} obj
 */
export const isNullObject = obj => {
    return !obj
}

/**
 * 判断字符串是否为空
 * @param {String} obj
 */
export const isEmptyString = obj => {
    if (isNullObject(obj)) {
        return true
    }
    if (!isString(obj)) {
        return true
    }
    if (obj.length <= 0) {
        return true
    }
    return false
}

/**
 * 判断字符串是否为必填
 * @param {String} obj
 */
export const isMustProofString = obj => {
    if (isNullObject(obj)) {
        return true
    }
    if (!isString(obj)) {
        return true
    }
    if (obj.length <= 0) {
        return true
    }
    if (obj === "YES") {
        return true
    }
    return false
}

/**
 * 判断状态是否为成功， 以 SUCCESS 结尾
 * @param {String} obj
 */
export const isStatusSuccess = obj => {
    if (!isString(obj)) {
        return false
    }
    const flag = "SUCCESS"
    const temp = obj + ""
    return (temp.endsWith(flag))
}

/**
 * 返回安全字符串
 * @param {String} obj
 */
export const toSafeString = obj => {
    if (isNullObject(obj)) {
        return ""
    }
    return obj
}

/**
 * 返回字符串列表，以指定格式分割
 * @param {String} obj
 */
export const toList = (obj, split) => {
    if (isNullObject(obj)) {
        return []
    }
    const splitFormat = isEmptyString(split) ? ":" : split
    return obj.split(splitFormat)
}

/**
 * 返回百分比字符串
 * @param {String} obj
 */
export const toPercentageString = (obj, fixed = 0) => {
    if (isNullObject(obj)) {
        return ""
    }
    const floatNumber = parseFloat(obj);
    const percentage = (floatNumber * 100).toFixed(fixed);
    return (percentage + "%")
}

/**
 * 返回去掉首尾空串的字符串
 * @param {String} obj
 */
export const toTrimString = (obj) => {
    if (isString(obj)) {
        const trimStr = obj.replace(/^\s+|\s+$/g, "");
        return trimStr
    }
    return obj
}

/**
 * 返回标签类型字符串
 * @param {String} obj
 */
export const toEventTagString = (obj) => {
    if (isString(obj)) {
        const trimStr = toTrimString(obj);
        const noSpaceStr = trimStr.replace(" ", "_").toUpperCase();
        return noSpaceStr
    }
    return obj
}

/**
 * 返回当前系统类型
 * @returns {String} 系统类型
 */
export const checkOSType = () => {
    const userAgent = navigator.userAgent.toLowerCase()
    console.log("userAgent: " + userAgent)

    if (userAgent.indexOf("android") !== -1) {
        // 这是Android设备
        return "ANDROID"

    } else if ((userAgent.indexOf("iphone") !== -1) ||
        (userAgent.indexOf("ipad") !== -1) ||
        (userAgent.indexOf("ipod") !== -1) ||
        (userAgent.indexOf("ios") !== -1)) {
        // 这是iOS设备
        return "IOS"

    } else {
        // 这不是Android也不是iOS设备，可能是其他设备或桌面浏览器
        return "OTHER"
    }

    //如何正则

    // if (/(android|Android)/.test(userAgent)) {
    //     // 这是Android设备
    //     return "ANDROID"
    // } else if (/(iPhone|iPad|iPod|iOS)/.test(userAgent)) {
    //     // 这是iOS设备
    //     return "IOS"

    // } else {
    //     // 这不是Android也不是iOS设备，可能是其他设备或桌面浏览器
    //     return "OTHER"
    // }
}
