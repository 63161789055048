import React, { useEffect, useState } from 'react';
import './signcontractaskdialog.css'
import { saveEvent } from '@/store/actions/saveEvent';
import { useDispatch } from 'react-redux';
import { Mask, Toast } from 'antd-mobile';
import icon_order from "@/assets/icon_order.png"
import icon_order_copy from "@/assets/icon_order_copy.png"
import { sendCopyFailSms } from '@/store/actions/sendCopyFailSms';


export default function SignContractAskDialog({ visible, curOrderId, onDidSign, onNotSign, onOrderIdCopy, onClose }) {

    //获取 Redux 分发器
    const dispatch = useDispatch()

    const [needDisplay, setNeedDisplay] = useState(false);

    //监听
    useEffect(() => {
        setNeedDisplay(visible)
    }, [visible])

    //打点接口
    const doHitEvent = async (type, remark = "") => {
        console.log("doHitEvent: " + type + " , " + remark);

        try {
            //获取Action
            const action = saveEvent(type, remark)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    // 发送失败短信
    const toSendCopyFailSms = async () => {
        console.log("GUIDE-setCopyFailSms: ");

        //获取用户状态
        try {
            //显示loading
            //setMaskVisible(true)

            //获取Action
            const action = sendCopyFailSms()

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //点击监听
    const onHandleDidSignClick = () => {
        // 打点
        doHitEvent("GUIDE_DIALOG_DID_SIGN_CLICK", "")
        // 回调
        onDidSign && onDidSign()
    }

    //点击监听
    const onHandleNotSignClick = () => {
        // 打点
        doHitEvent("GUIDE_DIALOG_NOT_SIGN_CLICK", "")
        // 回调
        onNotSign && onNotSign()
    }

    // 复制订单号
    const onHandleOrderIdCopy = () => {
        //打点
        doHitEvent("GUIDE_DIALOG_ORDER_COPY", "")

        if (curOrderId && curOrderId.length > 0) {
            const preFlag = "contractsuite="
            const fullText = preFlag + curOrderId
            console.log("==== 订单号: " + fullText)

            if (navigator.clipboard && navigator.permissions) {
                navigator.clipboard.writeText(fullText)
                    .then(function () {
                        console.log('Successfully!');
                        Toast.show('The order number has been copied to the clipboard.')
                    })
                    .catch(function (err) {
                        // console.error('Unable to copy content to clipboard', err);
                        // Toast.show('Unable to copy content to clipboard')

                        try {
                            const textArea = document.createElement('textArea')
                            textArea.value = fullText
                            textArea.style.width = 0
                            textArea.style.position = 'fixed'
                            textArea.style.left = '-999px'
                            textArea.style.top = '10px'
                            textArea.setAttribute('readonly', 'readonly')
                            document.body.appendChild(textArea)
                            textArea.focus()
                            textArea.select()
                            document.execCommand('copy')
                            document.body.removeChild(textArea)

                            console.log('Successfully!');
                            Toast.show('The order number has been copied to the clipboard.')
                        }
                        catch (error) {
                            //打点
                            if (err) {
                                doHitEvent("CLIPBOARD_ERR", err.message || "")
                            } else {
                                doHitEvent("CLIPBOARD_ERR", "err is null")
                            }

                            // 发送失败短信
                            toSendCopyFailSms()

                            console.error('Unable to copy content to clipboard', error);
                            Toast.show('Unable to copy content to clipboard')
                        }
                    });
            } else {
                try {
                    const textArea = document.createElement('textArea')
                    textArea.value = fullText
                    textArea.style.width = 0
                    textArea.style.position = 'fixed'
                    textArea.style.left = '-999px'
                    textArea.style.top = '10px'
                    textArea.setAttribute('readonly', 'readonly')
                    document.body.appendChild(textArea)
                    textArea.focus()
                    textArea.select()
                    document.execCommand('copy')
                    document.body.removeChild(textArea)

                    console.log('Successfully!');
                    Toast.show('The order number has been copied to the clipboard.')
                }
                catch (error) {
                    //打点
                    if (error) {
                        doHitEvent("CLIPBOARD_ERR", error.toString() || "")
                    } else {
                        doHitEvent("CLIPBOARD_ERR", "err is null")
                    }

                    // 发送失败短信
                    toSendCopyFailSms()

                    console.error('Unable to copy content to clipboard', error);
                    Toast.show('Unable to copy content to clipboard')
                }
            }
        } else {
            Toast.show("Order id is null")
        }
    }

    return (
        <Mask visible={needDisplay} opacity={0.6}
            onMaskClick={() => { }}>
            <div className="signContractAskDialogOverlayBg">
                <div className="signContractAskDialogOverlayContent">
                    <div className='signContractAskDialogTitle'>You may be looking for the order number?</div>

                    <div className="signContractAskDialogContent" onClick={() => { onHandleOrderIdCopy() }}>
                        <img className="signContractAskDialogImg" src={icon_order} alt="" />
                        <div className="signContractAskDialogNumberContent">
                            <div className="signContractAskDialogNumber">Order number:</div>
                            <div className="signContractAskDialogSpace"></div>
                            <div className="signContractAskDialogNumber">{curOrderId}</div>
                        </div>
                        <img className="signContractAskDialogCopyImg" src={icon_order_copy} alt="" />
                    </div>

                    <div className='signContractAskDialogTips'>Copy the order number and launch the contract APP. The system will automatically identify the order number in your clipboard and import the order. If it is not recognized, please enter the query manuallv.</div>

                    <div className='signContractAskDialogLine'></div>
                    <div className='signContractAskDialogButtonContent'>
                        <div className='signContractAskDialogButtonDidPaid' onClick={onHandleDidSignClick}>I have signed</div>
                        <div className='signContractAskDialogButtonSpace'></div>
                        <div className='signContractAskDialogButtonNotPaid' onClick={onHandleNotSignClick}>Signing not completed</div>
                    </div>
                </div>
            </div>
        </Mask >
    );
};
