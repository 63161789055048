import axios from "axios";
import { getUserInfo } from "./storage";
import { checkOSType, toSafeString } from "./utils";
import { BaseUrl } from "./apiUrl";
import DeviceID, { generateDeviceIdV2 } from "./deviceId";
import { generateAudidV2 } from "./aduid";
import AppParams from "./appParams";

// 1. 创建新的 axios 实例
const http = axios.create({
    baseURL: BaseUrl,
    timeout: 50000,
    withCredentials: true,
    responseType: 'json',
    headers: {
        // 'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
})

// 2. 设置请求拦截器和响应拦截器
http.interceptors.request.use(config => {
    console.log(config.data)
    return config
})

// 3. 设置请求响应拦截器
http.interceptors.response.use(response => {
    console.log(response.data)
    return response.data
}, err => {
    return Promise.reject(err)
})

// 4. 导出该 axios 实例
export default http

//导出基本参数
export const baseParams = function initParams() {
    //缓存用户信息
    const userInfo = getUserInfo()
    //缓存token
    const token = toSafeString(userInfo.token)
    //缓存userId
    const userId = toSafeString(userInfo.userId)

    //设备id
    const deviceId = DeviceID.getVisitorId()
    const deviceIdV2 = generateDeviceIdV2(deviceId)

    //组装aduid
    let aduid = generateAudidV2()

    // 组装channel code
    let channelCode = AppParams.referrer || ""
    if (channelCode.length === 0) {
        channelCode = "HFIVESIGN"
    }

    let params = {}
    params.token = token
    params.userId = userId
    params.clientType = checkOSType()
    params.androidId = deviceIdV2
    params.advertId = deviceIdV2
    params.aduid = aduid
    params.aduidH5 = deviceIdV2
    params.aduidPath = navigator.userAgent
    params.locationUrl = AppParams.urlParams
    params.product = "LOANSALAD"
    params.channelCode = channelCode
    params.subChannelCode = channelCode
    params.version = "5006"
    params.apiVersion = "v3"
    return params
}
