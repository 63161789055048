import { USER_NOT_EXIST_CHECK_REQ } from "../actions/userNotExist"

// 初始状态
const initState = {
    notExist: false
}

// 操作 用户 状态信息的 reducer 函数
export const userNotExist = (state = initState, action) => {
    const { type, payload } = action
    console.log('userNotExistState type: ' + type)

    if (USER_NOT_EXIST_CHECK_REQ === type) {
        return { ...payload, notExist: true }

    } else {
        return state
    }
}