
import http, { baseParams } from "@/utils/request";
import { UrlGetCouponInfo } from "@/utils/apiUrl";
import { isEmptyString } from "@/utils/utils";
import { Toast } from "antd-mobile";

//类型：请求成功
export const COUPON_DETAIL_REQ_SUCCESS = "COUPON_DETAIL_REQ_SUCCESS"

//类型：请求失败
export const COUPON_DETAIL_REQ_FAIL = "COUPON_DETAIL_REQ_FAIL"

//类型：请求失败
export const COUPON_DETAIL_REQ_CLEAR = "COUPON_DETAIL_REQ_CLEAR"


/**
 * 获取优惠券信息
 * @returns thunk
 */
export const getCouponDetail = () => {
    return async (dispatch) => {
        //组装参数
        const params = baseParams()

        //发起请求
        const res = await http.post(UrlGetCouponInfo, params)
        const resCode = parseInt(res.code)
        const resObj = res.obj
        console.log("获取优惠券信息回调-结束：=======")

        //保存
        const success = (resCode === 0)
        const result = {}
        result.list = resObj||[]
        dispatch(saveCouponDetailState(result, success))

        //如果有错误，提示
        if (!success) {
            if (!isEmptyString(res.msg)) {
                Toast.show(res.msg, 1)
            }
        }
    }
}

/**
 * 将 返回状态 信息保存到 Redux 中
 * @param {*} data 
 * @returns 
 */
export const saveCouponDetailState =  (data, success) => {
    return {
        type: success ? COUPON_DETAIL_REQ_SUCCESS : COUPON_DETAIL_REQ_FAIL,
        payload: data
    }
}

/**
 * 将 返回状态 信息保存到 Redux 中
 * @param {*} data 
 * @returns 
 */
export const cleanCouponDetailState = () => {
    console.log("清空CouponDetail state: ")
    return {
        type: COUPON_DETAIL_REQ_CLEAR,
        payload: {}
    }
}
