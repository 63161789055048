import { LOGIN_REQ_CLEAR, LOGIN_REQ_FAIL, LOGIN_REQ_SUCCESS } from "../actions/login"

// 初始状态
const initState = {
    token: '',
    userId: '',
    type: '',
    isAdSource: 'YES'
}

// 操作 Token 状态信息的 reducer 函数
export const login = (state = initState, action) => {
    const { type, payload } = action
    //console.log('login type: ' + type)

    switch (type) {
        case LOGIN_REQ_SUCCESS: return { ...payload, status: type }
        case LOGIN_REQ_FAIL: return { ...payload, status: type }
        case LOGIN_REQ_CLEAR: return { status: type }
        default: return state
    }
}