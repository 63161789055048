import React, { useEffect, useState } from "react";
import '../proof.css';
import '../../../App.css'
import '../../Login/login.css';
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { Mask, DotLoading } from "antd-mobile";
import { useDispatch, useSelector } from "react-redux";
import { PROOF_MOBILE_USER_STATE_REQ_FAIL, PROOF_MOBILE_USER_STATE_REQ_SUCCESS, getUserState, isProofFace, isProofFrequentlyUsedApps, isProofMobile } from "@/store/actions/userState";
import { isEmptyString, toTrimString } from "@/utils/utils";
import { PageTag } from "@/utils/pageTag";
import ProofNavigateBar from "@/components/ProofNavigateBar";
import { saveEvent } from "@/store/actions/saveEvent";
import { useFormik } from "formik";
import { SEND_DATA_SMS_CODE_REQ_FAIL, SEND_DATA_SMS_CODE_REQ_SUCCESS, cleanSendDataSmsCodeState, sendDataSmsCode } from "@/store/actions/sendDataSmsCode";
import FormOtpInput from "@/components/FormOtpInput";
import FormMobileInput from "@/components/FormMobileInput";
import { DATA_SMS_CODE_LOIN_REQ_FAIL, dataSmsCodeLogin, resetProofMobileDataFailState } from "@/store/actions/dataSmsCodeLogin";
import { isLoanLoaning, isLoanReject, isLoanRepayNow, isLoanReviewing, isLoanSigning } from "@/utils/loanStatus";
import AddFavorite from "@/components/AddFavorite";
import AddToHomeGifDialog from "@/components/AddToHomeGifDialog/AddToHomeGifDialog";
import AddHomeEvent from "@/utils/addHomeEvent";
import PrivacyPolicyLink from "@/components/PrivacyPolicyLink/PrivacyPolicyLink";
import CouponClick from "@/pages/Coupon/CouponClick";

/**
 * 认证号码页
 */
export default function ProofMobile() {

    //loading状态
    const [maskVisible, setMaskVisible] = useState(false)

    //显示add home弹框
    const [addHomeMaskVisible, setAddHomeMaskVisible] = useState(false);
    const [addHomeMaskIsHomeMode, setAddHomeMaskIsHomeMode] = useState(true);

    // 获取路由历史 history 对象
    const history = useHistory()

    // 获取路由信息 location 对象
    const location = useLocation()

    //获取 Redux 分发器
    const dispatch = useDispatch()

    //倒计时状态
    const [canStart, setCanStart] = useState(false);

    //获取Redux Store中的信息
    const sendDataSmsCodeInfo = useSelector(state => state.sendDataSmsCode)

    //获取Redux Store中的用户状态信息
    const userStateInfo = useSelector(state => state.userState)

    //获取Redux Store中的用户状态信息
    // const userNotExistInfo = useSelector(state => state.userNotExist)

    //获取Redux Store中的保存状态信息
    const dataSmsCodeLoginFailInfo = useSelector(state => state.dataSmsCodeLoginFail)

    // Formik 表单对象
    const form = useFormik({
        //设置表单初始值
        initialValues: {
            mobile: userStateInfo.readonlyMobile,
            code: ""
        },

        //提交
        onSubmit: values => {
            console.log(values)

            //提交登录
            onLoginSubmit()
        },

        //校验
        validate(values) {
            const errors = {}
            if (!values.mobile) {
                errors.mobile = "phone number can't be none"
            }
            if (!values.code) {
                errors.code = "otp can't be none"
            }

            return errors
        }
    })

    //查询用户流程状态
    const checkUserStateInfo = async () => {
        console.log("ProofMobile-checkUserStateInfo: ");

        //获取用户状态
        try {
            //显示loading
            setMaskVisible(true)

            //获取Action
            const action = getUserState(PageTag.PROOF_MOBILE)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //请求短信接口
    const doSendOtp = async (mobile) => {
        console.log("doSendOtp: " + mobile);

        try {
            //显示loading
            setMaskVisible(true)

            //获取Action
            const action = sendDataSmsCode(mobile)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //请求登录接口
    const doOtpLogin = async (mobile, code, orderId = "") => {
        console.log("ProofMobile-doLogin: " + mobile + " , " + code + ", " + orderId);

        if (isEmptyString(mobile) || isEmptyString(code)) {
            return
        }

        try {
            //显示loading
            setMaskVisible(true)

            //获取Action
            const action = dataSmsCodeLogin(mobile, code, orderId)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //点击获取otp
    const onSendSmsCode = async () => {
        //验证是否在倒计时中
        //打点
        doHitEvent("PROOF_MOBILE_OTP_CLICK")

        //验证手机号
        let mobile = toTrimString(form.values.mobile + "")
        if (mobile.length < 9) {
            form.setTouched({ mobile: true })
            return
        }

        //请求发送短信
        doSendOtp(mobile)
    }

    //点击登录
    const onLoginSubmit = async () => {
        try {
            //打点
            doHitEvent("PROOF_MOBILE_COMMIT_CLICK")

            //验证手机号
            let mobile = toTrimString(form.values.mobile + "")

            //验证验证码
            let code = toTrimString(form.values.code + "")

            //orderId
            let orderId = ""//sendDataSmsCodeInfo.fetchOrderId + ""

            //请求登录
            doOtpLogin(mobile, code, orderId)

        } catch (e) {
            console.log(e)
        }
    }

    //点击返回
    const onHandleNavGoBack = () => {
        console.log("go back")
        history.goBack()
    }

    //打点接口
    const doHitEvent = async (type, remark = "") => {
        console.log("doHitEvent: " + type + " , " + remark);

        try {
            //获取Action
            const action = saveEvent(type, remark)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    // 点击add home监听
    const onHandleAddToHome = (type) => {
        console.log(`点击add home监听: ${type}`)
        if (type === 'home') {
            AddHomeEvent.onHandleAddHomeEventClick()
        } else {
            AddHomeEvent.onHandleAddFavoriteEventClick()
        }
    }

    // 关闭add home
    const onHandleAddToHomeClose = () => {
        console.log(`点击close add home监听`)
        setAddHomeMaskVisible(false)
    }

    // 刷新操作
    const onHandleRefresh = () => {
        //获取用户状态
        checkUserStateInfo()
    }

    //启动时请求首页额度
    useEffect(() => {

        // 优惠券入口
        CouponClick.fromParentPage = PageTag.PROOF_MOBILE

        //获取用户状态
        checkUserStateInfo()

        //打点
        doHitEvent("PROOF_MOBILE_START")

        return () => {
            //打点
            doHitEvent("PROOF_MOBILE_END")
        }
    }, [])

    // 查询用户流程状态返回监听
    useEffect(() => {
        try {
            console.log("ProofMobile-查询用户流程状态返回监听")
            console.log(userStateInfo)

            if (userStateInfo.status === PROOF_MOBILE_USER_STATE_REQ_SUCCESS) {
                //隐藏loading
                setMaskVisible(false)
                console.log("Launch-signOrderId: " + userStateInfo.signOrderId)
    
                //是否为签约中
                const isHomeSigning = isLoanSigning(userStateInfo.signFlag, userStateInfo.signOrderId)
                //是否为审核中
                const isHomeReviewing = isLoanReviewing(userStateInfo.applyStatus)
                //是否为拒绝
                const isHomeReject = isLoanReject(userStateInfo.applyStatus, userStateInfo.orderStatus)
                //是否为放款中
                const isHomeLoaning = isLoanLoaning(userStateInfo.orderStatus)
                //是否为待还款
                const isHomeRepay = isLoanRepayNow(userStateInfo.orderStatus)
                //是否为待进件
                const isHomeNotLoan = ((!isHomeReviewing) && (!isHomeReject) && (!isHomeLoaning) && (!isHomeRepay))
                console.log("Launch-isHomeReviewing: " + isHomeReviewing)
                console.log("Launch-isHomeReject: " + isHomeReject)
                console.log("Launch-isHomeLoaning: " + isHomeLoaning)
                console.log("Launch-isHomeRepay: " + isHomeRepay)
                console.log("Launch-isHomeNotLoan: " + isHomeNotLoan)
                console.log("Launch-isHomeSigning: " + isHomeSigning)

                //跳转状态页
                if (isHomeSigning) {
                    history.replace('/guide')
                    return

                } else if (isHomeReviewing) {
                    history.replace('/review')
                    return

                } else if (isHomeReject) {
                    history.replace('/reject')
                    return

                } else if (isHomeLoaning) {
                    history.replace('/loaning')
                    return

                } else if (isHomeRepay) {
                    history.replace('/repay')
                    return
                }

                //判断下是否已经做完所有认证
                if (userStateInfo.isProofFinish === true) {
                    //跳转申请页
                    history.replace('/apply')
                    return
                }

                //是否为常用app认证
                if (isProofFrequentlyUsedApps(userStateInfo.stepTitle)) {
                    //跳转申请页
                    history.replace('/prooffrequentlyapps')
                    return
                }

                //是否为人脸认证
                if (isProofFace(userStateInfo.stepTitle)) {
                    //跳转申请页
                    history.replace('/proofface')
                    return
                }

                //是否为号码认证
                if (!isProofMobile(userStateInfo.stepTitle)) {
                    //跳转申请页
                    history.replace('/proof')
                }
            } else if (userStateInfo.status === PROOF_MOBILE_USER_STATE_REQ_FAIL) {
                //隐藏loading
                setMaskVisible(false)
            }

        } catch (e) {
            console.log(e)
        }
    }, [userStateInfo])


    //监听用户是否存在
    // useEffect(() => {
    //     if (userNotExistInfo.notExist === true) {
    //         //history.replace('/login')
    //         history.replace('/loginfb')
    //     }
    // }, [userNotExistInfo])


    //监听验证码接口返回
    useEffect(() => {
        console.log("ProofMobile-监听验证码返回")
        console.log(sendDataSmsCodeInfo)
        //获取短信发送成功返回
        if (sendDataSmsCodeInfo.status === SEND_DATA_SMS_CODE_REQ_SUCCESS) {
            //关闭loading
            setMaskVisible(false)

            //开始倒计时
            setCanStart(true)

            //更新验证码缓存信息
            dispatch(cleanSendDataSmsCodeState({ ...sendDataSmsCodeInfo }))

        } else if (sendDataSmsCodeInfo.status === SEND_DATA_SMS_CODE_REQ_FAIL) {
            //关闭loading
            setMaskVisible(false)

            //更新验证码缓存信息
            dispatch(cleanSendDataSmsCodeState({ ...sendDataSmsCodeInfo }))
        }
    }, [sendDataSmsCodeInfo])

    // 保存失败状态返回监听
    useEffect(() => {
        try {
            console.log("ProofMobile-保存失败状态返回监听")
            console.log(dataSmsCodeLoginFailInfo)

            if (dataSmsCodeLoginFailInfo.status === DATA_SMS_CODE_LOIN_REQ_FAIL) {
                //隐藏loading
                setMaskVisible(false)

                try {
                    //获取Action
                    const action = resetProofMobileDataFailState()

                    //触发接口
                    dispatch(action)

                } catch (e) {
                    console.log(e)
                }
            }

        } catch (e) {
            console.log(e)
        }
    }, [dataSmsCodeLoginFailInfo])

    return (
        <div className="proofRoot">
            {/* <NavBar onBack={() => { history.goBack() }} style={{ 'width': '100%', 'backgroundColor': 'white' }}>Proof</NavBar> */}
            <ProofNavigateBar theme="white" onBackClick={onHandleNavGoBack} onAddFavorite={() => { setAddHomeMaskIsHomeMode(false); setAddHomeMaskVisible(true) }} onAddHome={() => { setAddHomeMaskIsHomeMode(true); setAddHomeMaskVisible(true) }} onRefresh={onHandleRefresh}>{userStateInfo.pageTitle}</ProofNavigateBar>

            {/* 使用添加 home screen bar */}
            {/* <AddFavorite onAddHome={() => { setAddHomeMaskIsHomeMode(true); setAddHomeMaskVisible(true) }} /> */}

            <form className="proofContent" onSubmit={form.handleSubmit}>
                {/* 手机号码输入框 */}
                {userStateInfo.readonlyMobile && (userStateInfo.readonlyMobile.length > 0) && <div className="proofItemList">
                    <FormMobileInput
                        name="mobile"
                        placeholder="Enter phone number"
                        value={form.values.mobile}
                        title="Phone number"
                        type="text"
                        readOnly="readOnly"
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        autoComplete="off" />
                </div>}
                {!userStateInfo.readonlyMobile && <div className="proofItemList">
                    <FormMobileInput
                        name="mobile"
                        placeholder="Enter phone number"
                        value={form.values.mobile}
                        title="Phone number"
                        type="text"
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        autoComplete="off" />
                </div>}
                {form.errors.mobile && form.touched.mobile && (
                    <div className="validate contentLeftMargin">{form.errors.mobile}</div>
                )}

                <div className="proofItemList">
                    {/* 验证码输入框 */}
                    <FormOtpInput
                        title="Verification code"
                        name="code"
                        placeholder="Enter verification code"
                        type="text"
                        value={form.values.code}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        onClickCallback={onSendSmsCode}
                        canStart={canStart}
                        autoComplete="off" />
                </div>
                {form.errors.code && form.touched.code && (
                    <div className="validate contentLeftMargin">{form.errors.code}</div>
                )}
            </form>

            <div className="proofOtpTips">{userStateInfo.readonlyMobile ? "MTN" : "We"} will send a OTP to your mobile number. Fill in the OTP to verify the validity of your mobile phone number.</div>

            {/* 点击确定 */}
            <div className="proofApplyBtn" onClick={form.handleSubmit}>
                <div className="mainButtonGradient">Next</div>
            </div>

            {/* privacy policy */}
            <PrivacyPolicyLink pageTag={userStateInfo.pageTitle} showCheckbox={false} linkText="Privacy policy" />

            {/* </div> */}
            {/* add home gif dialog */}
            <AddToHomeGifDialog visible={addHomeMaskVisible} onConfirm={onHandleAddToHome} onClose={onHandleAddToHomeClose} isHomeMode={addHomeMaskIsHomeMode} />

            <Mask visible={maskVisible} opacity={0.1}
                onMaskClick={() => { }}>
                <div className="overlayContent">
                    <span style={{ fontSize: 24, color: '#ff3141' }} >
                        <DotLoading color="currentColor" />
                    </span>
                </div>
            </Mask>
        </div>
    )
}