
import http, { baseParams } from "@/utils/request";
import { UrlGetOtp } from "@/utils/apiUrl";
import { isEmptyString } from "@/utils/utils";
import { Toast } from "antd-mobile";
import { isString } from "formik";


//类型：请求成功
export const SEND_OTP_REQ_SUCCESS = "SEND_OTP_REQ_SUCCESS"

//类型：请求失败
export const SEND_OTP_REQ_FAIL = "SEND_OTP_REQ_FAIL"

//类型：清空请求
export const SEND_OTP_REQ_CLEAR = "SEND_OTP_REQ_CLEAR"


/**
 * 发送短信验证码
 * @param {string} mobile 手机号码
 * @returns thunk
 */
export const sendOtp = (mobile, pageType = "LOGINPAGE") => {
    return async (dispatch) => {
        //组装参数
        const params = baseParams()
        params.mobile = mobile
        params.pageType = pageType

        //发起请求
        const res = await http.post(UrlGetOtp, params)
        const resCode = parseInt(res.code)
        let resObj = {}
        if (res.obj) {
            if (!isString(res.obj)) {
                resObj = res.obj
            }
        }

        //保存code，登录有用，判断是否为fb
        resObj.resCode = resCode
        console.log("发送短信验证码回调-结束：=======")

        //保存
        const success = (resCode === 0)
        dispatch(saveSendOtpState(resObj, success))

        //如果有错误，提示
        if ((resCode !== 2002) && (resCode !== 2001)) {
            if (!success) {
                if (!isEmptyString(res.msg)) {
                    Toast.show(res.msg, 1)
                }
            }
        }
    }
}

/**
 * 将 返回状态 信息保存到 Redux 中
 * @param {*} tokens 
 * @returns 
 */
export const saveSendOtpState = (data, success) => {
    return {
        type: success ? SEND_OTP_REQ_SUCCESS : SEND_OTP_REQ_FAIL,
        payload: data
    }
}

/**
 * 将 返回状态 信息保存到 Redux 中
 * @param {*} tokens 
 * @returns 
 */
export const cleanOtpState = () => {
    return {
        type: SEND_OTP_REQ_CLEAR,
        payload: {}
    }
}
