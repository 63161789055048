import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Mask, DotLoading, Toast, PageIndicator, Swiper } from "antd-mobile";

import { useDispatch, useSelector } from "react-redux";
import ProofNavigateBar from "@/components/ProofNavigateBar";
import { saveEvent } from "@/store/actions/saveEvent";
import icon_order from "@/assets/icon_order.png"
import icon_order_copy from "@/assets/icon_order_copy.png"
import icon_guide_step1 from "@/assets/icon_guide_step1.png"
import icon_guide_step2 from "@/assets/icon_guide_step2.png"
import icon_guide_logo from "@/assets/icon_guide_logo.png"
import icon_guide_safety from "@/assets/icon_guide_safety.png"
import icon_guide_rate_star from "@/assets/icon_guide_rate_star.png"
import icon_guide_app_install from "@/assets/icon_guide_app_install.png"
import icon_guide1 from "@/assets/icon_guide1.png"
import icon_guide2 from "@/assets/icon_guide2.png"
import icon_guide3 from "@/assets/icon_guide3.png"
import icon_guide_index_step from "@/assets/icon_guide_index_step.png"
import icon_guide_step_v2_1 from "@/assets/icon_guide_step_v2_1.png"
import icon_guide_loading from '@/assets/icon_guide_loading.gif'
import icon_guide_tips from "@/assets/icon_guide_tips.png"


import './guide.css';
// import AddFavorite from "@/components/AddFavorite";
import AddToHomeGifDialog from "@/components/AddToHomeGifDialog/AddToHomeGifDialog";
import AddHomeEvent from "@/utils/addHomeEvent";
import { getOrderIdInfo } from "@/utils/storage";
import { PageTag } from "@/utils/pageTag";
import { GUIDE_USER_STATE_REQ_FAIL, GUIDE_USER_STATE_REQ_SUCCESS, getUserState } from "@/store/actions/userState";
import { isLoanLoaning, isLoanReject, isLoanRepayNow, isLoanReviewing, isLoanSigning } from "@/utils/loanStatus";
import { SEND_COPY_FAIL_REQ_SUCCESS, sendCopyFailSms } from "@/store/actions/sendCopyFailSms";
import { updateConfirmApplyState } from "@/store/actions/loanApply";
import SignContractAskDialog from "@/components/SignContractAskDialog/SignContractAskDialog";

/**
 * 引导页
 */
export default function Guide() {

    // loading状态
    const [maskVisible, setMaskVisible] = useState(false)

    // 订单状态
    const [curOrderId, setCurOrderId] = useState("")

    // 显示add home弹框
    const [addHomeMaskVisible, setAddHomeMaskVisible] = useState(false);
    const [addHomeMaskIsHomeMode, setAddHomeMaskIsHomeMode] = useState(true);
    //显示支付提示弹框
    const [signContractAskMaskVisible, setSignContractAskMaskVisible] = useState(false);

    // 获取路由历史 history 对象
    const history = useHistory()

    // 获取 Redux 分发器
    const dispatch = useDispatch()

    // 获取Redux Store中的用户状态信息
    const userStateInfo = useSelector(state => state.userState)

    // 获取Redux Store中的用户状态信息
    const sendCopyFailSmsInfo = useSelector(state => state.sendCopyFailSms)

    // banner
    const banners = [icon_guide1, icon_guide2, icon_guide3]

    const items = banners.map((banner, index) => (
        <Swiper.Item key={index}>
            <div className="guideSwiperItemContent">
                <img className="guideSwiperItemImage" src={banner} alt="" />
                {
                    (index === 0) && <div className="guideBannerTipsContent">
                        <img className="guideBannerTipsIndexImg" src={icon_guide_index_step} alt="" />
                        <div className="guideBannerTipsTitle">Step 01</div>
                    </div>
                }

                {
                    (index === 1) && <div className="guideBannerTipsContent">
                        <img className="guideBannerTipsIndexImg" src={icon_guide_index_step} alt="" />
                        <div className="guideBannerTipsTitle">Step 02</div>
                    </div>
                }

                {
                    (index === 2) && <div className="guideBannerTipsContent">
                        <img className="guideBannerTipsIndexImg" src={icon_guide_index_step} alt="" />
                        <div className="guideBannerTipsTitle">Step 03</div>
                    </div>
                }
            </div>
        </Swiper.Item>
    ))

    //打点接口
    const doHitEvent = async (type, remark = "") => {
        console.log("doHitEvent: " + type + " , " + remark);

        try {
            //获取Action
            const action = saveEvent(type, remark)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    // 获取订单号
    const getOrderId = () => {
        const curOid = userStateInfo.signOrderId || ""
        const orderId = getOrderIdInfo()
        console.log("---orderId:" + orderId)
        console.log("---curOid:" + curOid)

        if (curOid.length > 0) {
            setCurOrderId(curOid)
        } else {
            setCurOrderId(orderId)
        }
    }

    //点击返回
    const onHandleNavGoBack = () => {
        console.log("go back")
        history.replace("/review")
    }

    // 点击add home监听
    const onHandleAddToHome = (type) => {
        console.log(`点击add home监听: ${type}`)
        if (type === 'home') {
            AddHomeEvent.onHandleAddHomeEventClick()
        } else {
            AddHomeEvent.onHandleAddFavoriteEventClick()
        }
    }

    // 关闭add home
    const onHandleAddToHomeClose = () => {
        console.log(`点击close add home监听`)
        setAddHomeMaskVisible(false)
    }

    // 关闭支付提示弹框
    const onHandleSignContractAskDialogClose = () => {
        // 关闭弹框
        setSignContractAskMaskVisible(false)
    }

    //已支付
    const onHandleDidSign = () => {
        // 关闭弹框
        setSignContractAskMaskVisible(false)

        // 查询状态
        checkUserStateInfo()
    }

    // 未支付
    const onHandleNotSign = () => {
        setSignContractAskMaskVisible(false)
    }

    // 复制订单号
    const onHandleOrderIdCopy = () => {
        //打点
        doHitEvent("ORDERID_COPY", curOrderId || "")

        if (curOrderId && curOrderId.length > 0) {
            const preFlag = "contractsuite="
            const fullText = preFlag + curOrderId
            console.log("==== 订单号: " + fullText)

            if (navigator.clipboard && navigator.permissions) {
                navigator.clipboard.writeText(fullText)
                    .then(function () {
                        console.log('Successfully!');
                        Toast.show('The order number has been copied to the clipboard.')
                    })
                    .catch(function (err) {
                        // console.error('Unable to copy content to clipboard', err);
                        // Toast.show('Unable to copy content to clipboard')

                        try {
                            const textArea = document.createElement('textArea')
                            textArea.value = fullText
                            textArea.style.width = 0
                            textArea.style.position = 'fixed'
                            textArea.style.left = '-999px'
                            textArea.style.top = '10px'
                            textArea.setAttribute('readonly', 'readonly')
                            document.body.appendChild(textArea)
                            textArea.focus()
                            textArea.select()
                            document.execCommand('copy')
                            document.body.removeChild(textArea)

                            console.log('Successfully!');
                            Toast.show('The order number has been copied to the clipboard.')
                        }
                        catch (error) {
                            //打点
                            if (err) {
                                doHitEvent("CLIPBOARD_ERR", err.message || "")
                            } else {
                                doHitEvent("CLIPBOARD_ERR", "err is null")
                            }

                            // 发送失败短信
                            toSendCopyFailSms()

                            console.error('Unable to copy content to clipboard', error);
                            Toast.show('Unable to copy content to clipboard')
                        }
                    });
            } else {
                try {
                    const textArea = document.createElement('textArea')
                    textArea.value = fullText
                    textArea.style.width = 0
                    textArea.style.position = 'fixed'
                    textArea.style.left = '-999px'
                    textArea.style.top = '10px'
                    textArea.setAttribute('readonly', 'readonly')
                    document.body.appendChild(textArea)
                    textArea.focus()
                    textArea.select()
                    document.execCommand('copy')
                    document.body.removeChild(textArea)

                    console.log('Successfully!');
                    Toast.show('The order number has been copied to the clipboard.')
                }
                catch (error) {
                    //打点
                    if (error) {
                        doHitEvent("CLIPBOARD_ERR", error.toString() || "")
                    } else {
                        doHitEvent("CLIPBOARD_ERR", "err is null")
                    }

                    // 发送失败短信
                    toSendCopyFailSms()

                    console.error('Unable to copy content to clipboard', error);
                    Toast.show('Unable to copy content to clipboard')
                }
            }
        } else {
            Toast.show("Order id is null")
        }
    }

    // 点击安装事件
    const onHandleInstallClick = () => {
        //打点
        doHitEvent("INSTALL_CLICK")

        // 复制订单号
        onHandleOrderIdCopy()

        // 显示订单弹框
        setSignContractAskMaskVisible(true)

        // 1.5秒后打开应用市场
        setTimeout(() => {
            // 打开应用市场
            const playUrl = "https://play.google.com/store/apps/details?id=com.mycontract.suitepro"
            const params = "&referrer=" + curOrderId
            const fullUrl = playUrl + params
            window.open(fullUrl, "_blank")
        }, 1500)
    }

    //查询用户流程状态
    const checkUserStateInfo = async () => {
        console.log("GUIDE-checkUserStateInfo: ");

        //获取用户状态
        try {
            //显示loading
            setMaskVisible(true)

            //获取Action
            const action = getUserState(PageTag.GUIDE)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    // 发送失败短信
    const toSendCopyFailSms = async () => {
        console.log("GUIDE-setCopyFailSms: ");

        //获取用户状态
        try {
            //显示loading
            //setMaskVisible(true)

            //获取Action
            const action = sendCopyFailSms()

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //启动时请求首页额度
    useEffect(() => {
        //获取贷款详情
        try {
            //添加 fb 像素
            // eslint-disable-next-line no-undef
            fbq('track', 'Page_Guide');
            // eslint-disable-next-line no-undef
            gtag('track', 'Page_Guide');

            //更新状态
            dispatch(updateConfirmApplyState({}, true))

            // 获取订单号
            getOrderId()

            //获取用户状态
            checkUserStateInfo()

            //打点
            doHitEvent("GUIDE_START")

            return () => {
                //打点
                doHitEvent("GUIDE_END")
            }

        } catch (e) {
            console.log(e)
        }
    }, [])

    // 查询用户流程状态返回监听
    useEffect(() => {
        try {

            console.log("GUIDE-查询用户流程状态返回监听")
            console.log(userStateInfo)

            if (userStateInfo.status === GUIDE_USER_STATE_REQ_SUCCESS) {
                //隐藏loading
                setMaskVisible(false)

                console.log("LOAING-再次查询user state:")
                console.log("LOAING-applyStatus: " + userStateInfo.applyStatus)
                console.log("LOAING-orderStatus: " + userStateInfo.orderStatus)
                console.log("LOAING-signFlag: " + userStateInfo.signFlag)
                console.log("Launch-signOrderId: " + userStateInfo.signOrderId)

                // 更新订单号
                getOrderId()

                //是否为签约中
                const isHomeSigning = isLoanSigning(userStateInfo.signFlag, userStateInfo.signOrderId)
                //是否为审核中
                const isHomeReviewing = isLoanReviewing(userStateInfo.applyStatus)
                //是否为拒绝
                const isHomeReject = isLoanReject(userStateInfo.applyStatus, userStateInfo.orderStatus)
                //是否为放款中
                const isHomeLoaning = isLoanLoaning(userStateInfo.orderStatus)
                //是否为待还款
                const isHomeRepay = isLoanRepayNow(userStateInfo.orderStatus)
                //是否为待进件
                const isHomeNotLoan = ((!isHomeReviewing) && (!isHomeReject) && (!isHomeLoaning) && (!isHomeRepay))
                console.log("REVIEW-isHomeReviewing: " + isHomeReviewing)
                console.log("REVIEW-isHomeReject: " + isHomeReject)
                console.log("REVIEW-isHomeLoaning: " + isHomeLoaning)
                console.log("REVIEW-isHomeRepay: " + isHomeRepay)
                console.log("REVIEW-isHomeNotLoan: " + isHomeNotLoan)
                console.log("REVIEW-isHomeSigning: " + isHomeSigning)

                //页面转发
                setTimeout(() => {
                    if (isHomeSigning) {

                    } else if (isHomeReviewing) {
                        history.replace('/review')

                    } else if (isHomeReject) {
                        history.replace('/reject')

                    } else if (isHomeLoaning) {
                        history.replace('/loaning')

                    } else if (isHomeRepay) {
                        history.replace('/repay')

                    } else {
                        history.replace('/home')
                    }
                }, 500)

            } else if (userStateInfo.status === GUIDE_USER_STATE_REQ_FAIL) {
                //隐藏loading
                setMaskVisible(false)
            }
        } catch (e) {
            console.log(e)
        }
    }, [userStateInfo])


    // 查询发送复制失败短信返回监听
    useEffect(() => {
        console.log("GUIDE-查询发送复制失败短信返回监听")
        console.log(sendCopyFailSmsInfo)
    }, [sendCopyFailSmsInfo])

    //监听用户是否存在
    // useEffect(() => {
    //     if (userNotExistInfo.notExist === true) {
    //         //history.replace('/login')
    //          history.replace('/loginfb')
    //     }
    // }, [userNotExistInfo])

    return (
        <div className="guideRoot">
            {/* 顶部背景 */}
            {/* <div className="applyTopBackground"></div> */}
            <ProofNavigateBar theme="white" onBackClick={onHandleNavGoBack} onAddFavorite={() => { setAddHomeMaskIsHomeMode(false); setAddHomeMaskVisible(true) }} onAddHome={() => { setAddHomeMaskIsHomeMode(true); setAddHomeMaskVisible(true) }}>Sign contract</ProofNavigateBar>

            {/* 使用添加 home screen bar */}
            {/* <AddFavorite onAddHome={() => { setAddHomeMaskIsHomeMode(true); setAddHomeMaskVisible(true) }} /> */}

            {/* 顶部banner */}
            {/* <div className="guideSwiperContent">
                <Swiper
                    loop
                    autoplay
                    style={{
                        '--border-radius': '8px',
                        '--track-padding': ' 0px 0px 32px 0px'
                    }}
                    indicator={(total, current) => (
                        <div className="guideSwiperIndicator">
                            <div className="guideSwiperIndicatorContent">
                                <PageIndicator
                                    total={total}
                                    current={current}
                                    style={{
                                        '--dot-color': '#FFFFFF4D',
                                        '--active-dot-color': '#FFF',
                                        '--dot-size': '4px',
                                        '--active-dot-size': '8px',
                                        '--dot-border-radius': '50%',
                                        '--active-dot-border-radius': '4px',
                                        '--dot-spacing': '10px',
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    defaultIndex={0}>
                    {items}
                </Swiper>
            </div> */}
            <div className="guideStepBg">
                <div className="guideStepContent">
                    <div className="guideStepRow">
                        <img className="guideStepImg1" src={icon_guide_step_v2_1} alt="" />
                        <div className="guideStepTitle1">Confirm Order</div>
                    </div>
                    <div className="guideStepLineContent">
                        <div className="guideStepLine"></div>
                    </div>
                    <div className="guideStepRow">
                        <img className="guideStepImg1" src={icon_guide_loading} alt="" />
                        <div className="guideStepTitle2">Sign contract</div>
                    </div>
                    <div className="guideStepRow">
                        <div className="guideStepLineContentGray">
                            <div className="guideStepLine guideLineHightGray"></div>
                        </div>
                        <div className="guideStepTip3">After completing the contract signing in [Contract suite], the loan can be withrawn</div>
                    </div>
                    <div className="guideStepRow">
                        <div className="guideStepImg3">3</div>
                        <div className="guideStepTitle1">Loan withdrawl</div>
                        {/* <div className="guideStepTitle1">Disburse loan</div> */}
                    </div>
                </div>
            </div>

            <div className="guideContent">
                {/* 订单号 */}
                <div className="guideOrderContent" onClick={() => { onHandleOrderIdCopy() }}>
                    <img className="guideOrderImg" src={icon_order} alt="" />
                    <div className="guideOrderNumberContent">
                        <div className="guideOrderNumber">Order number:</div>
                        <div className="guideOrderNumber">{curOrderId}</div>
                    </div>
                    <img className="guideOrderCopyImg" src={icon_order_copy} alt="" />
                </div>

                {/* 步骤 */}
                {false && <div className="guideStepContent">
                    <div className="guideStepRowContent">
                        <div className="guideStepRowStep">
                            <div className="guideStepLeftLine guideLineTransparent"></div>
                            <img className="guideStepImg" src={icon_guide_step1} alt="" />
                            <div className="guideStepRightLine"></div>
                        </div>
                        <div className="guideStepRowStep">
                            <div className="guideStepLeftLine"></div>
                            <img className="guideStepImg" src={icon_guide_step2} alt="" />
                            <div className="guideStepRightLine"></div>
                        </div>
                        <div className="guideStepRowStep">
                            <div className="guideStepLeftLine"></div>
                            <img className="guideStepImg" src={icon_guide_step2} alt="" />
                            <div className="guideStepRightLine guideLineTransparent"></div>
                        </div>
                    </div>
                    <div className="guideStepRowContent">
                        <div className="guideStepRowTitle">Confirm Order</div>
                        <div className="guideStepRowTitle">Sign contract</div>
                        <div className="guideStepRowTitle">Disburse loan</div>
                    </div>
                </div>}

                {/* app 信息 */}
                {/* <div className="guideTips">You need to complete the signing on the application Contract suite.</div> */}
                <div className="guideTips">Sign contract and withdraw loan.</div>

                <div className="guideAppContent">
                    <div className="guideAppLogoContent">
                        <img className="guideAppLogo" src={icon_guide_logo} alt="" />
                        <div className="guideAppNameContent">
                            <div className="guideAppName">Contract suite</div>
                            <div className="guideAppDev">CONTRACT SUITE DEV</div>
                        </div>
                    </div>

                    <div className="guideAppSafetyContent">
                        <img className="guideAppSafetyImg" src={icon_guide_safety} alt="" />
                        <div className="guideAppSafety">Verified by Play Store protection mechanism</div>
                    </div>

                    <div className="guideAppRateContent">
                        <div className="guideAppRateItemContent">
                            <div className="guideAppRateItemRow">
                                <div className="guideAppRateValue">4.6</div>
                                <img className="guideAppRateStarImg" alt="" src={icon_guide_rate_star} />
                            </div>
                            <div className="guideAppRateTitle">Score</div>
                        </div>

                        <div className="guideAppRateLine"></div>

                        <div className="guideAppRateItemContent">
                            <div className="guideAppRateValue">3+</div>
                            <div className="guideAppRateTitle">Age</div>
                        </div>

                        <div className="guideAppRateLine"></div>

                        <div className="guideAppRateItemContent">
                            <div className="guideAppRateValue">100K+</div>
                            <div className="guideAppRateTitle">Downloads</div>
                        </div>

                        {/* <div className="guideAppRateLine"></div>

                        <div className="guideAppRateItemContent">
                            <div className="guideAppRateValue">13M</div>
                            <div className="guideAppRateTitle">Size</div>
                        </div> */}
                    </div>

                    {/* 去应用市场 */}
                    <div className="guideAppInstallContent" onClick={() => { onHandleInstallClick() }}>
                        <img className="guideAppInstallImg" alt="" src={icon_guide_app_install} />
                        <div className="guideAppInstallTitle">Install on Play Store</div>
                    </div>
                </div>

                <div className="guideAppContent">
                    <div className="guideAppTipsContent">
                        <img className="guideAppTipsImage" src={icon_guide_tips} alt="" />
                        <div className="guideAppTipsTitle">Tips</div>
                    </div>
                    <div className="guideAppTipsDetail">1. No need to log in</div>
                    <div className="guideAppTipsDetail">2. Automatically identify orders</div>
                    <div className="guideAppTipsDetail guideAppBottomMargin16px">3. 0 information supplement</div>

                </div>

                <div className="guideAppInstallTips">Please be sure to install or launch the app through this page, otherwise your information will be lost.</div>
            </div>

            {/* add home gif dialog */}
            <AddToHomeGifDialog visible={addHomeMaskVisible} onConfirm={onHandleAddToHome} onClose={onHandleAddToHomeClose} isHomeMode={addHomeMaskIsHomeMode} />

            {/* 签约提示弹框 */}
            <SignContractAskDialog visible={signContractAskMaskVisible} curOrderId={curOrderId} onClose={onHandleSignContractAskDialogClose} onDidSign={onHandleDidSign} onNotSign={onHandleNotSign} />

            {/* load mask */}
            <Mask visible={maskVisible} opacity={0.1}
                onMaskClick={() => { }}>
                <div className="overlayContent">
                    <span style={{ fontSize: 24, color: '#ff3141' }} >
                        <DotLoading color="currentColor" />
                    </span>
                </div>
            </Mask>
        </div >
    )
}