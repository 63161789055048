import React, { useEffect, useState } from "react";
import './privacypolicy.css';
import { saveEvent } from "@/store/actions/saveEvent";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ProofNavigateBar from "@/components/ProofNavigateBar";

/**
 * 安装页
 */
export default function PrivacyPolicy() {

    // 获取路由历史 history 对象
    const history = useHistory()

    //获取 Redux 分发器
    const dispatch = useDispatch()

    //显示add home弹框
    const [addHomeMaskVisible, setAddHomeMaskVisible] = useState(false);
    const [addHomeMaskIsHomeMode, setAddHomeMaskIsHomeMode] = useState(true);


    //启动时监听
    useEffect(() => {
        //获取贷款详情
        try {
            //打点
            doHitEvent("PRIVACY_POLICY_START")

            return () => {
                //打点
                doHitEvent("PRIVACY_POLICY_END")
            }

        } catch (e) {
            console.log(e)
        }
    }, [])


    //打点接口
    const doHitEvent = async (type, remark = "") => {
        console.log("doHitEvent: " + type + " , " + remark);

        try {
            //获取Action
            const action = saveEvent(type, remark)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //点击返回
    const onHandleNavGoBack = () => {
        console.log("go back")
        history.goBack()
    }

    return (
        <div className="termsofuseRoot">

            {/* 顶部背景 */}
            {/* <div className="applyTopBackground"></div> */}
            <ProofNavigateBar theme="white" onBackClick={onHandleNavGoBack} onAddFavorite={() => { setAddHomeMaskIsHomeMode(false); setAddHomeMaskVisible(true) }} onAddHome={() => { setAddHomeMaskIsHomeMode(true); setAddHomeMaskVisible(true) }}>Privacy Policy</ProofNavigateBar>

            <span>You agree to these terms of use as you enter the“ loan salad ” App (hereinafter the "App"). Please take the time to read them properly. In case you do not consent to these terms and conditions, exit the App immediately.</span>
            <span className="termsofuseMarginTop16Px">We're committed to protecting your privacy, and our policies are designed to reflect this commitment. Our Privacy Policy describes what information we collect from you, how we use that information, and how you can control your personal data.</span>

            <div className="termsofuseMarginTop16Px">What Data Do We Gather</div>
            <span>We want you to understand what kind of data we look for, why we collect it, and how we use it. This Privacy Policy applies to all users of the App.</span>
            <span className="termsofuseMarginTop16Px">The kind of data we get and how we utilize it are covered in the sections below.</span>
            <span className="termsofuseMarginTop16Px">Personally Identifiable Information: It refers to any information that may be used to locate or contact a particular user. Examples:</span>

            <div className="termsofuseMarginTop16Px">Why Do We Need This Information</div>
            <span>We collect the personal details of users to manage our business and provide a better experience. Additionally, we need your personal information to process your application and connect you with one of our Partners.</span>

            <div className="termsofuseMarginTop16Px">How the App Uses Personally Identifiable Information</div>
            <span>PII collected by the app is necessary in order to provide the loan connection service. Data submitted by the user is securely sent to the lender partners to streamline the loan application process.</span>
            <span className="termsofuseMarginTop16Px">PII may also be used in disagreement resolution and technical maintenance. App operators promise not to use PII in such a way that exceeds what is sufficient to deliver and maintain the service.</span>
            <span className="termsofuseMarginTop16Px">Using the app and providing PII constitutes consent from the user for their PII to be shared with verified lender partners in line with the appropriate laws. As well as loan applications, users consent for their PII to be used for related advertising and marketing purposes.</span>
            <span className="termsofuseMarginTop16Px">Neither the app nor its operators have direct access to the PII submitted by users, as this is encrypted by the app before being sent to the lender partners.</span>
            <span className="termsofuseMarginTop16Px">By using the app users consent to lender partners corresponding with them in relation to the processing and administering the loan request, subsequent loan offers, and subsequent loan agreements. This consent waives users’ rights to file any claim related to such correspondence with the or any other agency.</span>

            <div className="termsofuseMarginTop16Px">How Do We Share Personally Identifiable Information</div>
            <span>We need your permission to process your application and put you in touch with a lender. This process is granted by your provision of personally identifiable information.</span>
            <span className="termsofuseMarginTop16Px">Due to the fact that a lender cannot accept your loan without knowing something about you, it follows that we have the right to disclose this information to a third party.</span>
            <span className="termsofuseMarginTop16Px">It's important to be aware that lenders may request verification of your personal information from national databases and credit reporting organizations. Keep in mind that these queries can have an impact on your credit score.</span>

            <div className="termsofuseMarginTop16Px">Marketing Practices</div>
            <span>You accept that you are aware of our marketing practices when you first use our App and that you consent to receive communications of this nature from us and our third-party partners.</span>
            <span className="termsofuseMarginTop16Px">By using the information you've given us, our third-party partners could also contact you.</span>
            <span className="termsofuseMarginTop16Px">We will use your contact details to send you marketing emails about our products and services. You can opt out of these at any time by using the unsubscribe link in any email we send you. However, if you want to unsubscribe from messages from third parties, you need to get in touch with them directly.</span>

            <div className="termsofuseMarginTop16Px">How We Protect Your Information</div>
            <span>Your personal information is protected by a range of measures, from loss or theft to unauthorized access, display, copying, use, or alteration.</span>
            <span className="termsofuseMarginTop16Px">By utilizing protections and cutting-edge security measures that comply with the standards of US legislation, we're keeping your data private. Access to it is restricted to authorized individuals who may only use it for legal, commercial purposes as regulated by US law.</span>

            <div className="termsofuseMarginTop16Px">Security</div>
            <span>We take reasonable measures, which may include, but are not limited to, firewall barriers, SSL encryption techniques, and authentication procedures, to help protect personal information from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction. No system, however, is perfectly secure.</span>

            <div className="termsofuseMarginTop16Px">Cookies and Information Collected by Third Parties</div>
            <span>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.</span>
            <span className="termsofuseMarginTop16Px">The app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</span>
            <span className="termsofuseMarginTop16Px">The Mobile App may allow third parties, including advertising partners and personal loan lenders, to display advertisements on the App. These companies may use tracking technologies, including cookies, to collect information about users who view or interact with these advertisements. The Mobile App does not provide Personally Identifiable Information to these third parties.</span>

            <div className="termsofuseMarginTop16Px">Notice for Minors</div>
            <span>If you are under 18, please do not submit any personal information through our App. We encourage parents and guardians to spend time online with their children and to participate in and monitor the interactive activities of their children.</span>
            <span className="termsofuseMarginTop16Px">If you are a parent or guardian and you have reason to believe that your child has provided us with Personal Information without your consent, please contact us.</span>

            <div className="termsofuseMarginTop16Px">Privacy Policy Changes</div>
            <span>We may change this Privacy Policy from time to time by posting new terms on this page. If we make material changes to this Privacy Policy, we might do so without notice. That's why it's important to reread our policy now and then to be informed if any changes occur.</span>

            <div className="termsofuseMarginTop16Px">How You Can Contact Us</div>
            <span>Please feel free to reach out to us using our email address if you would like to discuss anything with us.</span>
            <span className="termsofuseMarginTop16Px">contact us</span>
            <span>Telephone:+234  07050632659</span>
            <span>e-mail:LoansaladNigeriaservice@outlook.com</span>
            <span>address:LKH+3F, Anthony Village Lagos, Nigeria</span>

        </div >
    )
}