import axios from "axios";
import { BaseUrl } from "./apiUrl";

// 1. 创建新的 axios 实例
const uploadHttp = axios.create({
    baseURL: BaseUrl,
    timeout: 500000,
    withCredentials: true
})

// 2. 设置请求拦截器和响应拦截器
uploadHttp.interceptors.request.use(config => {

    console.log(config)

    return config
})

// 3. 设置请求响应拦截器
uploadHttp.interceptors.response.use(response => {
    console.log(response.data)
    return response.data
}, err => {
    return Promise.reject(err)
})

// 4. 导出该 axios 实例
export default uploadHttp
