
import './App.css';
import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom'
// import { isLogin, removeAduidInfo, removeDeviceIDInfo, removeUserInfo } from './utils/storage';

// const Login = React.lazy(()=> import('@/pages/Login'))
// const Home = React.lazy(()=> import('@/pages/Home'))

import Login from '@/pages/Login/Login';
import Home from '@/pages/Home';
import TabbarLayout from './pages/TabbarLayout';
import Proof from './pages/Proof';
import Me from './pages/Me';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import Apply from './pages/Loan/Apply';
import Review from './pages/Loan/Review';
import Launch from './pages/Launch';
import Reject from './pages/Loan/Reject';
import Loaning from './pages/Loan/Loaning';
import Repay from './pages/Loan/Repay';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import DeviceID from './utils/deviceId';
// import { generateAudid } from './utils/aduid';
// import { useDispatch } from 'react-redux';
import ProofMobile from './pages/Proof/Mobile';
import ProofFrequentlyApps from './pages/Proof/FrequentlyApps';
import ProofFace from './pages/Proof/Face';
import LoginFB from './pages/Login/LoginFB';
// import LoginAd from './pages/LoginAd';
import { useAddToHomePrompt } from "./components/AddToHomePrompt";
import AddHomeEvent from './utils/addHomeEvent';
import SmartChat from './utils/smartChat';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import LoginCoupon from './pages/LoginCoupon/LoginCoupon';
import ApplyCoupon from './pages/Loan/Apply/ApplyCoupon';
import RepayCoupon from './pages/Loan/Repay/RepayCoupon';
import Coupon from './pages/Coupon/Coupon';
import LoginAd from './pages/LoginCoupon/LoginAd';
import Guide from './pages/Loan/Guide/Guide';

function App() {

  //移除登录信息
  // removeUserInfo()

  //上报aduid

  //获取 Redux 分发器
  // const dispatch = useDispatch()


  //初始化指纹
  useEffect(() => {

    //移除登录信息
    // removeUserInfo()
    // removeAduidInfo()
    // removeDeviceIDInfo()

    //初始化 aduid
    // const gAduid = DeviceID.getAduid()
    // console.log("启动初始化 gaudid: " + gAduid)

    //初始化 smart chat
    SmartChat.init();

    //初始化 设备id
    if (!DeviceID.isValidVistorId()) {
      //初始化
      const fpPromise = FingerprintJS.load()

      //获取设备id
      fpPromise
        .then(fp => fp.get())
        .then(result => {
          DeviceID.saveVisitorId(result.visitorId)
          console.log("启动初始化 fingerprint: " + result.visitorId)

          //保存aduid
          //doSaveAduid()
        })
    } else {
      //保存aduid
      //doSaveAduid()
    }

    //监听安装事件
    AddHomeEvent.addBeforeInstallPromptEventListener()

    //注册service worker
    // if ('serviceWorker' in navigator) {
    //   navigator.serviceWorker.register('/service-worker.js')
    //     .then(function (registration) {
    //       console.log('Service Worker registered with scope:', registration.scope);
    //     })
    //     .catch(function (error) {
    //       console.error('Service Worker registration failed:', error);
    //     });
    // }

  }, [])

  return (
    <BrowserRouter>
      <div className="AppRoot">
        <div className="App">

          <Switch>
            <Route path="/proof"><Proof /></Route>
            <Route path="/proofmobile"><ProofMobile /></Route>
            <Route path="/prooffrequentlyapps"><ProofFrequentlyApps /></Route>
            <Route path="/proofface"><ProofFace /></Route>
            <Route path="/login"><LoginCoupon /></Route>
            <Route path="/loginfb"><LoginFB /></Route>
            <Route path='/loginad' ><LoginAd /></Route>
            {/* <Route path='/logincoupon' ><LoginCoupon /></Route> */}
            <Route path='/home' ><Home /></Route>
            <Route path='/me' ><Me /></Route>
            <Route path='/apply' ><ApplyCoupon /></Route>
            {/* <Route path='/applycoupon' ><ApplyCoupon /></Route> */}
            <Route path='/review' ><Review /></Route>
            <Route path='/reject' ><Reject /></Route>
            <Route path='/loaning' ><Loaning /></Route>
            <Route path='/repay' ><RepayCoupon /></Route>
            {/* <Route path='/repaycoupon' ><RepayCoupon /></Route> */}
            <Route path='/launch' ><Launch /></Route>
            <Route path='/privacy' ><PrivacyPolicy /></Route>
            <Route path='/coupon' ><Coupon /></Route>
            <Route path='/guide' ><Guide /></Route>


            {/* 直接去启动页 */}
            <Redirect to='/launch'></Redirect>

          </Switch>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
