
import http, { baseParams } from "@/utils/request";
import { UrlLogin, UrlLoginFacebook } from "@/utils/apiUrl";
import { setChatMobile, setUserInfo } from "@/utils/storage";
import { Toast } from "antd-mobile";
import { isEmptyString, isString } from "@/utils/utils";


//类型：请求成功
export const LOGIN_REQ_SUCCESS = "LOGIN_REQ_SUCCESS"

//类型：请求失败
export const LOGIN_REQ_FAIL = "LOGIN_REQ_FAIL"

//类型：清空请求
export const LOGIN_REQ_CLEAR = "LOGIN_REQ_CLEAR"


/**
 * 登录
 * @param {string} mobile 手机号码
 * @param {string} code 验证码
 * @returns thunk
 */
export const doLogin = (mobile, code, directLoginFlag = "NO") => {
    return async dispatch => {
        //来源
        const dreferrer = document.referrer || ""

        //组装参数
        const params = baseParams()
        params.mobile = mobile + ""
        params.code = code + ""
        params.directLoginFlag = directLoginFlag + ""
        params.docReferrer = dreferrer + ""

        console.log("=======docReferrer: " + dreferrer)


        //发起请求
        const res = await http.post(UrlLogin, params)
        const resCode = parseInt(res.code)
        const resObj = res.obj
        const resMsg = res.msg
        console.log("登录回调-结束：=======")

        //保存
        const success = (resCode === 0)
        dispatch(saveLoginState(resObj, success))

        //如果有错误，提示
        if (!success) {
            if (!isEmptyString(resMsg)) {
                Toast.show(resMsg, 1)
            }
        }

        //保存本地
        if (success) {
            //保存号码
            resObj.mobile = mobile + ""
            setUserInfo(resObj)
            setChatMobile(mobile + "")
        }
    }
}


/**
 * fb联合登录
 * @param {string} mobile fb id
 * @returns thunk
 */
export const doFbUnionLogin = (facebookId, pageType = "LOGINPAGE") => {
    return async dispatch => {
        //来源
        const dreferrer = document.referrer || ""

        //组装参数
        const params = baseParams()
        params.facebookUserId = facebookId + ""
        params.pageType = pageType
        params.docReferrer = dreferrer + ""

        console.log("=======docReferrer: " + dreferrer)


        //发起请求
        const res = await http.post(UrlLoginFacebook, params)
        const resCode = parseInt(res.code)
        let resObj = {}
        if (res.obj) {
            if (!isString(res.obj)) {
                resObj = res.obj
            }
        }

        const resMsg = res.msg
        resObj.resCode = resCode
        console.log("登录回调-结束：=======")

        //保存
        const success = (resCode === 0)
        dispatch(saveLoginState(resObj, success))

        //如果有错误，提示
        if ((resCode !== 2002) && (resCode !== 2001)) {
            if (!success) {
                if (!isEmptyString(resMsg)) {
                    Toast.show(resMsg, 1)
                }
            }
        }

        //保存本地
        if (success) {
            //保存号码
            resObj.mobile = facebookId + ""
            setUserInfo(resObj)
            setChatMobile(facebookId + "")
        }
    }
}

/**
 * 将 登录 信息保存到 Redux 中
 * @param {*} data 登录信息 
 * @returns 
 */
export const saveLoginState = (data, success) => {
    return {
        type: success ? LOGIN_REQ_SUCCESS : LOGIN_REQ_FAIL,
        payload: data
    }
}

/**
 * 将 登录 信息保存到 Redux 中
 * @param {*} data 登录信息 
 * @returns 
 */
export const cleanLoginState = () => {
    return {
        type: LOGIN_REQ_CLEAR,
        payload: {}
    }
}