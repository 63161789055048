import { Toast } from "antd-mobile";
import http, { baseParams } from "./request";
import { UrlSaveEvent } from "./apiUrl";

export default class AddHomeEvent {

    static canAddHomeEvent = null

    /** 监听安装桌面事件 */
    static addBeforeInstallPromptEventListener() {
        const ready = (e) => {
            e.preventDefault();
            this.canAddHomeEvent = (e);
        };
        window.addEventListener("beforeinstallprompt", ready);
    }

    /** 处理安装事件点击 */
    static onHandleAddHomeEventClick() {
        if (this.canAddHomeEvent) {
            // return prompt.prompt();
            this.canAddHomeEvent.prompt()
            this.canAddHomeEvent.userChoice.then((choiceResult) => {
                console.log(choiceResult)
                if (choiceResult.outcome === 'accepted') {
                    Toast.show('Add success!');
                    //打点
                    this.doHitEvent("ADD_SCREEN_SUCCESS")
                } else {
                    Toast.show('Not agree install short');
                    this.doHitEvent("ADD_SCREEN_NOT_AGREE")
                }
            });
        }
        else {
            Toast.show("beforeinstallprompt not in window!")
            this.doHitEvent("ADD_SCREEN_NOT_SURPORT")
        }
    }

    // 处理书签事件
    static onHandleAddFavoriteEventClick() {
        navigator.clipboard.writeText("https://www.loansalad.info")
            .then(() => {
                console.log('Text copied to clipboard');
                Toast.show("Copy success")
            })
            .catch(err => {
                console.error('Failed to copy text:', err);
            });
    }


    static async doHitEvent(type, remark = "") {
        try {
            if (type) {
                //组装参数
                const params = baseParams()
                params.type = type
                params.remark = remark

                //发起请求
                await http.post(UrlSaveEvent, params)
                console.log("保存埋点回调-结束：=======" + type)
            }
        } catch (e) {

        }
    }
}