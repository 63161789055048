
import { SEND_DATA_SMS_CODE_REQ_CLEAR, SEND_DATA_SMS_CODE_REQ_FAIL, SEND_DATA_SMS_CODE_REQ_SUCCESS } from "../actions/sendDataSmsCode"

// 初始状态
const initState = {
    status: ""
}

// 操作 获取运营商验证码 状态信息的 reducer 函数
export const sendDataSmsCode = (state = initState, action) => {
    const { type, payload } = action
    //console.log('loan type: ' + type)

    switch (type) {
        case SEND_DATA_SMS_CODE_REQ_SUCCESS: return { ...payload, status: type }
        case SEND_DATA_SMS_CODE_REQ_FAIL: return { status: type }
        case SEND_DATA_SMS_CODE_REQ_CLEAR: return { ...payload, status: type }
        default: return state
    }
}