
import { baseParams } from "@/utils/request";
import { UrlUploadImage } from "@/utils/apiUrl";
import uploadHttp from "@/utils/uploadRequest";
import axios from "axios";
import { Toast } from "antd-mobile";

//类型：请求成功
export const UPLOAD_IMAGE_REQ_SUCCESS = "UPLOAD_IMAGE_REQ_SUCCESS"

//类型：请求失败
export const UPLOAD_IMAGE_REQ_FAIL = "UPLOAD_IMAGE_REQ_FAIL"

/**
 * 上传图片
 * @returns thunk
 */
export const uploadImage = (image, uploadCallback) => {
    return async (dispatch) => {

        console.log("==========")
        console.log(image)

        //组装参数
        const params = baseParams()

        // const file = {
        //     uri: image,
        //     type: "image/jpeg",
        //     name: "filensddame",
        // }

        //压图片
        const formData = new FormData();
        formData.append('image', image);

        //压参数
        for (const key in params) {
            formData.append(key, params[key]);
        }

        //发起请求
        uploadHttp.post(UrlUploadImage, formData)
            .then((response) => {
                // 处理上传成功的响应
                const resCode = parseInt(response.code)
                if (resCode === 0) {
                    console.log('=======图片上传成功');
                    const url = response.obj.url
                    console.log(url);
                    uploadCallback(true, url)
                } else {
                    Toast.show(response.msg || "")
                    uploadCallback(false, "")
                }
            })
            .catch((error) => {
                // 处理上传失败和网络错误
                console.error('上传失败', error);
                Toast.show(error.message || "")
                uploadCallback(false, "")
            });
    }
}
