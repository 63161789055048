import React, { useEffect, useState } from "react";
import './proof.css';
import '../../App.css'
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { Toast, Mask, DotLoading } from "antd-mobile";
import { useDispatch, useSelector } from "react-redux";
import { PROOF_USER_STATE_REQ_SUCCESS, ProofStepAccount, ProofStepBasic, ProofStepWork, getUserState, isActionAddressPick, isActionBankPick, isActionDatePick, isActionInput, isActionSelect, isProofBankAccount, isProofContact, isProofFace, isProofFrequentlyUsedApps, isProofMobile, updateUserState } from "@/store/actions/userState";
import { SAVE_PROOF_REQ_FAIL, resetProofDataFailState, saveProofData } from "@/store/actions/proof";
import { toUnRefApi } from "@/utils/apiUrl";
import FormInput from "@/components/FormInput";
import BankFormSelect from "@/components/BankFormSelect";
import FormSelect from "@/components/FormSelect";
import { BANK_CODE_REQ_SUCCESS, getBankCodeList } from "@/store/actions/bankCode";
import DateFormSelect from "@/components/DateFormSelect";
import AddressFormSelect from "@/components/AddressFormSelect";
import NgnAddressJson from "../../assets/nigeria_city_list.json"
import { isEmptyString, isMustProofString, isNullObject, toEventTagString, toTrimString } from "@/utils/utils";
import { PageTag } from "@/utils/pageTag";
import ProofNavigateBar from "@/components/ProofNavigateBar";
import { saveEvent } from "@/store/actions/saveEvent";
import { isLoanLoaning, isLoanReject, isLoanRepayNow, isLoanReviewing, isLoanSigning } from "@/utils/loanStatus";
import AddFavorite from "@/components/AddFavorite";
import AddToHomeGifDialog from "@/components/AddToHomeGifDialog/AddToHomeGifDialog";
import AddHomeEvent from "@/utils/addHomeEvent";
import PrivacyPolicyLink from "@/components/PrivacyPolicyLink/PrivacyPolicyLink";
import CouponClick from "../Coupon/CouponClick";

/**
 * 认证页
 */
export default function Proof() {

    //loading状态
    const [maskVisible, setMaskVisible] = useState(false)

    //显示add home弹框
    const [addHomeMaskVisible, setAddHomeMaskVisible] = useState(false);
    const [addHomeMaskIsHomeMode, setAddHomeMaskIsHomeMode] = useState(true);

    // 获取路由历史 history 对象
    const history = useHistory()

    // 获取路由信息 location 对象
    const location = useLocation()

    //获取 Redux 分发器
    const dispatch = useDispatch()

    //获取显示状态信息
    const [isContactPage, setIsContactPage] = useState(false);
    const [showBankCodeList, setShowBankCodeList] = useState(false);
    const [formatBankCodeInfo, setFormatBankCodeInfo] = useState([[]]);

    //获取Redux Store中的用户状态信息
    const userStateInfo = useSelector(state => state.userState)

    //获取Redux Store中的proof信息
    const bankCodeInfo = useSelector(state => state.bankCode)

    //获取Redux Store中的用户状态信息
    // const userNotExistInfo = useSelector(state => state.userNotExist)

    //获取Redux Store中的保存状态信息
    const saveProofFailInfo = useSelector(state => state.saveProofFail)

    //更新用户流程状态
    const onUpdateUserState = newState => {
        //更新用户状态
        try {
            //保持非空
            if (isNullObject(newState)) return

            //获取Action
            const action = updateUserState(newState, PageTag.PROOF)

            //触发接口
            dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //输入监听
    const onHandleInputChange = (index, value) => {
        //更新用户状态
        try {
            const newState = { ...userStateInfo }
            const item = newState.dataList[index]
            item.content = value
            item.realContent = value

            //获取Action
            const action = updateUserState(newState, PageTag.PROOF)

            //触发接口
            dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //输入监听-联系人额外亦是
    const onHandleInputChangeForContactExtData = (index, value) => {
        //更新用户状态
        try {
            const newState = { ...userStateInfo }
            const item = newState.extDataList[index]
            item.content = value
            item.realContent = value

            //获取Action
            const action = updateUserState(newState, PageTag.PROOF)

            //触发接口
            dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //输入监听-联系人
    const onHandleInputChangeForContact = (parentIndex, index, value) => {
        //更新用户状态
        try {
            const newState = { ...userStateInfo }
            const section = newState.sectionList[parentIndex]
            const item = section[index]
            item.content = value
            item.realContent = value

            //获取Action
            const action = updateUserState(newState, PageTag.PROOF)

            //触发接口
            dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //点击选择列表监听
    const onHandleSelectedDataForContactExtData = async (index, type, data) => {
        console.log("onHandleSelectedDataForContactExtData: " + type + ", data: " + data);
        //更新用户状态
        try {
            const newState = { ...userStateInfo }
            const item = newState.extDataList[index]
            item.content = data.label
            item.realContent = data.value

            //获取Action
            const action = updateUserState(newState, PageTag.PROOF)

            //触发接口
            dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //点击选择列表监听
    const onHandleSelectedDataForContact = async (parentIndex, index, type, data) => {
        console.log("onHandleSelectedDataForContact: " + type + ", data: " + data);
        //更新用户状态
        try {
            const newState = { ...userStateInfo }
            const section = newState.sectionList[parentIndex]
            const item = section[index]
            item.content = data.label
            item.realContent = data.value

            //获取Action
            const action = updateUserState(newState, PageTag.PROOF)

            //触发接口
            dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //点击选择列表监听
    const onHandleSelectedData = async (index, type, data) => {
        console.log("onHandleSelectedDataClick: " + type + ", data: " + data);
        //更新用户状态
        try {
            const newState = { ...userStateInfo }
            const item = newState.dataList[index]
            item.content = data.label
            item.realContent = data.value

            //获取Action
            const action = updateUserState(newState, PageTag.PROOF)

            //触发接口
            dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //点击选择地址列表监听, state, city, area
    const onHandleSelectedAddress = async (index, type, addressList) => {
        console.log("onHandleSelectedAddress: " + type + ", data: " + addressList);
        //更新用户状态
        try {
            const data = addressList.join(" ")
            const newState = { ...userStateInfo }
            const item = newState.dataList[index]
            item.content = data
            item.realContent = data

            item.stateContent = addressList[0]
            item.cityContent = addressList[1]
            item.areaContent = addressList[2]

            //获取Action
            const action = updateUserState(newState, PageTag.PROOF)

            //触发接口
            dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //点击选择银行列表监听
    const onHandleSelectedBankCode = async (index, type, data) => {
        console.log("onHandleSelectedBankCode: " + type + ", data: " + data);
        //更新用户状态
        try {
            const newState = { ...userStateInfo }
            const item = newState.dataList[index]
            item.content = data.label
            item.realContent = data.value
            item.bankCodeContent = data.value

            //获取Action
            const action = updateUserState(newState, PageTag.PROOF)

            //触发接口
            dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //点击选择BankCode监听
    const onHandleCheckBankCodeList = async (index, type) => {
        console.log("onHandleCheckBankCodeList: " + type);
        checkBankCodeInfo()
    }

    //点击关闭 BankCode 列表弹框
    const onHandleCloseBankCodeList = (index, type) => {
        console.log("onHandleCloseBankCodeList: " + index);
        //更新用户状态
        let newState = { ...userStateInfo }
        let item = newState.dataList[index]
        item.show = false
        onUpdateUserState(newState, PageTag.PROOF)
    }

    //失焦监听
    const onHandleBlur = (index, value) => {
        console.log("onHandleBlur: " + index);
    }

    //点击提交
    const onHandleSubmit = () => {
        //组装参数
        const params = {}

        //组装联系人
        if (isProofContact(userStateInfo.stepTitle)) {

            //判断所有内容是否输入完成
            let sectionFinish = true
            userStateInfo.sectionList.forEach(section => {
                section.forEach(item => {
                    if (isMustProofString(item.isMust)) {
                        if (isEmptyString(item.realContent)) {
                            sectionFinish = false
                        }
                    }
                })
            })
            let extFinish = true
            userStateInfo.extDataList.forEach(item => {
                if (isMustProofString(item.isMust)) {
                    if (isEmptyString(item.realContent)) {
                        extFinish = false
                    }
                }
            })

            if ((!sectionFinish) || (!extFinish)) {
                Toast.show("Please fill all contents.")
                return
            }

            const contactList = []
            userStateInfo.sectionList.forEach(section => {
                const contact = {}
                section.forEach(item => {
                    contact[item.paramName] = toTrimString(item.realContent + "")
                })
                contactList.push(contact)
            })
            params["contactList"] = contactList

            //额外字段
            userStateInfo.extDataList.forEach(item => {
                params[item.paramName] = toTrimString(item.realContent + "")
            })

        } else {
            //组装其它页面
            //判断所有内容是否输入完成
            let allFinish = true
            userStateInfo.dataList.forEach(item => {
                if (isMustProofString(item.isMust)) {
                    if (isEmptyString(item.realContent)) {
                        allFinish = false
                    }

                    //判断城市选择字段
                    if (isActionAddressPick(item.action)) {
                        if ((userStateInfo.stepTitle === ProofStepBasic) ||
                            (userStateInfo.stepTitle === ProofStepWork)) {
                            //基本信息 / 工作信息 
                            if (isEmptyString(item.stateContent)) {
                                allFinish = false
                            }
                            if (isEmptyString(item.cityContent)) {
                                allFinish = false
                            }
                            if (isEmptyString(item.areaContent)) {
                                allFinish = false
                            }
                        }
                    }

                    //判断银行选择字段
                    if (isActionBankPick(item.action)) {
                        if (userStateInfo.stepTitle === ProofStepAccount) {
                            //绑卡信息 
                            if (isEmptyString(item.bankCodeContent)) {
                                allFinish = false
                            }
                        }
                    }
                }
            })

            if ((!allFinish)) {
                Toast.show("Please fill all contents.")
                return
            }

            userStateInfo.dataList.forEach(item => {
                params[item.paramName] = toTrimString(item.realContent + "")

                //判断城市选择字段
                if (isActionAddressPick(item.action)) {
                    if ((userStateInfo.stepTitle === ProofStepBasic) ||
                        (userStateInfo.stepTitle === ProofStepWork)) {
                        //基本信息 / 工作信息 
                        params[item.paramNameState] = toTrimString(item.stateContent + "")
                        params[item.paramNameCity] = toTrimString(item.cityContent + "")
                        params[item.paramNameArea] = toTrimString(item.areaContent + "")
                    }
                }

                //判断银行选择字段
                if (isActionBankPick(item.action)) {
                    if (userStateInfo.stepTitle === ProofStepAccount) {
                        //绑卡信息 
                        params[item.paramNameBankCode] = toTrimString(item.bankCodeContent + "")
                    }
                }
            });
        }
        console.log(params)

        //显示loading
        setMaskVisible(true)

        //保存认证api
        const api = toUnRefApi(userStateInfo.apiUrl)
        console.log(api)

        //获取Action
        const action = saveProofData(api, params, userStateInfo.stepTitle)

        //触发接口
        dispatch(action)
    }

    //点击返回
    const onHandleNavGoBack = () => {
        console.log("go back")
        history.replace('/home')
    }

    //打点接口
    const doHitEvent = async (type, remark = "") => {
        console.log("doHitEvent: " + type + " , " + remark);

        try {
            //获取Action
            const action = saveEvent(type, remark)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //启动时请求首页额度
    useEffect(() => {
        //添加 fb 像素
        // eslint-disable-next-line no-undef
        fbq('track', 'Page_Proof');
        // eslint-disable-next-line no-undef
        gtag('track', 'Page_Proof');

        // 优惠券入口
        CouponClick.fromParentPage = PageTag.PROOF

        //获取用户状态
        checkUserStateInfo()

        //打点
        doHitEvent("PROOF_START")

        return () => {
            //打点
            doHitEvent("PROOF_END")
        }
    }, [])

    // 保存失败状态返回监听
    useEffect(() => {
        try {
            //隐藏loading
            setMaskVisible(false)

            console.log("Proof-保存失败状态返回监听")
            console.log(saveProofFailInfo)

            if (saveProofFailInfo.status === SAVE_PROOF_REQ_FAIL) {
                try {
                    //获取Action
                    const action = resetProofDataFailState()

                    //触发接口
                    dispatch(action)

                } catch (e) {
                    console.log(e)
                }
            }

        } catch (e) {
            console.log(e)
        }
    }, [saveProofFailInfo])

    // 查询用户流程状态返回监听
    useEffect(() => {
        try {
            console.log("Proof-查询用户流程状态返回监听")
            console.log(userStateInfo)

            if (userStateInfo.status === PROOF_USER_STATE_REQ_SUCCESS) {
                //隐藏loading
                setMaskVisible(false)
                console.log("Launch-signOrderId: " + userStateInfo.signOrderId)
    
                //是否为签约中
                const isHomeSigning = isLoanSigning(userStateInfo.signFlag, userStateInfo.signOrderId)
                //是否为审核中
                const isHomeReviewing = isLoanReviewing(userStateInfo.applyStatus)
                //是否为拒绝
                const isHomeReject = isLoanReject(userStateInfo.applyStatus, userStateInfo.orderStatus)
                //是否为放款中
                const isHomeLoaning = isLoanLoaning(userStateInfo.orderStatus)
                //是否为待还款
                const isHomeRepay = isLoanRepayNow(userStateInfo.orderStatus)
                //是否为待进件
                const isHomeNotLoan = ((!isHomeReviewing) && (!isHomeReject) && (!isHomeLoaning) && (!isHomeRepay))
                console.log("Launch-isHomeReviewing: " + isHomeReviewing)
                console.log("Launch-isHomeReject: " + isHomeReject)
                console.log("Launch-isHomeLoaning: " + isHomeLoaning)
                console.log("Launch-isHomeRepay: " + isHomeRepay)
                console.log("Launch-isHomeNotLoan: " + isHomeNotLoan)
                console.log("Launch-isHomeSigning: " + isHomeSigning)

                //跳转状态页
                if (isHomeSigning) {
                    history.replace('/guide')
                    return

                } else if (isHomeReviewing) {
                    history.replace('/review')
                    return

                } else if (isHomeReject) {
                    history.replace('/reject')
                    return

                } else if (isHomeLoaning) {
                    history.replace('/loaning')
                    return

                } else if (isHomeRepay) {
                    history.replace('/repay')
                    return
                }

                //判断下是否已经做完所有认证
                if (userStateInfo.isProofFinish === true) {
                    //跳转申请页
                    history.replace('/apply')
                    return
                }

                //是否为号码认证
                if (isProofMobile(userStateInfo.stepTitle)) {
                    //跳转申请页
                    history.replace('/proofmobile')
                    return
                }

                //是否为常用app认证
                if (isProofFrequentlyUsedApps(userStateInfo.stepTitle)) {
                    //跳转申请页
                    history.replace('/prooffrequentlyapps')
                    return
                }

                //是否为人脸认证
                if (isProofFace(userStateInfo.stepTitle)) {
                    //跳转申请页
                    history.replace('/proofface')
                    return
                }

                //是否为联系人页
                const isContactPage = isProofContact(userStateInfo.stepTitle)
                setIsContactPage(isContactPage)

                //是否为绑卡页r
                //const isBankAccountPage = isProofBankAccount(userStateInfo.stepTitle)
                setShowBankCodeList(false)
            }

        } catch (e) {
            console.log(e)
        }
    }, [userStateInfo])

    //查询银行列表返回
    useEffect(() => {
        console.log("Proof-查询银行列表返回监听")
        console.log(bankCodeInfo)

        // 隐藏loading
        setMaskVisible(false)

        if (bankCodeInfo.status === BANK_CODE_REQ_SUCCESS) {

            //初始化银行列表
            const formatBankCodeInfo = Array.isArray(bankCodeInfo.list) ? bankCodeInfo.list : [[]]
            console.log(formatBankCodeInfo)
            setFormatBankCodeInfo(formatBankCodeInfo)

            //更新显示状态
            setShowBankCodeList(true)
            // let newState = { ...userStateInfo }
            // newState.dataList.forEach(item => {
            //     //判断银行选择字段
            //     if (isActionBankPick(item.action)) {
            //         item.show = true
            //     }
            // })
            // onUpdateUserState(newState, PageTag.PROOF)
        }
    }, [bankCodeInfo])

    //监听用户是否存在
    // useEffect(() => {
    //     if (userNotExistInfo.notExist === true) {
    //         //history.replace('/login')
    //         history.replace('/loginfb')
    //     }
    // }, [userNotExistInfo])

    //查询用户流程状态
    const checkUserStateInfo = async () => {
        console.log("Proof-checkUserStateInfo: ");

        //获取用户状态
        try {
            //显示loading
            setMaskVisible(true)

            //获取Action
            const action = getUserState(PageTag.PROOF)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //查询bank code list
    const checkBankCodeInfo = async () => {
        console.log("Proof-checkBankCodeInfo: ");

        //获取用户状态
        try {
            //显示loading
            setMaskVisible(true)

            let isArray = Array.isArray(bankCodeInfo)
            if (!isArray || (bankCodeInfo.length === 0)) {

                //获取Action
                const action = getBankCodeList()

                //触发接口
                await dispatch(action)
            }

        } catch (e) {
            console.log(e)
        }
    }

    // 点击add home监听
    const onHandleAddToHome = (type) => {
        console.log(`点击add home监听: ${type}`)
        if (type === 'home') {
            AddHomeEvent.onHandleAddHomeEventClick()
        } else {
            AddHomeEvent.onHandleAddFavoriteEventClick()
        }
    }

    // 关闭add home
    const onHandleAddToHomeClose = () => {
        console.log(`点击close add home监听`)
        setAddHomeMaskVisible(false)
    }

    // 刷新操作
    const onHandleRefresh = () => {
        //获取用户状态
        checkUserStateInfo()
    }

    return (
        <div className="proofRoot">
            {/* <NavBar onBack={() => { history.goBack() }} style={{ 'width': '100%', 'backgroundColor': 'white' }}>Proof</NavBar> */}
            <ProofNavigateBar theme="white" onBackClick={onHandleNavGoBack} onAddFavorite={() => { setAddHomeMaskIsHomeMode(false); setAddHomeMaskVisible(true) }} onAddHome={() => { setAddHomeMaskIsHomeMode(true); setAddHomeMaskVisible(true) }} onRefresh={onHandleRefresh}>{userStateInfo.pageTitle}</ProofNavigateBar>

            {/* 使用添加 home screen bar */}
            {/* <AddFavorite onAddHome={() => { setAddHomeMaskIsHomeMode(true); setAddHomeMaskVisible(true) }} /> */}

            {/* <div className="proofContentScroll"> */}
            <div className="proofContent">
                {/* 联系人-额外输入 */}
                {isContactPage && userStateInfo.extDataList && userStateInfo.extDataList.map((value, index) => {
                    return (
                        <div className="proofItemList" key={index}>
                            {(isActionSelect(value.action) === true) && <FormSelect
                                placeholder={value.hint}
                                value={value.content}
                                title={value.title}
                                list={value.pickValues}
                                onSelectedDataClick={e => { onHandleSelectedDataForContactExtData(index, value.paramName, e) }}
                                autoComplete="off" />}

                            {(isActionInput(value.action) === true) && <FormInput
                                placeholder={value.hint}
                                value={value.content}
                                title={value.title}
                                onBlur={e => { onHandleBlur(index, e.target.value) }}
                                onChange={e => { onHandleInputChangeForContactExtData(index, e) }}
                                autoComplete="off" />}
                        </div>
                    )
                })}
                {/* 联系人-常量 */}
                {isContactPage && userStateInfo.sectionList && userStateInfo.sectionList.map((value, index) => {
                    return (
                        <div className="contentColumnStart" key={index}>
                            <div className="proofEdgeTopWhite"></div>
                            <div className="proofEdgeTopMainBackground"></div>
                            {(value.map((itemValue, itemIndex) => {
                                return (
                                    <div className="proofItemList" key={index + "_" + itemIndex}>
                                        {(isActionSelect(itemValue.action) === true) && <FormSelect
                                            placeholder={itemValue.hint}
                                            value={itemValue.content}
                                            title={itemValue.title}
                                            list={itemValue.pickValues}
                                            onSelectedDataClick={e => { onHandleSelectedDataForContact(index, itemIndex, itemValue.paramName, e) }}
                                            autoComplete="off" />}

                                        {(isActionInput(itemValue.action) === true) && <FormInput
                                            placeholder={itemValue.hint}
                                            value={itemValue.content}
                                            title={itemValue.title}
                                            onBlur={e => { onHandleBlur(itemIndex, e.target.value) }}
                                            onChange={e => { onHandleInputChangeForContact(index, itemIndex, e) }}
                                            autoComplete="off" />}
                                    </div>
                                )
                            }))}
                        </div>
                    )
                })}

                {/* 其它 */}
                {(!isContactPage) && userStateInfo.dataList && userStateInfo.dataList.map((value, index) => {
                    return (
                        <div className="proofItemList" key={index}>

                            {(isActionSelect(value.action) === true) && <FormSelect
                                placeholder={value.hint}
                                value={value.content}
                                title={value.title}
                                list={value.pickValues}
                                onBlur={e => { onHandleBlur(index, e.target.value) }}
                                onChange={e => { onHandleInputChange(index, e) }}
                                onSelectedDataClick={e => { onHandleSelectedData(index, value.paramName, e) }}
                                autoComplete="off" />}

                            {(isActionInput(value.action) === true) && <FormInput
                                placeholder={value.hint}
                                value={value.content}
                                title={value.title}
                                onBlur={e => { onHandleBlur(index, e.target.value) }}
                                onChange={e => { onHandleInputChange(index, e) }}
                                autoComplete="off" />}

                            {(isActionAddressPick(value.action) === true) && <AddressFormSelect
                                placeholder={value.hint}
                                value={value.content}
                                title={value.title}
                                list={NgnAddressJson}
                                onChange={e => { onHandleInputChange(index, e) }}
                                onSelectedDataClick={e => { onHandleSelectedAddress(index, value.paramName, e) }}
                                autoComplete="off" />}

                            {(isActionDatePick(value.action) === true) && <DateFormSelect
                                placeholder={value.hint}
                                value={value.content}
                                title={value.title}
                                showDatePicker={value.show}
                                onChange={e => { onHandleInputChange(index, e) }}
                                onSelectedDataClick={e => { onHandleSelectedData(index, value.paramName, e) }}
                                autoComplete="off" />}

                            {(isActionBankPick(value.action) === true) && <BankFormSelect
                                placeholder={value.hint}
                                value={value.content}
                                title={value.title}
                                showPicker={showBankCodeList}
                                list={formatBankCodeInfo}
                                onCloseClick={e => { onHandleCloseBankCodeList(index, value.paramName) }}
                                onCheckDataClick={e => { onHandleCheckBankCodeList(index, value.paramName) }}
                                onSelectedDataClick={e => { onHandleSelectedBankCode(index, value.paramName, e) }}
                                autoComplete="off" />}
                        </div>
                    )
                })}
            </div>

            {/* 点击确定 */}
            <div className="proofApplyBtn" onClick={onHandleSubmit}>
                <div className="mainButtonGradient">Next</div>
            </div>

            {/* privacy policy */}
            <PrivacyPolicyLink pageTag={userStateInfo.pageTitle} showCheckbox={false} linkText="Privacy policy" />

            {/* </div> */}
            {/* add home gif dialog */}
            <AddToHomeGifDialog visible={addHomeMaskVisible} onConfirm={onHandleAddToHome} onClose={onHandleAddToHomeClose} isHomeMode={addHomeMaskIsHomeMode} />

            <Mask visible={maskVisible} opacity={0.1}
                onMaskClick={() => { }}>
                <div className="overlayContent">
                    <span style={{ fontSize: 24, color: '#ff3141' }} >
                        <DotLoading color="currentColor" />
                    </span>
                </div>
            </Mask>
        </div>
    )
}