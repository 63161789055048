
import { UrlSaveEvent } from "./apiUrl";
import http, { baseParams } from "./request";
import { getChatMobile, getUserInfo } from "./storage";
import { isEmptyString } from "./utils";


export default class SmartChat {

    //初始化
    static async init() {
        //从缓存读数据
        const userInfo = getUserInfo()
        const gUserMobile = userInfo.mobile
        if (!isEmptyString(gUserMobile)) {
            this.saveUserInfo(gUserMobile)

        } else {
            const gMobile = getChatMobile()
            if (!isEmptyString(gMobile)) {
                this.saveUserInfo(gMobile)
            }
        }

        this.saveUserSend()
        this.saveUserReceive()
        this.saveChatDialogOpen()
        this.saveChatDialogClose()
    }

    //保存用户数据
    static async saveUserInfo(mobile) {
        try {
            if (mobile) {
                // eslint-disable-next-line no-undef
                ssq.push('setLoginInfo', {
                    user_id: mobile, // 必填，加密后的用户 id
                    user_name: mobile, // 必填，用户名
                    language: 'en', // 插件语言
                    phone: '+234' + mobile, // 手机（注意！带上手机区号）
                    email: "", // 邮箱
                    description: 'user info', // 描述
                });
            }
        } catch (e) {

        }
    }

    //记录用户发送内容
    static async saveUserSend() {
        try {
            // eslint-disable-next-line no-undef
            ssq.push('onSendMessage', function (obj) {
                console.log(obj);
                const dataJsonStr = JSON.stringify(obj)
                //上传数据
                const params = baseParams()
                params.type = "SMART_CHAT_SEND"
                params.remark = dataJsonStr

                //发起请求
                http.post(UrlSaveEvent, params)
            });
        } catch (e) {

        }
    }

    //记录用户接收内容
    static async saveUserReceive() {
        try {
            // eslint-disable-next-line no-undef
            ssq.push('onReceiveMessage', function (obj) {
                console.log(obj);
                const dataJsonStr = JSON.stringify(obj)
                //上传数据
                const params = baseParams()
                params.type = "SMART_CHAT_RECEIVE"
                params.remark = dataJsonStr

                //发起请求
                http.post(UrlSaveEvent, params)
            });
        } catch (e) {

        }
    }

    //记录聊天窗口打开
    static async saveChatDialogOpen() {
        try {
            // eslint-disable-next-line no-undef
            ssq.push('onOpenChat', function () {
                console.log("onOpenChat");

                // 上报数据
                const params = baseParams()
                params.type = "SMART_CHAT_OPEN"
                params.remark = ''

                //发起请求
                http.post(UrlSaveEvent, params)
            });
        }
        catch (e) {

        }
    }

    //记录聊天窗口关闭
    static async saveChatDialogClose() {
        try {
            // eslint-disable-next-line no-undef
            ssq.push('onCloseChat', function () {
                console.log("onCloseChat");

                // 上报数据
                const params = baseParams()
                params.type = "SMART_CHAT_CLOSE"
                params.remark = ''

                //发起请求
                http.post(UrlSaveEvent, params)
            });
        }
        catch (e) {

        }
    }
}