import React, { useState } from "react";
import '../FormSelect/form.css'
import { DatePicker, Input } from "antd-mobile";
import Icon from "../Icon";
import icon_down_black from "@/assets/icon_down_black.png"

export default function DateFormSelect({ title, showDatePicker, dateSelected, onCloseClick, onSelectedDataClick, ...rest }) {

    //最大日期
    // const now = new Date()
    // const start = new Date("1953-01-01 00:00:00")

    //设置日期显示状态
    const [showPicker, setShowPicker] = useState(false)

    //设置选择日期回调
    const onHandleSelectedDate = (date) => {
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let formatDate = year + "-" + (month >= 10 ? (month + "") : ("0" + month)) + "-" + (day >= 10 ? (day + "") : ("0" + day))
        console.log(formatDate)
        const item = { label: formatDate, value: formatDate }
        onSelectedDataClick(item)
    }

    //关闭日期显示
    const onHandleCloseDate = () => {
        setShowPicker(false)
    }

    return (
        <div className="formContent">
            <span className="formTitle">{title}</span>
            <div className="formValueContent" onClick={() => { setShowPicker(true) }} >
                <Input className="formValue"  {...rest} readOnly={true} />
                <Icon picUrl={icon_down_black} onItemClick={() => { setShowPicker(true) }}></Icon>
            </div>
            <div className="formSplitLine" hidden></div>
            <DatePicker
                className="formPicker"
                mode='date'
                title='Select Date'
                format='YYYY-MM-DD'
                cancelText="Cancel"
                confirmText="Confirm"
                visible={showPicker}
                onClose={onHandleCloseDate}
                min={new Date(1953, 0, 1, 0, 0, 0)}
                max={new Date(2023, 0, 1, 0, 0, 0)}
                onConfirm={onHandleSelectedDate}
            />
        </div >
    )
}



