import { COUPON_DETAIL_REQ_FAIL, COUPON_DETAIL_REQ_SUCCESS } from "../actions/couponDetail"


// 初始状态
const initState = {
}

// 操作 Token 状态信息的 reducer 函数
export const couponDetail = (state = initState, action) => {
    const { type, payload } = action
    //console.log('loan type: ' + type)

    switch (type) {
        case COUPON_DETAIL_REQ_SUCCESS: return { ...payload, status: type }
        case COUPON_DETAIL_REQ_FAIL: return { status: type }
        default: return state
    }
}