import React, { useEffect, useState } from 'react';
import './textcarouselhorizontal.css'
import FacebookData from '@/utils/facebookData';

const TextCarouselHorizontal = () => {

  //展示数据
  const [nameList, setNameList] = useState([]);

  useEffect(() => {
    const list = []
    if (FacebookData.facebookUserFriends) {
      FacebookData.facebookUserFriends.forEach(item => {
        const name = item.name
        if (name) {
          list.push(name)
        }
      })
    }
    
    if (list.length === 0) {
      if (FacebookData.facebookUserInfo) {
        const name = FacebookData.facebookUserInfo.name
        list.push(name)
      }
    }

    //更新数据
    setNameList(list)

  }, []);

  return (
    <div className="text-carousel-horizontal">
      <div className="text-container">
        {nameList.map((text, index) => (
          <p key={index} className='horizontalText horizontalTextRightMargin6Px'>{text}</p>
        ))}
      </div>
    </div>
  );
};

export default TextCarouselHorizontal;
