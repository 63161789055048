
import { SAVE_PROOF_REQ_FAIL } from "../actions/proof"

// 初始状态
const initState = {
    status: ""
}

// 操作 Token 状态信息的 reducer 函数
export const saveProofFail = (state = initState, action) => {
    const { type, payload } = action

    switch (type) {
        case SAVE_PROOF_REQ_FAIL: return { ...payload, status: type }
        default: return state
    }
}