import { SEND_COPY_FAIL_REQ_CLEAR, SEND_COPY_FAIL_REQ_FAIL, SEND_COPY_FAIL_REQ_SUCCESS } from "../actions/sendCopyFailSms"



// 初始状态
const initState = {
    status: ""
}

// 操作 短信发送 状态信息的 reducer 函数
export const sendCopyFailSms = (state = initState, action) => {
    const { type, payload } = action
    //console.log('loan type: ' + type)

    switch (type) {
        case SEND_COPY_FAIL_REQ_SUCCESS: return { ...payload, status: type }
        case SEND_COPY_FAIL_REQ_FAIL: return { ...payload, status: type }
        case SEND_COPY_FAIL_REQ_CLEAR: return { status: type }
        default: return state
    }
}