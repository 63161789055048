import React, { useEffect, useState } from "react";
import '../proof.css';
import '../../Login/login.css';
import '../../../App.css'
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { Mask, DotLoading, Toast } from "antd-mobile";
import { useDispatch, useSelector } from "react-redux";
import { PROOF_FREQUENTLY_APPS_USER_STATE_REQ_FAIL, PROOF_FREQUENTLY_APPS_USER_STATE_REQ_SUCCESS, getUserState, isProofFace, isProofFrequentlyUsedApps, isProofMobile } from "@/store/actions/userState";
import { PageTag } from "@/utils/pageTag";
import ProofNavigateBar from "@/components/ProofNavigateBar";
import { saveEvent } from "@/store/actions/saveEvent";
import { SAVE_FREQUENTYLY_APPS_REQ_FAIL, resetProofFrequentlyAppsDataFailState, saveFrequentlyApps } from "@/store/actions/saveFrequentlyApps";
import icon_logo_aliexpress from '@/assets/icon_logo_aliexpress.png'
import icon_logo_bolt_food from '@/assets/icon_logo_bolt_food.png'
import icon_logo_cryptomania from '@/assets/icon_logo_cryptomania.png'
import icon_logo_facebook from '@/assets/icon_logo_facebook.png'
import icon_logo_google_chat from '@/assets/icon_logo_google_chat.png'
import icon_logo_google_meet from '@/assets/icon_logo_google_meet.png'
import icon_logo_instagram from '@/assets/icon_logo_instagram.png'
import icon_logo_jiji_nigeria from '@/assets/icon_logo_jiji_nigeria.png'
import icon_logo_jumia_food from '@/assets/icon_logo_jumia_food.png'
import icon_logo_jumia_shopping from '@/assets/icon_logo_jumia_shopping.png'
import icon_logo_linkedin from '@/assets/icon_logo_linkedin.png'
import icon_logo_telegramx from '@/assets/icon_logo_telegramx.png'
import icon_logo_tiktok from '@/assets/icon_logo_tiktok.png'
import icon_logo_translate_all_languages from '@/assets/icon_logo_translate_all_languages.png'
import icon_logo_x from '@/assets/icon_logo_x.png'
import icon_app_selected from '@/assets/icon_app_selected.png'
import { isLoanLoaning, isLoanReject, isLoanRepayNow, isLoanReviewing, isLoanSigning } from "@/utils/loanStatus";
import { isEmptyString, toTrimString } from "@/utils/utils";
import AddFavorite from "@/components/AddFavorite";
import AddHomeEvent from "@/utils/addHomeEvent";
import AddToHomeGifDialog from "@/components/AddToHomeGifDialog/AddToHomeGifDialog";
import PrivacyPolicyLink from "@/components/PrivacyPolicyLink/PrivacyPolicyLink";
import CouponClick from "@/pages/Coupon/CouponClick";



/**
 * 认证号码页
 */
export default function ProofFrequentlyApps() {

    //loading状态
    const [maskVisible, setMaskVisible] = useState(false)

    //显示add home弹框
    const [addHomeMaskVisible, setAddHomeMaskVisible] = useState(false);
    const [addHomeMaskIsHomeMode, setAddHomeMaskIsHomeMode] = useState(true);

    // 获取路由历史 history 对象
    const history = useHistory()

    // 获取路由信息 location 对象
    const location = useLocation()

    //获取 Redux 分发器
    const dispatch = useDispatch()

    //获取Redux Store中的用户状态信息
    const userStateInfo = useSelector(state => state.userState)

    //获取Redux Store中的用户状态信息
    // const userNotExistInfo = useSelector(state => state.userNotExist)

    //获取Redux Store中的保存状态信息
    const saveFrequentlyAppsFailInfo = useSelector(state => state.saveFrequentlyAppsFail)

    //常用app列表状态
    const [appList, setAppList] = useState([])


    //查询用户流程状态
    const checkUserStateInfo = async () => {
        console.log("ProofFrequentlyApps-checkUserStateInfo: ");

        //获取用户状态
        try {
            //显示loading
            setMaskVisible(true)

            //获取Action
            const action = getUserState(PageTag.PROOF_FREQUENTLY_APPS)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //请求保存接口
    const doSaveApps = async () => {
        console.log("ProofFrequentlyApps-doSaveApps: ");

        try {
            //显示loading
            setMaskVisible(true)

            //找到选中的项
            let appListContent = ""
            appList.forEach(item => {
                if (item.selected) {
                    if (appListContent.length > 0) {
                        appListContent = appListContent + "," + item.label
                    } else {
                        appListContent = item.label
                    }
                }
            })
            appListContent = toTrimString(appListContent + "")

            //保存打点
            doHitEvent("PROOF_FREQUENTLY_APPS_COMMIT_CLICK", appListContent)

            //拦截
            if (isEmptyString(appListContent)) {
                //隐藏loading
                setMaskVisible(false)

                Toast.show("Please select at least one app")
            } else {
                //获取Action
                const action = saveFrequentlyApps(appListContent)

                //触发接口
                await dispatch(action)
            }
        } catch (e) {
            console.log(e)
        }
    }

    //点击app
    const onHandleAppClick = (index, selected) => {
        if (appList.length > index) {
            const list = [...appList]
            const item = list[index]
            item.selected = !selected

            //更新列表
            setAppList(list)

            if (!selected) {
                //保存打点
                doHitEvent("PROOF_FREQUENTLY_APPS_CLICK", item.label)
            }
        }
    }

    //点击提交
    const onHandleSubmit = async () => {
        try {
            //请求登录
            doSaveApps()

        } catch (e) {
            console.log(e)
        }
    }

    //点击返回
    const onHandleNavGoBack = () => {
        console.log("go back")
        history.goBack()
    }

    //打点接口
    const doHitEvent = async (type, remark = "") => {
        console.log("doHitEvent: " + type + " , " + remark);

        try {
            //获取Action
            const action = saveEvent(type, remark)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //初始化app列表
    const initAppList = () => {
        const list = [
            { label: "TikTok", icon: icon_logo_tiktok, selected: false },
            { label: "Jumia Food", icon: icon_logo_jumia_food, selected: false },
            { label: "Google Chat", icon: icon_logo_google_chat, selected: false },
            { label: "JUMIA Online Shopping", icon: icon_logo_jumia_shopping, selected: false },
            { label: "X", icon: icon_logo_x, selected: false },
            { label: "Cryptomania", icon: icon_logo_cryptomania, selected: false },
            { label: "Jiji Nigeria", icon: icon_logo_jiji_nigeria, selected: false },
            { label: "Instagram", icon: icon_logo_instagram, selected: false },
            { label: "LinkedIn", icon: icon_logo_linkedin, selected: false },
            { label: "Bolt Food", icon: icon_logo_bolt_food, selected: false },
            { label: "AliExpress", icon: icon_logo_aliexpress, selected: false },
            { label: "Facebook", icon: icon_logo_facebook, selected: false },
            { label: "Telegram X", icon: icon_logo_telegramx, selected: false },
            { label: "Google Meet", icon: icon_logo_google_meet, selected: false },
            { label: "Translate All Languages", icon: icon_logo_translate_all_languages, selected: false }
        ]
        setAppList(list)
    }

    // 点击add home监听
    const onHandleAddToHome = (type) => {
        console.log(`点击add home监听: ${type}`)
        if (type === 'home') {
            AddHomeEvent.onHandleAddHomeEventClick()
        } else {
            AddHomeEvent.onHandleAddFavoriteEventClick()
        }
    }

    // 关闭add home
    const onHandleAddToHomeClose = () => {
        console.log(`点击close add home监听`)
        setAddHomeMaskVisible(false)
    }

    // 刷新操作
    const onHandleRefresh = () => {
        //获取用户状态
        checkUserStateInfo()
    }

    //启动时请求首页额度
    useEffect(() => {

        // 优惠券入口
        CouponClick.fromParentPage = PageTag.PROOF_FREQUENTLY_APPS

        //初始化列表
        initAppList()

        //获取用户状态
        checkUserStateInfo()

        //打点
        doHitEvent("PROOF_FREQUENTLY_APPS_START")

        return () => {
            //打点
            doHitEvent("PROOF_FREQUENTLY_APPS_END")
        }
    }, [])

    // 查询用户流程状态返回监听
    useEffect(() => {
        try {
            console.log("ProofFrequentlyApps-查询用户流程状态返回监听")
            console.log(userStateInfo)

            if (userStateInfo.status === PROOF_FREQUENTLY_APPS_USER_STATE_REQ_SUCCESS) {
                //隐藏loading
                setMaskVisible(false)
                console.log("Launch-signOrderId: " + userStateInfo.signOrderId)

                //是否为签约中
                const isHomeSigning = isLoanSigning(userStateInfo.signFlag, userStateInfo.signOrderId)
                //是否为审核中
                const isHomeReviewing = isLoanReviewing(userStateInfo.applyStatus)
                //是否为拒绝
                const isHomeReject = isLoanReject(userStateInfo.applyStatus, userStateInfo.orderStatus)
                //是否为放款中
                const isHomeLoaning = isLoanLoaning(userStateInfo.orderStatus)
                //是否为待还款
                const isHomeRepay = isLoanRepayNow(userStateInfo.orderStatus)
                //是否为待进件
                const isHomeNotLoan = ((!isHomeReviewing) && (!isHomeReject) && (!isHomeLoaning) && (!isHomeRepay))
                console.log("Launch-isHomeReviewing: " + isHomeReviewing)
                console.log("Launch-isHomeReject: " + isHomeReject)
                console.log("Launch-isHomeLoaning: " + isHomeLoaning)
                console.log("Launch-isHomeRepay: " + isHomeRepay)
                console.log("Launch-isHomeNotLoan: " + isHomeNotLoan)
                console.log("Launch-isHomeSigning: " + isHomeSigning)

                //跳转状态页
                if (isHomeSigning) {
                    history.replace('/guide')
                    return

                } else if (isHomeReviewing) {
                    history.replace('/review')
                    return

                } else if (isHomeReject) {
                    history.replace('/reject')
                    return

                } else if (isHomeLoaning) {
                    history.replace('/loaning')
                    return

                } else if (isHomeRepay) {
                    history.replace('/repay')
                    return
                }

                //判断下是否已经做完所有认证
                if (userStateInfo.isProofFinish === true) {
                    //跳转申请页
                    history.replace('/apply')
                    return
                }

                //是否为号码认证
                if (isProofMobile(userStateInfo.stepTitle)) {
                    //跳转申请页
                    history.replace('/proofmobile')
                    return
                }

                //是否为人脸认证
                if (isProofFace(userStateInfo.stepTitle)) {
                    //跳转申请页
                    history.replace('/proofface')
                    return
                }

                //是否为常用app认证
                if (!isProofFrequentlyUsedApps(userStateInfo.stepTitle)) {
                    //跳转申请页
                    history.replace('/proof')
                    return
                }

            } else if (userStateInfo.status === PROOF_FREQUENTLY_APPS_USER_STATE_REQ_FAIL) {
                //隐藏loading
                setMaskVisible(false)
            }

        } catch (e) {
            console.log(e)
        }
    }, [userStateInfo])


    //监听用户是否存在
    // useEffect(() => {
    //     if (userNotExistInfo.notExist === true) {
    //         //history.replace('/login')
    //         history.replace('/loginfb')
    //     }
    // }, [userNotExistInfo])

    // 保存失败状态返回监听
    useEffect(() => {
        try {
            console.log("ProofFrequentlyApps-保存失败状态返回监听")
            console.log(saveFrequentlyAppsFailInfo)

            if (saveFrequentlyAppsFailInfo.status === SAVE_FREQUENTYLY_APPS_REQ_FAIL) {
                //隐藏loading
                setMaskVisible(false)

                try {
                    //获取Action
                    const action = resetProofFrequentlyAppsDataFailState()

                    //触发接口
                    dispatch(action)

                } catch (e) {
                    console.log(e)
                }
            }

        } catch (e) {
            console.log(e)
        }
    }, [saveFrequentlyAppsFailInfo])

    return (
        <div className="proofRoot">
            {/* <NavBar onBack={() => { history.goBack() }} style={{ 'width': '100%', 'backgroundColor': 'white' }}>Proof</NavBar> */}
            <ProofNavigateBar theme="white" onBackClick={onHandleNavGoBack} onAddFavorite={() => { setAddHomeMaskIsHomeMode(false); setAddHomeMaskVisible(true) }} onAddHome={() => { setAddHomeMaskIsHomeMode(true); setAddHomeMaskVisible(true) }} onRefresh={onHandleRefresh}>{userStateInfo.pageTitle}</ProofNavigateBar>

            {/* 使用添加 home screen bar */}
            {/* <AddFavorite onAddHome={() => { setAddHomeMaskIsHomeMode(true);  setAddHomeMaskVisible(true) }} /> */}

            <div className="proofContent ">
                <div className="proofAppList proofTopMargin16Px">
                    {appList.map((value, index) => {
                        if (value.selected) {
                            return (
                                <div className="proofAppContent proofAppContentSelected" key={index} onClick={() => { onHandleAppClick(index, true) }}>
                                    <div className="proofAppIconSelectedContent">
                                        <img className="proofAppIconSelected" src={icon_app_selected} alt="" />
                                    </div>
                                    <img className="proofAppIcon" src={value.icon} alt="" />
                                    <span className="proofAppName">{value.label}</span>
                                </div>
                            )
                        } else {
                            return (
                                <div className="proofAppContent" key={index} onClick={() => { onHandleAppClick(index, false) }}>
                                    <div className="proofAppIconSelectedContent">
                                        <div className="proofAppIconUnSelect" ></div>
                                    </div>
                                    <img className="proofAppIcon" src={value.icon} alt="" />
                                    <span className="proofAppName">{value.label}</span>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>

            {/* 点击确定 */}
            <div className="proofApplyBtn" onClick={onHandleSubmit}>
                <div className="mainButtonGradient">Next</div>
            </div>

            {/* privacy policy */}
            <PrivacyPolicyLink pageTag={userStateInfo.pageTitle} showCheckbox={false} linkText="Privacy policy" />

            {/* </div> */}
            {/* add home gif dialog */}
            <AddToHomeGifDialog visible={addHomeMaskVisible} onConfirm={onHandleAddToHome} onClose={onHandleAddToHomeClose} isHomeMode={addHomeMaskIsHomeMode} />

            <Mask visible={maskVisible} opacity={0.1}
                onMaskClick={() => { }}>
                <div className="overlayContent">
                    <span style={{ fontSize: 24, color: '#ff3141' }} >
                        <DotLoading color="currentColor" />
                    </span>
                </div>
            </Mask>
        </div>
    )
}