import React, { useEffect, useState } from "react";
import '../loan.css'
import '../../../App.css'
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Mask, DotLoading } from "antd-mobile";
import { useDispatch, useSelector } from "react-redux";
import { LOANING_USER_STATE_REQ_FAIL, LOANING_USER_STATE_REQ_SUCCESS, getUserState } from "@/store/actions/userState";
import loan_loaning from "@/assets/loan_loaning.png"
import { isLoanLoaning, isLoanReject, isLoanRepayNow, isLoanReviewing, isLoanSigning } from "@/utils/loanStatus";
import { PageTag } from "@/utils/pageTag";
import NavigateBar from "@/components/NavigateBar";
import { saveEvent } from "@/store/actions/saveEvent";
import AddFavorite from "@/components/AddFavorite";
import AddHomeEvent from "@/utils/addHomeEvent";
import AddToHomeGifDialog from "@/components/AddToHomeGifDialog/AddToHomeGifDialog";
import CouponClick from "@/pages/Coupon/CouponClick";

/**
 * 审核中页
 */
export default function Loaning() {

    //loading状态
    const [maskVisible, setMaskVisible] = useState(false)

    //显示add home弹框
    const [addHomeMaskVisible, setAddHomeMaskVisible] = useState(false);
    const [addHomeMaskIsHomeMode, setAddHomeMaskIsHomeMode] = useState(true);

    // 获取路由历史 history 对象
    const history = useHistory()

    //获取 Redux 分发器
    const dispatch = useDispatch()

    //获取Redux Store中的用户状态信息
    const userStateInfo = useSelector(state => state.userState)

    //获取Redux Store中的用户状态信息
    // const userNotExistInfo = useSelector(state => state.userNotExist)

    //查询用户流程状态
    const checkUserStateInfo = async () => {
        console.log("LOAING-checkUserStateInfo: ");

        //获取用户状态
        try {
            //显示loading
            setMaskVisible(true)

            //获取Action
            const action = getUserState(PageTag.LOANING)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //打点接口
    const doHitEvent = async (type, remark = "") => {
        console.log("doHitEvent: " + type + " , " + remark);

        try {
            //获取Action
            const action = saveEvent(type, remark)

            //触发接口
            await dispatch(action)

        } catch (e) {
            console.log(e)
        }
    }

    //点击提交
    const onHandleSubmit = () => {
        //查询用户状态
        checkUserStateInfo()
    }
    // 点击add home监听
    const onHandleAddToHome = (type) => {
        console.log(`点击add home监听: ${type}`)
        if (type === 'home') {
            AddHomeEvent.onHandleAddHomeEventClick()
        } else {
            AddHomeEvent.onHandleAddFavoriteEventClick()
        }
    }

    // 关闭add home
    const onHandleAddToHomeClose = () => {
        console.log(`点击close add home监听`)
        setAddHomeMaskVisible(false)
    }

    // 刷新操作
    const onHandleRefresh = () => {
        // 查询用户流程状态
        checkUserStateInfo()
    }

    //启动监听
    useEffect(() => {
        // 优惠券入口
        CouponClick.fromParentPage = PageTag.LOANING

        //打点
        doHitEvent("LOANING_START")

        return () => {
            //打点
            doHitEvent("LOANING_END")
        }
    }, [])

    // 查询用户流程状态返回监听
    useEffect(() => {
        try {

            console.log("LOANING-查询用户流程状态返回监听")
            console.log(userStateInfo)

            if (userStateInfo.status === LOANING_USER_STATE_REQ_SUCCESS) {
                //隐藏loading
                setMaskVisible(false)

                console.log("LOAING-再次查询user state:")
                console.log("LOAING-applyStatus: " + userStateInfo.applyStatus)
                console.log("LOAING-orderStatus: " + userStateInfo.orderStatus)
                console.log("LOAING-signFlag: " + userStateInfo.signFlag)
                console.log("Launch-signOrderId: " + userStateInfo.signOrderId)
    
                //是否为签约中
                const isHomeSigning = isLoanSigning(userStateInfo.signFlag, userStateInfo.signOrderId)
                //是否为审核中
                const isHomeReviewing = isLoanReviewing(userStateInfo.applyStatus)
                //是否为拒绝
                const isHomeReject = isLoanReject(userStateInfo.applyStatus, userStateInfo.orderStatus)
                //是否为放款中
                const isHomeLoaning = isLoanLoaning(userStateInfo.orderStatus)
                //是否为待还款
                const isHomeRepay = isLoanRepayNow(userStateInfo.orderStatus)
                //是否为待进件
                const isHomeNotLoan = ((!isHomeReviewing) && (!isHomeReject) && (!isHomeLoaning) && (!isHomeRepay))
                console.log("LOAING-isHomeReviewing: " + isHomeReviewing)
                console.log("LOAING-isHomeReject: " + isHomeReject)
                console.log("LOAING-isHomeLoaning: " + isHomeLoaning)
                console.log("LOAING-isHomeRepay: " + isHomeRepay)
                console.log("LOAING-isHomeNotLoan: " + isHomeNotLoan)
                console.log("LOAING-isHomeSigning: " + isHomeSigning)

                //页面转发
                setTimeout(() => {
                    if (isHomeSigning) {
                        history.replace('/guide')
                    } else if (isHomeReviewing) {
                        history.replace('/review')
                    } else if (isHomeReject) {
                        history.replace('/reject')
                    } else if (isHomeLoaning) {

                    } else if (isHomeRepay) {
                        history.replace('/repay')
                    } else {
                        history.replace('/home')
                    }
                }, 500)

            } else if (userStateInfo.status === LOANING_USER_STATE_REQ_FAIL) {
                //隐藏loading
                setMaskVisible(false)
            }
        } catch (e) {
            console.log(e)
        }
    }, [userStateInfo])

    //监听用户是否存在
    // useEffect(() => {
    //     if (userNotExistInfo.notExist === true) {
    //         //history.replace('/login')
    //         history.replace('/loginfb')
    //     }
    // }, [userNotExistInfo])

    return (
        <div className="loanRoot mainBackgroundWhite">
            {/* <NavBar back={null} onBack={() => { history.goBack() }} style={{ 'width': '100%', 'backgroundColor': 'white' }}>Loaning</NavBar> */}
            <NavigateBar theme="white" onAddFavorite={() => { setAddHomeMaskIsHomeMode(false); setAddHomeMaskVisible(true) }} onAddHome={() => { setAddHomeMaskIsHomeMode(true); setAddHomeMaskVisible(true) }} onRefresh={onHandleRefresh}>Loan Salad</NavigateBar>

            {/* 使用添加 home screen bar */}
            {/* <AddFavorite onAddHome={() => { setAddHomeMaskIsHomeMode(true); setAddHomeMaskVisible(true) }} /> */}

            <div hidden className="loanContent">
                <div className="statusImageContent">
                    <img
                        className="statusImage"
                        src={loan_loaning}
                        alt="" />
                </div>

                <span className="statusTitle">Loaning</span>

                <span className="statusTips">Money will be sent to your bank account</span>

                {/* 点击确定 */}
                <div className="statusRefreshButtonContent" onClick={onHandleSubmit}>
                    <div className="mainButtonGradient">Refresh status</div>
                </div>
            </div>

            {/* add home gif dialog */}
            <AddToHomeGifDialog visible={addHomeMaskVisible} onConfirm={onHandleAddToHome} onClose={onHandleAddToHomeClose} isHomeMode={addHomeMaskIsHomeMode} />

            <Mask visible={maskVisible} opacity={0.1}
                onMaskClick={() => { }}>
                <div className="overlayContent">
                    <span style={{ fontSize: 24, color: '#ff3141' }} >
                        <DotLoading color="currentColor" />
                    </span>
                </div>
            </Mask>
        </div >
    )
}