import React, { useEffect, useState } from 'react';
import './addtohomegifdialog.css'
import icon_add_home from '@/assets/icon_add_home.png'
import icon_add_favorite from '@/assets/icon_add_favorite.png'
import icon_gif_addtohome from '@/assets/icon_gif_addtohome.gif'
import icon_gif_shoucang from '@/assets/icon_gif_shoucang.gif'
import icon_close from '@/assets/icon_close.png'
import { Mask } from 'antd-mobile';

export default function AddToHomeGifDialog({ visible, onConfirm, onClose, onNext, isHomeMode = true }) {

    const [addToHome, setAddToHome] = useState(true);
    const [needDisplay, setNeedDisplay] = useState(false);


    //监听
    // useEffect(() => {
    //     setAddToHome(isHomeMode)
    //     setNeedDisplay(visible)
    // }, [])

    //监听
    useEffect(() => {
        setAddToHome(isHomeMode)
        setNeedDisplay(visible)
    }, [visible, isHomeMode])

    const onHandleNextClick = () => {
        const homeMode = addToHome
        setAddToHome(!homeMode)
        onNext && onNext()
    }

    return (
        <Mask visible={needDisplay} opacity={0.6}
            onMaskClick={() => { }}>
            <div className="addGifDialogOverlayBg">
                <div className="addGifDialogOverlayContent">
                    <div className="addGifDialogOverlayContentRowHeaderCenter">
                        <span>{addToHome ? "Add to home screen" : "Add Bookmark"}</span>
                        <img className="addGifDialogOverlayIconClose" src={icon_close} alt="" onClick={() => { onClose() }} />
                    </div>

                    <div className="addGifDialogOverlayContentIconCenter">
                        <img className="addGifDialogOverlayIconCenter" src={addToHome ? icon_gif_addtohome : icon_gif_shoucang} alt="" />
                    </div>

                    <div className='addGifDialogOverlayLine'></div>

                    <div className="addGifDialogOverlayButtonContent" onClick={() => { addToHome ? onConfirm('home') : onConfirm('favorite') }}>
                        <div className="mainButtonGradient">
                            <img className="addGifDialogOverlayIconConfirm" src={addToHome ? icon_add_home : icon_add_favorite} alt="" />
                            {addToHome ? "Add to home screen" : "Add Bookmark"}
                        </div>
                    </div>
                </div>

                <div className='addGifDialogOverlayPagerDotContent'>
                    {addToHome ? <div className='addGifDialogOverlayPagerDotSelectedContent'>
                        <div className='addGifDialogOverlayPagerDotSelected'></div>
                    </div> : <div className='addGifDialogOverlayPagerDotUnSelectContent'>
                        <div className='addGifDialogOverlayPagerDotUnSelect'></div>
                    </div>}

                    <div className='addGifDialogOverlayPagerDotEddge'></div>

                    {addToHome ? <div className='addGifDialogOverlayPagerDotUnSelectContent'>
                        <div className='addGifDialogOverlayPagerDotUnSelect'></div>
                    </div> : <div className='addGifDialogOverlayPagerDotSelectedContent'>
                        <div className='addGifDialogOverlayPagerDotSelected'></div>
                    </div>}
                </div>

                <div className='addGifDialogOverlayNextContent' onClick={onHandleNextClick}>
                    <div className="mainButtonTransparent">Next</div>
                </div>
            </div>
        </Mask >
    );
};
