import { combineReducers } from "redux";
import { sendOtp } from "./sendOtp";
import { login } from "./login";
import { loanLimit } from "./loanLimit";
import { userState } from "./userState";
import { saveProofFail } from "./proof";
import { bankCode } from "./bankCode";
import { loanDetail } from "./loanDetail";
import { loanApply } from "./loanApply";
import { repayDetail } from "./repayDetail";
import { repayUrl } from "./repayUrl";
import { appConfig } from "./appConfig";
import { userNotExist } from "./userNotExist";
import { sendDataSmsCode } from "./sendDataSmsCode"
import { dataSmsCodeLoginFail } from "./dataSmsCodeLogin"
import { saveFrequentlyAppsFail } from "./saveFrequentlyApps"
import { couponDetail } from "./couponDetail"
import { sendCopyFailSms } from "./sendCopyFailSms"


// 组合各个 reducer 函数，成为一个根 reducer
const rootReducer = combineReducers({
    sendOtp,
    login,
    appConfig,
    loanLimit,
    userState,
    saveProofFail,
    bankCode,
    loanDetail,
    loanApply,
    repayDetail,
    repayUrl,
    userNotExist,
    sendDataSmsCode,
    dataSmsCodeLoginFail,
    saveFrequentlyAppsFail,
    couponDetail,
    sendCopyFailSms
})

// 导出根 reducer
export default rootReducer