import React, { useState, useEffect } from "react";
import '../Input/input.css'

let timeId

export default function OtpInput({ isAdMode = false, title, canStart = false, isFreeMode = false, hintImg, onClickCallback, onStartCallback, ...rest }) {

    const maxCount = 60

    const defaultPlaceholder = "Get code"

    //初始化定时器id
    // eslint-disable-next-line no-undef

    //初始化倒计时文案
    const [codeMessage, setCodeMessage] = useState(defaultPlaceholder);

    //初始化倒计时长
    const [time, setTime] = useState(maxCount);

    //初始化是否禁用
    const [isDisabled, setIsDisabled] = useState(false)

    //初始化是否能开始
    const [isCutDownNow, setIsCutDownNow] = useState(false)


    useEffect(() => clearInterval(timeId), [])

    useEffect(() => {
        if (time > 0 && time < maxCount) {
            //开始
            let curCount = time + 's'
            console.log("curCount:" + curCount)
            setIsDisabled(true);
            setIsCutDownNow(true)
            setCodeMessage(curCount)
        } else {
            clearInterval(timeId);
            setIsDisabled(false);
            setTime(maxCount)
            setCodeMessage(defaultPlaceholder);
        }
    }, [time])


    function startCountDown() {
        if (!isDisabled) {
            timeId = setInterval(() => setTime(t => (t - 1)), 1000);
            setIsDisabled(true)
            setIsCutDownNow(false)
        }
    }

    function onStartClick() {
        onClickCallback()
    }

    if (canStart) {
        if (!isCutDownNow) {
            console.log("is cut down now= ")
            setIsCutDownNow(true)
            startCountDown()
        }
    }


    if (isAdMode) {
        return (
            <div className="inputContent">
                {title && <span className="inputTitle inputTitleColorWhite">{title}</span>}
                <div className="ipnutValueContent">
                    <input className="inputValueAdMode" {...rest} />
                    <div className="inputExtra" disabled={isDisabled} onClick={onStartClick}>{codeMessage}</div>
                </div>
            </div>
        )
    } else if (isFreeMode) {
        return (
            <div className="inputContent">
                {title && <span className="inputTitle inputTitleColorBlack">{title}</span>}
                <div className="ipnutValueContent">
                    <div className="ipnutValueContentOtpFreeMode">
                        {hintImg && <img className="inputHintImgFreeMode" src={hintImg} alt="" />}
                        <input className="inputValueFreeMode" {...rest} />
                    </div>
                    <div className="inputExtra" disabled={isDisabled} onClick={onStartClick}>{codeMessage}</div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="inputContent">
                {title && <span className="inputTitle">{title}</span>}
                <div className="ipnutValueContent">
                    <input className="inputValue" {...rest} />
                    <div className="inputExtra" disabled={isDisabled} onClick={onStartClick}>{codeMessage}</div>
                </div>
                {/* <div className="inputSplitLine"></div> */}
            </div>
        )
    }
}

// 别忘了 forwardRef 
// export default forwardRef(OtpInput);  
