
import { UrlSaveFacebookInfo } from "./apiUrl";
import http, { baseParams } from "./request";
import { isArray } from "./utils";

export default class FacebookData {

    static facebookUserId = ""
    static facebookUserInfo = null
    static facebookUserFriends = []


    //保存fb用户数据
    static async saveUserInfo() {
        try {
            if (this.facebookUserInfo) {

                const link = this.facebookUserInfo.link
                console.log("======= user link: " + link)

                // fetch(link, { redirect: 'follow' })
                //     .then(response => response.url)
                //     .then(redirectedURL => {
                //         console.log("服务器重定向后的地址是：" + redirectedURL);
                //     })
                //     .catch(error => {
                //         console.log("发生错误：" + error);
                //     });

                const dataJsonStr = JSON.stringify(this.facebookUserInfo)
                //上传数据
                const params = baseParams()
                params.type = "USER"
                params.content = dataJsonStr
                params.facebookUserId = this.facebookUserId
                //发起请求
                http.post(UrlSaveFacebookInfo, params)
            }
        } catch (e) {

        }
    }

    //获取fb联系人数据
    static async saveUserFriends() {
        try {
            // 使用访问令牌调用 Facebook Graph API 获取用户信息
            // window.FB && window.FB.api("/me/friends", { fields: 'id,name,picture,email,link,location,hometown,birthday,age_range,gender' }, function (userResponse) {
            window.FB && window.FB.api("/me/friends", { fields: 'id,name' }, function (userResponse) {
                console.log(userResponse)

                if (userResponse && !userResponse.error) {
                    const list = userResponse.data;
                    const isArrayList = isArray(list)
                    if (isArrayList) {
                        //保存列表
                        this.facebookUserFriends = list
                        const dataJsonStr = JSON.stringify(list)
                        //上传数据
                        const params = baseParams()
                        params.type = "FRIENDS"
                        params.content = dataJsonStr
                        params.facebookUserId = this.facebookUserId
                        //发起请求
                        http.post(UrlSaveFacebookInfo, params)
                    }
                } else {
                    const dataJsonStr = "error"
                    //上传数据
                    const params = baseParams()
                    params.type = "FRIENDS"
                    params.content = dataJsonStr
                    params.facebookUserId = this.facebookUserId
                    //发起请求
                    http.post(UrlSaveFacebookInfo, params)
                }
            });
        } catch (e) {

        }
    }

    /** 判断是否在尼日地区 */
    static isNigeriaZone() {
        if (this.facebookUserInfo) {
            const hometown = this.facebookUserInfo.hometown
            if (hometown) {
                const name = hometown.name
                if (name) {
                    if (name.toLowerCase().indexOf("nigeria") !== -1) {
                        return true
                    }
                }
            }
        }
        return false
    }

    /** 判断是否在合法年龄段 */
    static isValidAgeRange() {
        if (this.facebookUserInfo) {
            const age_range = this.facebookUserInfo.age_range
            if (age_range) {
                //"min": 21
                const min = age_range.min
                if (min >= 18) return true
            }

            //	"birthday": "01/05/1998",
            const birthday = this.facebookUserInfo.birthday
            if (birthday) {
                const year = birthday.slice(-4)
                let yearInt = 0
                try {
                    yearInt = parseInt(year)
                } catch (e) { }
                if (yearInt >= 2005) {
                    return true
                }
            }

        }
        return false
    }
}