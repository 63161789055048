
import http, { baseParams } from "@/utils/request";
import { UrlSaveUserLocation } from "@/utils/apiUrl";

//类型：请求成功
export const SAVE_LOCATION_REQ_SUCCESS = "SAVE_LOCATION_REQ_SUCCESS"

//类型：请求失败
export const SAVE_LOCATION_REQ_FAIL = "SAVE_LOCATION_REQ_FAIL"

export const covertToAddress = (longitude = "", latitude = "") => {
    return async (dispatch) => {

        const apiKey = 'YOUR_API_KEY';

        // 使用Google Maps Geocoding API进行逆地理编码
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 'OK' && data.results.length > 0) {
                    const address = data.results[0].formatted_address;
                    console.log(`逆地理编码结果：${address}`);
                } else {
                    console.error('无法找到地址信息。');
                }
            })
            .catch((error) => {
                console.error('发生错误：', error);
            });
    }
}

/**
 * 保存位置信息
 * @returns thunk
 */
export const saveLocation = (longitude = "", latitude = "") => {
    return async (dispatch) => {
        //组装参数
        const params = baseParams()
        params.latitude = latitude
        params.longitude = longitude

        //发起请求
        await http.post(UrlSaveUserLocation, params)
        console.log("保存位置信息-结束：=======")
    }
}
